import type Country from '@product-web/locale/country'
import {getLocaleByLanguage} from '@product-web/locale/helpers'
import type Locale from '@product-web/locale/locale'
import {useUser} from '@product-web/user'

import type {LocalisedDocuments} from './types'
import {defaultLocale} from './types'

import {useFeatureCountryConfig} from '../../configuration'
import {legalDocumentsBaseUrl} from '../../configurations/default'
import {Feature} from '../../types'

export type TermsDocuments = Record<keyof LocalisedDocuments, string>

/**
 * Function for getting the locale version of files from LocalisedDocuments, if no document matching language is found it will return
 * the default locale version
 * @param language Language, language locale code to use for lookup
 * @param localisedDocuments LocalisedDocuments, documents grouped by locale for lookup
 * @returns termsDocuments TermsDocuments, object containing localised documents with base URL prepended
 * @example.
 *      const termDocuments = getTermsDocumentsForLocale(Locale.EN_GB, localisedDocuments)
 */
export const getTermsDocumentsForLocale = (
    language: Locale,
    localisedDocuments: LocalisedDocuments,
): TermsDocuments => {
    const {
        privacyPolicy,
        masterServiceAgreement,
        masterServiceAgreementB4B,
        dataProcessingAgreement,
        purchaseTerms,
        cardholderAgreement,
        authorisedUserTerms,
    } = localisedDocuments
    const localePrivacyPolicy = privacyPolicy[language] ?? privacyPolicy[defaultLocale]
    const localeMasterServiceAgreement =
        masterServiceAgreement[language] ?? masterServiceAgreement[defaultLocale]
    const localeDataProcessingAgreement =
        dataProcessingAgreement[language] ?? dataProcessingAgreement[defaultLocale]
    const localePurchaseTerms = purchaseTerms[language] ?? purchaseTerms[defaultLocale]
    const localeMasterServiceAgreementB4B =
        masterServiceAgreementB4B[language] ?? masterServiceAgreementB4B[defaultLocale]
    const localeCardholderAgreement =
        cardholderAgreement[language] ?? cardholderAgreement[defaultLocale]
    const localeAuthorisedUserTerms =
        authorisedUserTerms[language] ?? authorisedUserTerms[defaultLocale]

    return {
        masterServiceAgreement: `${legalDocumentsBaseUrl}/${localeMasterServiceAgreement}`,
        masterServiceAgreementB4B: `${legalDocumentsBaseUrl}/${localeMasterServiceAgreementB4B}`,
        dataProcessingAgreement: `${legalDocumentsBaseUrl}/${localeDataProcessingAgreement}`,
        privacyPolicy: `${legalDocumentsBaseUrl}/${localePrivacyPolicy}`,
        purchaseTerms: `${legalDocumentsBaseUrl}/${localePurchaseTerms}`,
        cardholderAgreement: `${legalDocumentsBaseUrl}/${localeCardholderAgreement}`,
        authorisedUserTerms: `${legalDocumentsBaseUrl}/${localeAuthorisedUserTerms}`,
    }
}

/**
 * Hook for getting term documents for the user, will get the users countries term documents unless override provided.
 * Will return the users locale documents if available or else will return default locale.
 * @param countryOverride Country, country override for feature country config.
 * @returns termsDocuments TermsDocuments, object containing localised documents with base URL prepended
 * @example.
 *      const termDocuments = useTermsDocuments()
 */
export const useTermsDocuments = (countryOverride?: Country): TermsDocuments => {
    const user = useUser()
    const locale = user ? getLocaleByLanguage(user.language) : defaultLocale
    const {documents} = useFeatureCountryConfig(Feature.Terms, countryOverride)
    return getTermsDocumentsForLocale(locale || defaultLocale, documents)
}
