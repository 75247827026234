import {Trans} from '@lingui/macro'
import type React from 'react'
import {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'

import {Box, Button, Stack, Tag, Text, tokens} from '@pleo-io/telescope'

import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import {PlanSelectionModal} from '@product-web/paywall/plan-selections/plan-selection-modal'
import type {PageOrigin} from '@product-web/paywall/types'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'

import {trackUpgradeYourPlanViewed} from '../lib/tracking'

interface EmptyStatesBaseProps {
    title: string
    description: string
    ctaText: string
    illustration?: string
    pageOrigin: PageOrigin
    planUpgradeSource: PlanUpgradeSource
    hasBorderBottom?: boolean
    hasIllustrationAboveCopy?: boolean
    secondaryAction?: () => React.ReactNode
}

export const EmptyStatesBase = ({
    title,
    description,
    ctaText,
    illustration,
    pageOrigin,
    planUpgradeSource,
    hasBorderBottom,
    hasIllustrationAboveCopy,
    secondaryAction,
}: EmptyStatesBaseProps) => {
    const [showChangePlanModal, setShowChangePlanModal] = useState(false)

    const renderIllustration = () => {
        if (!illustration) {
            return null
        }
        return (
            <IllustrationStack
                align="center"
                marginTop={hasIllustrationAboveCopy ? 0 : 24}
                hasBorderBottom={hasBorderBottom}
            >
                <img src={illustration} alt="" data-testid="empty-state-illustration" />
            </IllustrationStack>
        )
    }

    useEffect(() => {
        trackUpgradeYourPlanViewed({page: pageOrigin})
    }, [pageOrigin])

    return (
        <EmptyStatesBaseWrapper>
            <Stack align="center">
                {hasIllustrationAboveCopy && renderIllustration()}
                <CopyStack align="center" space={4}>
                    <Tag variant="pink">
                        <Trans>Upgrade</Trans>
                    </Tag>

                    <Text variant="2xlarge-accent" color="shade800" align="center">
                        {title}
                    </Text>

                    <Box paddingTop={4}>
                        <Text variant="small-subtle" color="shade700" align="center">
                            {description}
                        </Text>
                    </Box>

                    <ActionsStack paddingTop={12} space={16}>
                        <Button variant="primary" onClick={() => setShowChangePlanModal(true)}>
                            {ctaText}
                        </Button>
                        {typeof secondaryAction === 'function' && secondaryAction()}
                    </ActionsStack>
                </CopyStack>
                {!hasIllustrationAboveCopy && renderIllustration()}
            </Stack>

            {showChangePlanModal && (
                <PlanSelectionModal
                    isOpen
                    onDismiss={() => setShowChangePlanModal(false)}
                    planUpgradeSource={planUpgradeSource}
                    pageOrigin={pageOrigin}
                />
            )}
        </EmptyStatesBaseWrapper>
    )
}

const EmptyStatesBaseWrapper = styled.div`
    width: 100%;
    max-width: 500px;

    ${containerQuery(
        {minWidth: pxBreakpoints.largeDesktop, name: 'main'},
        css`
            max-width: 900px;
        `,
    )}
`

const CopyStack = styled(Stack)`
    max-width: calc(100% - ${tokens.spacing60});
`

const IllustrationStack = styled(Stack)<{hasBorderBottom?: boolean; backgroundColor?: string}>`
    background-color: ${({backgroundColor}) =>
        backgroundColor ? backgroundColor : tokens.colorBackgroundPresentationalPink};
    padding-top: ${tokens.spacing32};
    padding-bottom: ${({hasBorderBottom}) => (hasBorderBottom ? tokens.spacing32 : 0)};
    margin-bottom: ${tokens.spacing20};
    border-radius: ${tokens.arc8};

    & > img {
        width: calc(100% - ${tokens.spacing60});
    }
`

const ActionsStack = styled(Stack)`
    width: auto;

    & > * {
        width: 100%;
    }
`
