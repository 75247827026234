import {Plural, plural, t} from '@lingui/macro'
import type {ReactNode} from 'react'
import {createContext, useContext, useState} from 'react'

import type {CalloutVariants} from '@pleo-io/telescope'
import {Callout} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import {useToaster} from '@product-web/toaster'
import {invariant} from '@product-web/utils'

import type {GetVendorStatusResponse, Status, VendorSynced} from './vendor-status.bff'

import {bff} from '../../bff-hooks'

const ASYNC_VENDOR_REFRESH_INTERVAL = 3000

type StatusMapping = {
    [K in Status]: {
        one: string
        other: string
        variant: CalloutVariants
        key: 'errorCount' | 'createdCount' | 'pendingCount'
    }
}

export type AsyncVendorSyncAPI = {
    isSyncInProgress: boolean
    triggerSync?: (params: {accoutingEntryIds: string[]}) => void
    vendorStatusComponent?: ReactNode
    status: Status | undefined
    vendorsSynced: VendorSynced[]
}

export const AsyncVendorSyncContext = createContext<AsyncVendorSyncAPI>({
    isSyncInProgress: false,
    triggerSync: undefined,
    vendorStatusComponent: undefined,
    status: undefined,
    vendorsSynced: [],
})

export const AsyncVendorSyncProvider = ({children}: {children: ReactNode}) => {
    const {vendorBookkeeping, vendorBookkeepingSyncStatus} = useFlags()

    const {showToast} = useToaster()

    const [isSyncInProgress, setIsSyncInProgress] = useState(false)

    const [isBannerDisplayed, setIsBannerDisplayed] = useState(false)

    // TODO: delete query param when API is available
    const {data} = bff.vendorStatus.getVendorsStatus.useQuery(
        {
            status: vendorBookkeepingSyncStatus,
        },
        {
            // Enable if flag is on, application allows vendor export and sync is in progress
            // TODO: remove flag when release is planned
            enabled: Boolean(
                vendorBookkeeping && vendorBookkeepingSyncStatus !== 'INACTIVE' && isSyncInProgress,
            ),
            refetchInterval: (lastAsyncVendorStatus) => {
                return lastAsyncVendorStatus?.status &&
                    ['PENDING', 'PENDING_WITH_ERROR'].includes(lastAsyncVendorStatus.status)
                    ? ASYNC_VENDOR_REFRESH_INTERVAL
                    : 0
            },
            onSuccess: (lastAsyncVendorStatus) => {
                if (lastAsyncVendorStatus.status === 'CREATED') {
                    setIsSyncInProgress(false)
                    showToast(
                        plural(lastAsyncVendorStatus.createdCount, {
                            one: 'Vendor created.',
                            other: 'All vendors created.',
                        }),
                        {level: 'success'},
                    )

                    // TODO: decide to hide the banner after creation or not
                    // setIsBannerDisplayed(false)
                }

                if (lastAsyncVendorStatus.status === 'CREATED_WITH_ERROR') {
                    const translation = `
                        ${plural(lastAsyncVendorStatus.createdCount, {
                            one: '# vendor creation successful,',
                            other: '# vendor creations successful,',
                        })} ${plural(lastAsyncVendorStatus.errorCount, {
                        one: '# error.',
                        other: '# errors.',
                    })}`

                    setIsSyncInProgress(false)
                    showToast(translation, {level: 'error'})
                }

                return
            },
        },
    )

    const {mutateAsync: triggerVendorSync} = bff.vendorStatus.triggerVendorSync.useMutation({
        onSuccess: () => {
            setIsSyncInProgress(true)
            setIsBannerDisplayed(true)
        },
    })

    const triggerSync = async (params: {accoutingEntryIds: string[]}) => {
        triggerVendorSync(params)
    }

    return (
        <AsyncVendorSyncContext.Provider
            value={{
                isSyncInProgress,
                triggerSync,
                vendorStatusComponent: (
                    <VendorStatusBanner
                        data={data}
                        isBannerDisplayed={isBannerDisplayed}
                        onDismiss={setIsBannerDisplayed}
                    />
                ),
                status: data?.status,
                vendorsSynced: data?.vendorsSynced ?? [],
            }}
        >
            {children}
        </AsyncVendorSyncContext.Provider>
    )
}

export const useAsyncVendorSyncContext = () => {
    const context = useContext(AsyncVendorSyncContext)

    invariant(context, 'This hook can only be used within useAsyncVendorSyncContext')

    return context
}

export const VendorStatusBanner = ({
    data,
    isBannerDisplayed,
    onDismiss,
}: {
    data?: GetVendorStatusResponse
    isBannerDisplayed: boolean
    onDismiss: (value: boolean) => void
}) => {
    const {vendorBookkeeping} = useFlags()

    const statusMapping: StatusMapping = {
        CREATED: {
            one: t`Vendor created.`,
            other: t`All vendors created.`,
            variant: 'positive',
            key: 'createdCount',
        },
        CREATED_WITH_ERROR: {
            one: t`# vendor creation successful,`,
            other: t`# vendor creations successful,`,
            variant: 'warning',
            key: 'createdCount',
        },
        PENDING_WITH_ERROR: {
            one: t`# vendor being created,`,
            other: t`# vendors being created,`,
            variant: 'warning',
            key: 'pendingCount',
        },
        PENDING: {
            one: t`# vendor being created.`,
            other: t`# vendors being created.`,
            variant: 'neutral',
            key: 'pendingCount',
        },
    }

    if (!vendorBookkeeping || !isBannerDisplayed) {
        return null
    }

    return data ? (
        <Callout variant={statusMapping[data.status].variant}>
            <Callout.Text>
                <Plural
                    value={data[statusMapping[data.status]?.key] ?? 0}
                    one={statusMapping[data.status]?.one}
                    other={statusMapping[data.status]?.other}
                />{' '}
                {data.errorCount > 0 ? (
                    <Plural value={data.errorCount} one="# error." other="# errors." />
                ) : null}
            </Callout.Text>
            {['CREATED', 'CREATED_WITH_ERROR'].includes(data.status) ? (
                <Callout.CloseButton onClick={() => onDismiss(false)} />
            ) : null}
        </Callout>
    ) : null
}
