import styled, {css} from 'styled-components'

import {NakedButton, tokens} from '@pleo-io/telescope'

import {customColorSchemeTokens} from '@product-web/styles/custom-tokens'

import type {Step} from './guide'

interface Props {
    steps?: Pick<Step, 'color' | 'stepIndicatorColor' | 'indicatorNavigationDisabled'>[]
    active?: number
    onClickStepIndicator?: (step: number) => void
    dotInactiveColor?: string
}

export const Indicator = ({steps, active, onClickStepIndicator, dotInactiveColor}: Props) => {
    if (!steps || steps.length < 1) {
        return null
    }
    return (
        <Dots>
            {steps.map(
                (
                    {
                        stepIndicatorColor,
                        color,
                        indicatorNavigationDisabled: indicatorNavigationDissalowed,
                    },
                    idx: number,
                ) => (
                    <Dot
                        key={`guide-step-progress-${idx}`}
                        data-testid="progress-indicator"
                        color={stepIndicatorColor ?? color}
                        disabled={indicatorNavigationDissalowed}
                        onClick={() => onClickStepIndicator?.(idx)}
                        active={idx === active}
                        inactiveColor={dotInactiveColor}
                    />
                ),
            )}
        </Dots>
    )
}

const Dots = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const Dot = styled(NakedButton)<{
    color?: string
    active?: boolean
    inactiveColor?: string
}>`
    height: ${tokens.spacing8};
    width: ${tokens.spacing8};
    border-radius: ${tokens.circle};
    background-color: ${(props) =>
        props.inactiveColor ?? customColorSchemeTokens.colorBackgroundIndicator};
    margin: 0 ${tokens.spacing4};
    transition: background-color, height, width ${tokens.smooth} ${tokens.smoothInOut}; /* stylelint-disable-line */
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: ${props.color ??
            props.inactiveColor ??
            customColorSchemeTokens.colorBackgroundIndicator};
            height: ${tokens.spacing12};
            width: ${tokens.spacing12};
        `}

    &:disabled {
        cursor: not-allowed;
    }
`
