import {css, styled} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import type {PageOrigin} from '@product-web/paywall/types'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {InlineWistiaVideo} from '@product-web/telescope-lab/wistia-video/inline-wistia-video'

import {useLocalisedIllustration} from '../../empty-states/empty-states.helper'
import {useLocalisedAnimation} from '../use-localised-animation'
import {useLocalisedVideo} from '../use-localised-video'

const MediaItemWrapper = styled.div<{$type: 'illustration' | 'animation' | 'video'}>`
    align-items: center;
    background-color: ${tokens.colorBackgroundNeutral};
    border-radius: ${tokens.arc8};
    display: flex;
    flex: 1;
    padding: ${({$type}) =>
        $type === 'illustration' ? `${tokens.spacing40} 0 0 ${tokens.spacing32}` : 0};
    overflow: hidden;
`

type LandingPageMediaImgProps = {
    alt?: string
    src: string
    type: 'illustration' | 'animation'
    fixImgBg?: boolean
}

type LandingPageMediVideoProps = {
    videoId: string
    thumbnail: string
    type: 'video'
}

const LandingPageMediaVideo = ({type, videoId, thumbnail}: LandingPageMediVideoProps) => {
    return (
        <MediaItemWrapper $type={type}>
            <InlineWistiaVideo autoPlay startMuted thumbnail={{src: thumbnail}} videoId={videoId} />
        </MediaItemWrapper>
    )
}

const LandingPageMediaImg = ({type, src, alt, fixImgBg = false}: LandingPageMediaImgProps) => (
    <MediaItemWrapper $type={type}>
        <img
            src={src}
            alt={alt}
            width={'100%'}
            css={{
                borderRadius: type === 'illustration' ? `${tokens.arc8} 0 0 0` : 0,
                backgroundColor:
                    fixImgBg && type === 'illustration'
                        ? tokens.colorBackgroundStaticPage
                        : 'transparent',
            }}
        />
    </MediaItemWrapper>
)

const StyledContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing20};

    ${containerQuery(
        {name: 'main', minWidth: pxBreakpoints.tabletMedUp},
        css`
            flex-direction: row;
        `,
    )}
`

export const LandingPageMedia = ({pageOrigin}: {pageOrigin: PageOrigin}) => {
    const {illustration, illustrationAltText} = useLocalisedIllustration({type: pageOrigin})
    const {animationSource, animationAltText} = useLocalisedAnimation({type: pageOrigin})
    const {videoId, thumbnail} = useLocalisedVideo({type: pageOrigin})
    const fixIllustrationBg = pageOrigin === 'vendor-cards' || pageOrigin === 'budgets'

    return (
        <StyledContainer>
            {videoId ? (
                <LandingPageMediaVideo type="video" videoId={videoId} thumbnail={thumbnail} />
            ) : (
                <LandingPageMediaImg
                    src={animationSource}
                    alt={animationAltText}
                    type="animation"
                />
            )}
            <LandingPageMediaImg
                type="illustration"
                src={illustration}
                alt={illustrationAltText}
                fixImgBg={fixIllustrationBg}
            />
        </StyledContainer>
    )
}
