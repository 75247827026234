import {AnimatePresence, motion} from 'framer-motion'
import {useState} from 'react'
import styled from 'styled-components'

import {Modal} from '@pleo-io/telescope'

import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import {variants} from '@product-web-features/ui-guides/guide'

import {PlanSelectionStepOne} from './plan-selection-step-one'

import {
    type AllowedPlanType,
    trackChoosePlanModalActioned,
} from '../plan-updates/plan-update.helper'
import {PlanUpdateStepTwo} from '../plan-updates/plan-update-step-two'
import type {PageOrigin, PageSection} from '../types'

interface PlanSelectionModalProps {
    isOpen: boolean
    onDismiss: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    dangerouslySetZIndexValue?: number
}

export const PlanSelectionModal = ({
    isOpen,
    onDismiss,
    pageOrigin,
    planUpgradeSource,
    pageSection,
    dangerouslySetZIndexValue,
}: PlanSelectionModalProps) => {
    // There is a lot of code shared between the PlanSelectionModal and the PlanUpdateModal.
    // Need to create a Wrapper Modal around them
    const [selectedPlan, setSelectedPlan] = useState<AllowedPlanType | undefined>(undefined)
    const [currentPlan, setCurrentPlan] = useState<AllowedPlanType | undefined>(undefined)

    const trackModalClose = () => {
        if (selectedPlan === undefined) {
            trackChoosePlanModalActioned({
                action: 'abandoned',
                step: 'pricing_plan',
                origin: pageOrigin,
                section: pageSection,
            })
        } else {
            trackChoosePlanModalActioned({
                action: 'abandoned',
                step: 'payment_plan',
                origin: pageOrigin,
                section: pageSection,
                plan: selectedPlan,
            })
        }
    }

    return (
        <StyledModal
            isOpen={isOpen}
            aria-labelledby="plan-selection-modal-title"
            onDismiss={trackModalClose}
            dangerouslySetZIndexValue={dangerouslySetZIndexValue}
        >
            <Modal.Close
                data-testid="plan-selection-modal-close"
                onClick={() => {
                    trackModalClose()
                    onDismiss()
                }}
            />
            <AnimatePresence initial={false} exitBeforeEnter>
                <motion.div
                    key={selectedPlan}
                    variants={variants}
                    custom={selectedPlan === undefined ? 'left' : 'right'}
                    initial="enter"
                    animate="center"
                    exit="exit"
                >
                    {selectedPlan === undefined ? (
                        <PlanSelectionStepOne
                            pageOrigin={pageOrigin}
                            pageSection={pageSection}
                            planUpgradeSource={planUpgradeSource}
                            onGetStarted={({changeToPlan, existingPlan}) => {
                                trackChoosePlanModalActioned({
                                    action: 'get_started',
                                    step: 'pricing_plan',
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: changeToPlan,
                                })
                                setCurrentPlan(existingPlan)
                                setSelectedPlan(changeToPlan)
                            }}
                            onDismiss={onDismiss}
                        />
                    ) : (
                        <PlanUpdateStepTwo
                            pageOrigin={pageOrigin}
                            pageSection={pageSection}
                            planUpgradeSource={planUpgradeSource}
                            selectedPlan={selectedPlan!}
                            currentPlan={currentPlan!}
                            onBack={() => {
                                trackChoosePlanModalActioned({
                                    action: 'back',
                                    step: 'payment_plan',
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: selectedPlan,
                                })
                                setSelectedPlan(undefined)
                            }}
                            onDismiss={() => {
                                onDismiss()
                                setSelectedPlan(undefined)
                            }}
                            fromPlanSelectionModal
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    width: 100%;
    max-width: 1066px;
    height: auto;
`
