import {t} from '@lingui/macro'

import {SupportedLanguage} from '@product-web/i18n'
import type {PageOrigin} from '@product-web/paywall/types'
import {useUser} from '@product-web/user'

import LandingAnimationBudgetsEn from './assets/landing-animation-budgets-en.gif'
import LandingAnimationVendorCardsEn from './assets/landing-animation-vendor-cards-en.gif'

export const useLocalisedAnimation = ({type}: {type: PageOrigin}) => {
    const user = useUser()

    // Get only the generic language code without locales
    const userLanguage = (user?.language?.slice(0, 2) as SupportedLanguage) || SupportedLanguage.EN

    // Make sure there is always a type set, this should not happen
    const AnimationSourceType =
        AnimationSourceConfig[type] ?? AnimationSourceConfig['vendor-cards']!

    // Default to English if language not found
    const animationSource = AnimationSourceType[userLanguage] ?? AnimationSourceType.en!

    // Add the respective alt text if exists
    const animationAltText = AnimationAltTextConfig[type]?.() ?? ''

    return {animationSource, animationAltText, userLanguage}
}

const AnimationSourceConfig: Partial<
    Record<PageOrigin, Partial<Record<SupportedLanguage, string>>>
> = {
    'vendor-cards': {
        [SupportedLanguage.EN]: LandingAnimationVendorCardsEn,
    },
    budgets: {
        [SupportedLanguage.EN]: LandingAnimationBudgetsEn,
    },
    'recurring-expenses': {
        [SupportedLanguage.EN]: LandingAnimationVendorCardsEn,
    },
}

const AnimationAltTextConfig: Partial<Record<PageOrigin, () => string>> = {
    'vendor-cards': () =>
        t`Example overview with vendor cards for Linkedin Ads, Loom, Zoom, and Slack. The animation shows a new card being created, assigned to an employee, and then reassigned.`,
    'recurring-expenses': () =>
        t`Example overview with subscriptions for Linkedin Ads, Loom, Zoom, and Slack. The animation shows a new vendor card being created, assigned to an employee, and then reassigned.`,
    budgets: () =>
        t`An example budget is created and attached to the tag 'Project Apollo'. Fifteen thousand pounds are allocated. An expiry date is set and a reviewer is selected.`,
}
