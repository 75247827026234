import {Plural, Trans} from '@lingui/macro'
import {Link as RouterLink, useSearchParams} from 'react-router-dom'

import type {ButtonProps} from '@pleo-io/telescope'
import {Button, Inline, Link, LoadingPage, Stack, Text} from '@pleo-io/telescope'

import {dayjs} from '@product-web/dates/dayjs'

import {usePartnerProgramInfo} from '../../../api/partner-program-info'
import type {PartnerProgramReferral} from '../../../api/partner-program-referrals'
import {
    usePartnerCurrentReferrals,
    usePartnerPastReferrals,
} from '../../../api/partner-program-referrals'
import * as EmptySection from '../../../components/empty-section/empty-section'
import {GenericError} from '../../../components/generic-error'
import {SplitContent} from '../../../components/split-content'
import TruncateText from '../../../components/truncate-text/truncate-text'
import cat from '../../../images/no-clients.png'
import PartnerOverviewCard from '../components/partner-overview-card'
import {PartnerTierCard} from '../components/partner-tier-card/partner-tier-card'

export const CompanyReferrals = ({showPartnerAgreement}: {showPartnerAgreement: boolean}) => {
    const {error: partnerProgramInfoError} = usePartnerProgramInfo()
    const {
        flatList: currentReferralsList,
        total: currentReferralsTotal,
        error: currentReferralsError,
        size: currentReferralsSize,
        setSize: setCurrentReferralsSize,
    } = usePartnerCurrentReferrals()

    const {
        flatList: pastReferralsList,
        total: pastReferralsTotal,
        error: pastReferralsError,
        size: pastReferralsSize,
        setSize: setPastReferralsSize,
    } = usePartnerPastReferrals()

    const isLoadingCurrentReferrals = !currentReferralsList && !currentReferralsError
    const isLoadingPastReferrals = !pastReferralsList && !pastReferralsError
    const isLoading = isLoadingCurrentReferrals || isLoadingPastReferrals

    const noReferrals = currentReferralsList?.length === 0 && pastReferralsList?.length === 0

    const hasMoreCurrentReferrals =
        (currentReferralsList?.length || 0) < (currentReferralsTotal || 0)
    const hasLoadedMoreCurrentReferrals = currentReferralsSize > 1

    const hasMorePastReferrals = (pastReferralsList?.length || 0) < (pastReferralsTotal || 0)
    const hasLoadedMorePastReferrals = pastReferralsSize > 1

    if (partnerProgramInfoError || currentReferralsError || pastReferralsError) {
        return <GenericError />
    }

    return (
        <Stack mt={32} stretch>
            <SplitContent reverse>
                <SplitContent.Main>
                    {isLoading ? (
                        <LoadingPage />
                    ) : noReferrals ? (
                        showPartnerAgreement ? (
                            <NotSignedAgreementSection />
                        ) : (
                            <NoReferralsSection />
                        )
                    ) : (
                        <>
                            {!!currentReferralsList?.length && (
                                <Stack stretch>
                                    <Text variant="large-accent" weight="semibold" as="h2">
                                        <Trans>Your referrals</Trans>
                                    </Text>
                                    <Stack space={24} mt={24} mb={60} stretch>
                                        {currentReferralsList.map((referral) => (
                                            <ReferralItem key={referral.companyId} {...referral} />
                                        ))}
                                        {hasMoreCurrentReferrals ? (
                                            <ReferralsButton
                                                onClick={() => {
                                                    setCurrentReferralsSize((s) => s + 1)
                                                }}
                                            >
                                                <Trans>See more</Trans>
                                            </ReferralsButton>
                                        ) : (
                                            hasLoadedMoreCurrentReferrals && (
                                                <ReferralsButton
                                                    onClick={() => {
                                                        setCurrentReferralsSize(1)
                                                    }}
                                                >
                                                    <Trans>See less</Trans>
                                                </ReferralsButton>
                                            )
                                        )}
                                    </Stack>
                                </Stack>
                            )}
                            {!!pastReferralsList?.length && (
                                <Stack stretch>
                                    <Text
                                        variant="large-accent"
                                        weight="semibold"
                                        as="h2"
                                        space={10}
                                    >
                                        <Trans>Previous referrals</Trans>
                                    </Text>
                                    <Text color="shade600">
                                        <Trans>
                                            These past referrals would not count towards your tier
                                            activity because they are not part of your current
                                            partnership year.
                                        </Trans>
                                    </Text>
                                    <Stack space={24} mt={24} mb={60} stretch>
                                        {pastReferralsList.map((referral) => (
                                            <ReferralItem key={referral.companyId} {...referral} />
                                        ))}
                                        {hasMorePastReferrals ? (
                                            <ReferralsButton
                                                onClick={() => {
                                                    setPastReferralsSize((s) => s + 1)
                                                }}
                                            >
                                                <Trans>See more</Trans>
                                            </ReferralsButton>
                                        ) : (
                                            hasLoadedMorePastReferrals && (
                                                <ReferralsButton
                                                    onClick={() => {
                                                        setPastReferralsSize(1)
                                                    }}
                                                >
                                                    <Trans>See less</Trans>
                                                </ReferralsButton>
                                            )
                                        )}
                                    </Stack>
                                </Stack>
                            )}
                        </>
                    )}
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <Stack space={32} stretch>
                            <PartnerTierCard />
                            <PartnerOverviewCard />
                        </Stack>
                    </SplitContent.Sticky>
                </SplitContent.Right>
            </SplitContent>
        </Stack>
    )
}

function NoReferralsSection() {
    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>No referrals yet</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        You have not referred any users yet. To get started,{' '}
                        <Link as={RouterLink} to="/partner/onboard">
                            add your first client
                        </Link>
                        .
                    </Trans>
                </EmptySection.Subtitle>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function NotSignedAgreementSection() {
    const [searchParams, setSearchParams] = useSearchParams()
    const openAcceptPartnerAgreement = () => {
        searchParams.set('acceptTerms', 'true')
        setSearchParams(searchParams)
    }

    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>Don't miss out on your benefits</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        Accept your partner agreement to start onboarding new clients to the portal
                        right away.
                    </Trans>
                </EmptySection.Subtitle>
                <Button variant="primary" onClick={openAcceptPartnerAgreement}>
                    <Trans>Accept agreement</Trans>
                </Button>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

function ReferralItem({
    companyId,
    companyName,
    freeUserCount,
    paidUserCount,
    firstReferredDate,
}: PartnerProgramReferral) {
    return (
        <Inline key={companyId} justifyContent="space-between" space={24}>
            <Stack flex={1}>
                <TruncateText text={companyName} tooltip />
            </Stack>
            <Inline>
                {paidUserCount ? (
                    <Text>
                        <Text as="span" weight="bold">
                            {paidUserCount}
                        </Text>{' '}
                        <Plural value={paidUserCount} one="paid user" other="paid users" />
                    </Text>
                ) : (
                    <Text color="shade600">
                        <Text as="span" weight="bold" color="shade600">
                            {freeUserCount}
                        </Text>{' '}
                        <Plural value={freeUserCount} one="free user" other="free users" />
                    </Text>
                )}{' '}
                ・ <Text>{dayjs(firstReferredDate).format('DD MMMM YYYY')}</Text>
            </Inline>
        </Inline>
    )
}

function ReferralsButton({onClick, children}: Pick<ButtonProps, 'children' | 'onClick'>) {
    return (
        <Inline>
            <Button variant="tertiary" onClick={onClick}>
                {children}
            </Button>
        </Inline>
    )
}
