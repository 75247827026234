import {t, Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {Button, LoadingPage, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, Wand} from '@pleo-io/telescope-icons'

import {defineClientContext} from '@product-web/bff-client/operational-context'
import {pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {useLocalisedIllustration} from '@product-web-features/adoption/empty-states/empty-states.helper'
import {LandingPagesWrapper} from '@product-web-features/adoption/landing-pages/landing-pages-wrapper'

import {bff} from '../../../bff'
import {useTagBudgetTracking} from '../../../lib/use-tag-budget-tracking'

export const ViewDemoButton = ({
    onOpenDrawer,
    alternativeCopy,
}: {
    onOpenDrawer: (id: string) => void
    alternativeCopy?: boolean
}) => {
    const tracking = useTagBudgetTracking()
    return (
        <Button
            variant="secondary"
            IconRight={alternativeCopy ? undefined : Wand}
            data-testid="view-demo-budget-button"
            onClick={() => {
                tracking.demoBudgetActioned({
                    action: 'clicked',
                    source: 'view_demo_budget_button',
                })
                onOpenDrawer('demo')
            }}
        >
            {alternativeCopy ? <Trans>View demo</Trans> : <Trans>View demo budget</Trans>}
        </Button>
    )
}

export const TagBudgetsEmptyState = ({onOpenDrawer}: {onOpenDrawer: (id: string) => void}) => {
    const {illustration: EmptyStatesBudgetsIllustration} = useLocalisedIllustration({
        type: 'budgets',
    })

    const {data: hasTags, isLoading} = bff.tagBudgetsEmptyState.getHasTagsInfo.useQuery(undefined, {
        trpc: {
            context: defineClientContext({
                skipBatch: true,
            }),
        },
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const EntitledAction = () =>
        hasTags ? (
            <Button variant="primary" as={RouterLink} to="/budgets/create">
                <Trans>Create budget</Trans>
            </Button>
        ) : (
            <Button
                variant="primary"
                as={RouterLink}
                to="/settings/accounting/tags"
                IconRight={ArrowRight}
            >
                <Trans>Create tag</Trans>
            </Button>
        )

    return (
        <LandingPagesWrapper
            pageOrigin="budgets"
            topAction={<ViewDemoButton onOpenDrawer={onOpenDrawer} alternativeCopy />}
            entitledCTA={<EntitledAction />}
        >
            <BlankStateWrapper>
                <Stack align="center">
                    <IllustrationStack align="center">
                        <img
                            src={EmptyStatesBudgetsIllustration}
                            alt={t`Stay in control of budgets`}
                            data-testid="empty-state-illustration"
                        />
                    </IllustrationStack>
                    <CopyStack align="center" space={4}>
                        <Text variant="2xlarge-accent" color="shade800" align="center">
                            <Trans>Stay in control of budgets</Trans>
                        </Text>

                        <Stack paddingTop={4} space={12} stretch>
                            <Stack space={0} stretch>
                                <Text as="p" variant="small-subtle" color="shade700" align="center">
                                    <Trans>
                                        Set budgets for projects, clients, events, and more using
                                        tags.
                                    </Trans>
                                </Text>
                                <Text as="p" variant="small-subtle" color="shade700" align="center">
                                    <Trans>
                                        Card and out-of-pocket expenses marked with the chosen tags
                                        will be allocated to the budget.
                                    </Trans>
                                </Text>
                            </Stack>
                            {!hasTags && (
                                <Text as="p" variant="small-subtle" color="shade700" align="center">
                                    <Trans>Your company doesn't have tags for budgets yet.</Trans>
                                </Text>
                            )}
                        </Stack>

                        <Actions paddingTop={12} space={16} align={'center'}>
                            <EntitledAction />
                            <ViewDemoButton onOpenDrawer={onOpenDrawer} />
                        </Actions>
                    </CopyStack>
                </Stack>
            </BlankStateWrapper>
        </LandingPagesWrapper>
    )
}

const BlankStateWrapper = styled.section`
    margin-top: ${tokens.spacing40};
    width: 100%;
    max-width: 500px;

    ${containerQuery(
        {minWidth: pxBreakpoints.largeDesktop, name: 'main'},
        css`
            max-width: 900px;
        `,
    )}
`

const CopyStack = styled(Stack)`
    max-width: calc(100% - ${tokens.spacing60});
`

const IllustrationStack = styled(Stack)`
    background-color: ${tokens.blue200};
    padding-top: ${tokens.spacing32};
    margin-bottom: ${tokens.spacing20};
    border-radius: ${tokens.arc8};

    & > img {
        width: calc(100% - ${tokens.spacing60});
    }
`

const Actions = styled(Stack)`
    width: auto;

    & > * {
        width: 100%;
    }
`
