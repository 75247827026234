import qs from 'qs'
import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import type {
    Account,
    AccountCategory,
    AccountCategoryTypeKey,
    CreateAccount,
    CreateAccountCategory,
    CreateAccountCategoryResponse,
    CreateAccountResponse,
    GetAllAccountCategoryResponse,
    SuccessResponse,
    UpdateAccount,
    UpdateAccountCategory,
    UpdateAccountCategoryResponse,
    UpdateAccountResponse,
} from '@pleo-io/deimos'

import {request} from '@product-web/api'
import config from '@product-web/config'
import {useCompanyUser} from '@product-web/user'

import {findAccountItem} from './helpers'

import {getDeimos} from '../helpers'

const baseUrl = config.endpoints.api
async function createAccountCategory(
    companyId: string,
    payload: Partial<CreateAccountCategory>,
): Promise<CreateAccountCategoryResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/account-categories`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

async function updateAccountCategory(
    id: string,
    payload: Partial<UpdateAccountCategory>,
): Promise<UpdateAccountCategoryResponse> {
    return request(`${baseUrl}/rest/v1/account-categories/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}
async function deleteAccountCategory(id: string): Promise<SuccessResponse> {
    return request(`${baseUrl}/rest/v1/account-categories/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

async function createAccountCategoryAccount(
    companyId: string,
    payload: Partial<CreateAccount>,
): Promise<CreateAccountResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/accounts`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

async function updateAccountCategoryAccount(
    id: string,
    payload: Partial<UpdateAccount>,
): Promise<UpdateAccountResponse> {
    return request(`${baseUrl}/rest/v1/accounts/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}
async function deleteAccountCategoryAccount(id: string): Promise<SuccessResponse> {
    return request(`${baseUrl}/rest/v1/accounts/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export type ImportAccountCategoriesPayload = {
    file: File
    onProgress?: (number: number) => void
}

interface ImportAccountCategoriesResultItem {
    categoryName: string
    createNew: boolean
    addCount: number
    categoryType: AccountCategoryTypeKey
}

export type ImportAccountCategoriesResult = {
    items: ImportAccountCategoriesResultItem[]
}

export type ExportAccountCategoriesResult = {
    url: string
    filename: string
}

async function importCategories(
    id: string,
    payload: ImportAccountCategoriesPayload,
    dryRun = false,
): Promise<ImportAccountCategoriesResult> {
    return request(`${baseUrl}/rest/v1/company-settings/${id}/account-categories/import`, {
        auth: 'user',
        method: 'POST',
        query: {dryRun},
        ...payload,
    })
}

async function exportCategories(
    id: string,
    categoryIds?: string[],
): Promise<ExportAccountCategoriesResult> {
    const queryParams = qs.stringify({categoryIds}, {indices: false})
    const normalizedQueryParams = queryParams ? '?' + queryParams : ''

    return request(
        `${baseUrl}/rest/v1/company-settings/${id}/account-categories/export${normalizedQueryParams}`,
        {
            auth: 'user',
            method: 'GET',
        },
    )
}

export function useAccountCategories({
    bypassHook = false,
    swrConfig = {revalidateOnFocus: false},
    includeDepartments = false,
    expenseCompanyId,
}: {
    bypassHook?: boolean
    swrConfig?: SWRConfiguration
    includeDepartments?: boolean
    expenseCompanyId?: string
} = {}) {
    const {companyId} = useCompanyUser()
    const departmentsAppendix = includeDepartments ? '&includeDepartments=true' : ''
    const url =
        companyId && !bypassHook
            ? `/rest/v1/companies/${
                  expenseCompanyId ?? companyId
              }/account-categories?eager=true&sortBy=createdAt&order=DESC${departmentsAppendix}`
            : null

    const result = useSWR<GetAllAccountCategoryResponse, Error>(url, getDeimos, swrConfig)

    async function importAccountCategories(
        payload: ImportAccountCategoriesPayload,
        dryRun = false,
    ): Promise<ImportAccountCategoriesResult> {
        return await importCategories(companyId, payload, dryRun)
    }

    async function exportAccountCategories(
        categoryIds?: string[],
    ): Promise<ExportAccountCategoriesResult> {
        return await exportCategories(companyId, categoryIds)
    }

    async function createCategory(payload: Partial<CreateAccountCategory>) {
        if (!result?.data || !companyId) {
            return
        }
        const response = await createAccountCategory(companyId, payload)
        result.mutate([response, ...result?.data], false)
    }

    async function updateCategory(id: string, payload: Partial<UpdateAccountCategory>) {
        const index =
            result?.data?.findIndex((category: AccountCategory) => category.id === id) ?? -1
        if (!result?.data || index === -1) {
            return
        }
        const response = await updateAccountCategory(id, payload)
        const updatedCategory = {...result.data[index], ...response}
        result.mutate(
            [...result.data.slice(0, index), updatedCategory, ...result.data.slice(index + 1)],
            false,
        )
    }

    async function archiveCategory(id: string) {
        await updateCategory(id, {hidden: true})
    }

    async function unarchiveCategory(id: string) {
        await updateCategory(id, {hidden: false})
    }

    async function deleteCategory(id: string) {
        const index =
            result?.data?.findIndex((category: AccountCategory) => category.id === id) ?? -1
        if (!result?.data || index === -1) {
            return
        }
        await deleteAccountCategory(id)
        result.mutate([...result?.data?.slice(0, index), ...result?.data?.slice(index + 1)], false)
    }

    async function createAccount(payload: Partial<CreateAccount>) {
        const index =
            result?.data?.findIndex(
                (category: AccountCategory) => category.id === payload.accountCategoryId,
            ) ?? -1
        if (!result?.data || index === -1 || !companyId) {
            return
        }

        const response = await createAccountCategoryAccount(companyId, payload)
        result.mutate(
            [
                ...result.data.slice(0, index),
                {
                    ...result.data[index],
                    accounts: [...(result.data[index]?.accounts ?? []), response],
                },
                ...result.data.slice(index + 1),
            ],
            false,
        )
    }
    async function updateAccount(id: string, payload: Partial<UpdateAccount>) {
        const item = findAccountItem(id, result?.data ?? [])
        if (!result?.data || item.accountIndex === -1 || item.accountCategoryIndex === -1) {
            return
        }
        const accounts = result.data[item.accountCategoryIndex].accounts || []
        const response = await updateAccountCategoryAccount(id, payload)
        result.mutate(
            [
                ...result.data.slice(0, item.accountCategoryIndex),
                {
                    ...result.data[item.accountCategoryIndex],
                    accounts: [
                        ...accounts.slice(0, item.accountIndex),
                        {
                            ...response,
                        },
                        ...accounts.slice(item.accountIndex + 1),
                    ],
                },
                ...result.data.slice(item.accountCategoryIndex + 1),
            ],
            false,
        )
    }

    async function archiveAccount(id: string) {
        const item = findAccountItem(id, result?.data ?? [])
        if (!result?.data || item.accountIndex === -1 || item.accountCategoryIndex === -1) {
            return
        }

        const accounts = result.data[item.accountCategoryIndex].accounts || []
        await updateAccountCategoryAccount(id, {hidden: true})
        result.mutate([
            ...result.data.slice(0, item.accountCategoryIndex),
            {
                ...result.data[item.accountCategoryIndex],
                accounts: accounts.slice(0).filter((account: Account) => account.id !== id),
            },
            ...result.data.slice(item.accountCategoryIndex + 1),
        ])
    }

    async function unarchiveAccount(id: string) {
        const item = findAccountItem(id, result?.data ?? [])
        if (!result?.data || item.accountIndex === -1 || item.accountCategoryIndex === -1) {
            return
        }

        const accounts = result.data[item.accountCategoryIndex].accounts || []
        await updateAccountCategoryAccount(id, {hidden: false})
        result.mutate([
            ...result.data.slice(0, item.accountCategoryIndex),
            {
                ...result.data[item.accountCategoryIndex],
                accounts: accounts.slice(0).filter((account: Account) => account.id !== id),
            },
            ...result.data.slice(item.accountCategoryIndex + 1),
        ])
    }

    async function deleteAccount(id: string) {
        const item = findAccountItem(id, result?.data ?? [])
        if (!result?.data || item.accountIndex === -1 || item.accountCategoryIndex === -1) {
            return
        }

        const accounts = result.data[item.accountCategoryIndex].accounts || []
        await deleteAccountCategoryAccount(id)
        result.mutate(
            [
                ...result.data.slice(0, item.accountCategoryIndex),
                {
                    ...result.data[item.accountCategoryIndex],
                    accounts: [
                        ...accounts.slice(0, item.accountIndex),
                        ...accounts.slice(item.accountIndex + 1),
                    ],
                },
                ...result.data.slice(item.accountCategoryIndex + 1),
            ],
            false,
        )
    }

    return {
        ...result,
        mutations: {
            createCategory,
            updateCategory,
            archiveCategory,
            unarchiveCategory,
            deleteCategory,
            createAccount,
            updateAccount,
            archiveAccount,
            unarchiveAccount,
            deleteAccount,
            importAccountCategories,
            exportAccountCategories,
        },
    }
}
