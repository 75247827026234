import * as React from 'react'
import {Navigate, Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const PurchaseOrdersWrapper = React.lazy(async () => import('./components/wrapper'))
const PurchaseOrders = React.lazy(async () => import('./screens/dashboard/purchase-orders'))
const CreatePurchaseOrder = React.lazy(
    async () => import('./screens/details/create-purchase-order'),
)

export const routes = (
    <Route
        element={<PurchaseOrdersWrapper />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
    >
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<PurchaseOrders />} />
        <Route path="create" element={<CreatePurchaseOrder />} />
    </Route>
)
