import {Trans} from '@lingui/macro'
import type {FC} from 'react'
import type React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {focusRing, Inline, Popover, Stack, Text, tokens} from '@pleo-io/telescope'
import {Eye} from '@pleo-io/telescope-icons'

import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import FlaggedLabel from './flagged-label'
import {type useNavigationItems, useReviewPageOnboardingUI} from './use-navigation-items'

export function NavigationLink({
    item,
    onClickItem,
    reloadDocument,
}: {
    item: ReturnType<typeof useNavigationItems>['items'][number]
    onClickItem?: () => void
    reloadDocument?: boolean
}) {
    const pathname = useLocation().pathname
    const isDesktopView = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)
    const {showReviewPageOnboarding, markReviewPageOnboardingAsViewed} = useReviewPageOnboardingUI()

    const menuItem = (
        <NavItemInline alignItems="center">
            <CompactNavItemLink
                draggable={false}
                match={item.match}
                onClick={onClickItem}
                pathname={pathname}
                reloadDocument={reloadDocument}
                to={item.to}
                exact={pathname === '/reimbursements' || pathname === '/reimbursements-v2'} //we can remove this when we delete the reimbursementV2 feature flag logic
            >
                {item.newFeature ? (
                    <NewFeatureSpan>
                        {item.flag ? (
                            <FlaggedLabel label={item.label} flag={item.flag} />
                        ) : (
                            item.label
                        )}
                    </NewFeatureSpan>
                ) : (
                    item.label
                )}
                {item.extraNode?.()}
            </CompactNavItemLink>
        </NavItemInline>
    )

    if (showReviewPageOnboarding && item.to === '/review' && isDesktopView) {
        return (
            <Popover open={true} defaultOpen={true}>
                <Popover.Trigger>{menuItem}</Popover.Trigger>
                <Popover.Content side="right">
                    <Inline
                        p={20}
                        space={16}
                        data-testid="review-have-new-home-onboarding"
                        css={{maxWidth: '460px'}}
                    >
                        <Inline
                            css={{
                                flex: '64px 0 0',
                                height: '64px',
                                backgroundColor: tokens.colorBackgroundPresentationalYellow,
                                borderRadius: tokens.arc12,
                            }}
                            alignX="center"
                            alignY="center"
                        >
                            <Eye size={32} />
                        </Inline>
                        <Stack>
                            <Text variant="large-accent" weight={'medium'}>
                                <Trans>Review has a new home</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    A focused view to keep track of your company's spending.
                                </Trans>
                            </Text>
                        </Stack>
                        <Popover.Close onClick={() => markReviewPageOnboardingAsViewed()} />
                    </Inline>
                    <Popover.Arrow />
                </Popover.Content>
            </Popover>
        )
    }

    return menuItem
}

interface LinkProps {
    to: string
    pathname: string
    exact?: boolean
    match?: string
    children: React.ReactNode | string
    [key: string]: any
}

const Link: FC<LinkProps> = ({to, pathname, exact, match, children, ...rest}) => {
    const pattern = match ? match : to
    const regex = exact ? new RegExp(`^${pattern}$`) : new RegExp(`^${pattern}`)
    const isActive = regex.test(pathname)

    return (
        <GlobalNavigationLink
            $isActive={isActive}
            to={to}
            aria-current={isActive ? 'page' : undefined}
            {...rest}
        >
            {children}
        </GlobalNavigationLink>
    )
}

const NewFeatureSpan = styled.span`
    position: relative;
`

const NavItemInline = styled(Inline)`
    width: 100%;
    position: relative;

    &:last-child {
        margin-bottom: ${tokens.spacing10};
    }
`

const CompactNavItemLink = styled(Link)`
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
`

const GlobalNavigationLink = styled(RouterLink)<{$isActive?: boolean}>`
    width: 86%;
    display: flex;
    min-height: 38px;
    box-sizing: border-box;
    padding-left: ${tokens.spacing32};
    margin: ${tokens.spacing2} ${tokens.spacing16};
    font-family: inherit;
    font-size: 14px;
    color: ${tokens.colorContentInteractive};
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    align-items: center;
    border-radius: ${tokens.arc4};
    ${focusRing('inset')};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        border-radius: ${tokens.arc4};
    }

    ${(props) =>
        props.$isActive &&
        css`
            position: relative;
            font-weight: ${tokens.fontWeightBold};
        `}
`
