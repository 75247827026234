import {AnimatePresence, motion} from 'framer-motion'
import type {ComponentType, ReactNode} from 'react'
import {createContext, useContext, useState} from 'react'
import styled, {css} from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

import {useToggleNavigation} from '@product-web/navigation/navigation-context'
import {breakpoints, getBreakpoint, pxBreakpoints} from '@product-web/styles/theme'
import {containerQuery} from '@product-web/telescope-lab/container-queries/container'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

const TRANSITION = tokens.smoothInOut

const withAnimatedPresence =
    (Component: ComponentType<{isPageSplit?: boolean}>) =>
    ({children}: {children: ReactNode}) => {
        const {isPageSplit} = usePageContext()
        return (
            <AnimatePresence>
                <Component isPageSplit={isPageSplit}>{children}</Component>
            </AnimatePresence>
        )
    }
export const mainSectionMotionVariants = {
    initial: {
        width: '100%',
        transition: {
            width: TRANSITION,
        },
    },
    split: {
        width: '50%',
        transition: {
            width: TRANSITION,
        },
    },
}

export const getDetailSectionMotionVariants = (isMobile: boolean) => ({
    initial: {
        left: '100%',
        transition: {
            left: TRANSITION,
        },
        transitionEnd: {
            display: 'none',
        },
    },
    animateIn: {
        left: isMobile ? '0%' : '50%',
        display: 'block',
        transition: {
            left: TRANSITION,
        },
    },
    animateOut: {
        left: '100%',
        transition: {
            left: TRANSITION,
        },
        transitionEnd: {
            display: 'none',
        },
    },
})
/**
 * TYPES
 * */
interface MainSectionProps {
    layout: 'narrow' | 'wide'
    children?: ReactNode
    isPageSplit: boolean
}

interface PageProps {
    layout: 'narrow' | 'wide'
    children?: ReactNode
    showNav: boolean
}

type PageContextType = {
    isPageSplit: boolean
    toggleSplitDetailsPage: () => void
    setIsPageSplit: (arg: boolean) => void
}

export const Layout = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100vw;
    height: 100vh;

    @media (max-width: ${getBreakpoint('tabletMedUp')}) {
        flex-direction: column;
    }
`

export const PageContext = createContext<PageContextType>({
    isPageSplit: false,
    toggleSplitDetailsPage: () => {},
    setIsPageSplit: () => {},
})

export const usePageContext = () => useContext(PageContext)

export const Page = ({children, layout, showNav}: PageProps) => {
    const [isPageSplit, setIsPageSplit] = useState(false)
    const toggleSplitDetailsPage = () => setIsPageSplit(!isPageSplit)
    useToggleNavigation({
        showNav,
    })
    return (
        <PageContext.Provider value={{toggleSplitDetailsPage, isPageSplit, setIsPageSplit}}>
            <Container data-generic-ui="page">
                <MainSectionWrapper isPageSplit={isPageSplit}>
                    <MainSection isPageSplit={isPageSplit} layout={layout}>
                        {children}
                    </MainSection>
                </MainSectionWrapper>
            </Container>
        </PageContext.Provider>
    )
}

/**
 * COMPOSABLE COMPONENTS
 * */

const Title = styled(Text).attrs({
    as: 'h1',
    variant: '3xlarge-accent',
    color: 'shade800',
})`
    position: static;
    font-size: ${tokens.font3XLarge};
    margin-bottom: ${tokens.spacing16};
    margin-left: 0;
    top: ${tokens.spacing12};
    left: 42px;
    z-index: ${tokens.zIndexNavigation};

    @media (max-width: ${getBreakpoint('tabletMedUp')}) {
        position: fixed;
        font-size: ${tokens.fontXLarge};
        margin-bottom: 0;
        margin-left: ${tokens.spacing6};
        top: 4px;
    }
`

const Actions = styled.div`
    display: flex;
    gap: ${tokens.spacing24};
    flex: 1;
    justify-content: center;

    ${containerQuery(
        {minWidth: pxBreakpoints.smallTabletUp},
        css`
            justify-content: flex-end;
        `,
    )}
`

const Tabs = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;

    [role='tab'] {
        padding: ${tokens.spacing8} 0;
    }

    ${containerQuery(
        {minWidth: pxBreakpoints.smallTabletUp},
        css`
            justify-content: flex-start;
        `,
    )}
`

const SpaceBetween = styled.div`
    display: grid;
    grid-gap: ${tokens.spacing28};
    justify-content: center;
    justify-items: center;
    margin-bottom: ${tokens.spacing16};

    ${Title} {
        margin-bottom: 0;
    }

    ${containerQuery(
        {minWidth: pxBreakpoints.smallTabletUp},
        css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: ${tokens.spacing32};
        `,
    )}

    ${containerQuery(
        {minWidth: pxBreakpoints.tabletMedUp},
        css`
            gap: 0;
        `,
    )}
`

const Banners = styled.div`
    > * {
        margin-bottom: ${tokens.spacing32};
    }

    /* Some render <a> tags as banners, and they are not affected by margin unless the display is changed.
    This is a temporary fix, as we are working on a Telescope Banner component */

    > a {
        display: block;
    }
`

const Backlink = styled.div`
    position: absolute;
    top: -${tokens.spacing36};
`

const Header = styled.header`
    position: relative;

    ${Backlink} + ${Banners} {
        margin-top: ${tokens.spacing16};
    }

    > *:last-child {
        margin-bottom: ${tokens.spacing16};
    }
`

const DetailSection = styled(motion.section).attrs<{isPageSplit?: boolean}>(({isPageSplit}) => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mediumTabletUp})`)
    return {
        key: 'detailSection',
        initial: 'initial',
        animate: isPageSplit ? 'animateIn' : 'initial',
        exit: 'animateOut',
        variants: getDetailSectionMotionVariants(isMobile),
    }
})`
    position: fixed;
    top: ${tokens.spacing56};
    width: 100vw;
    height: 100vh;
    border-left: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    background-color: ${tokens.shade000};
    z-index: ${tokens.zIndexModal};

    @media (min-width: ${breakpoints.mediumTabletUp}) {
        position: absolute;
        width: 50%;
        top: 0;
    }
`

Page.Header = Header
Page.Title = Title
Page.Backlink = Backlink
Page.Banners = Banners
Page.Actions = Actions
Page.Tabs = Tabs
Page.SpaceBetween = SpaceBetween
Page.DetailSection = withAnimatedPresence(DetailSection)

/**
 * STYLES
 * */

export const Container = styled.section`
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
`

export const MainSectionWrapper = styled(motion.div).attrs<{isPageSplit?: boolean}>(
    ({isPageSplit}) => {
        const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp})`)
        if (isMobile) {
            return {}
        }
        return {
            key: 'mainSection',
            initial: 'initial',
            animate: isPageSplit ? 'split' : 'initial',
            variants: mainSectionMotionVariants,
        }
    },
)`
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
`

const MainSection = styled.section<MainSectionProps>`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: ${tokens.spacing36} ${tokens.spacing24};
    box-sizing: border-box;

    ${({isPageSplit}) =>
        isPageSplit &&
        css`
            overflow: auto;
        `};

    @media (min-width: ${getBreakpoint('smallTabletUp')}) {
        padding: 55px ${tokens.spacing40};
    }

    @media (min-width: ${getBreakpoint('tabletMedUp')}) {
        padding: 76px ${tokens.spacing56} 0;
    }

    ${({layout, isPageSplit}) =>
        layout === 'wide' &&
        `
        @media (min-width: ${breakpoints.desktopMedUp}) {
            max-width:  ${isPageSplit ? '100%' : '1200px'};
        };
    `}

    ${({layout, isPageSplit}) =>
        layout === 'narrow' &&
        `
            max-width: 652px;

            @media (max-width: ${breakpoints.tabletMedUp}) {
                padding: 55px ${tokens.spacing24};
            };

            @media (min-width: ${breakpoints.tabletMedUp}) and (max-width: ${
            breakpoints.desktopUp
        }) {
                padding: 76px ${tokens.spacing24};
            };

            @media (min-width: ${breakpoints.desktopUp}) {
                padding: 76px ${isPageSplit ? tokens.spacing56 : 0};
            };
    `};
`
