import {Trans} from '@lingui/macro'
import type {MutableRefObject} from 'react'
import {useCallback, useEffect} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled, {keyframes} from 'styled-components'

import {focusRing, NakedButton, tokens, Tooltip} from '@pleo-io/telescope'
import {motionEnterSmallShort} from '@pleo-io/telescope-tokens/dist/motion'

import * as tracking from '@product-web/analytics'
import {useIsLoggedIn} from '@product-web/auth--session/context'
import {useFlags} from '@product-web/flags'
import {useSupportChat} from '@product-web/freshchat/use-support-chat'
import {SupportedLanguage} from '@product-web/i18n'
import {
    getHelpCentreArticleLinkIntercom,
    getLanguageByLocale,
    getLocaleForHelpCentre,
} from '@product-web/locale/helpers'
import {getHelpCentreFolderLink} from '@product-web/locale/helpers'
import Locale from '@product-web/locale/locale'
import {getLocale, useUser} from '@product-web/user'
import {useOnClickOutside} from '@product-web/web-platform/use-on-click-outside'
import {useHelpCentre} from '@product-web-features/help-centre/help-centre-context'

import {helpCentreLinkButtonStyles} from '../help-centre-link-button'

const COMMERCIAL_SUPPORTED_LANGUAGES = [
    SupportedLanguage.EN,
    SupportedLanguage.DA,
    SupportedLanguage.DE,
    SupportedLanguage.ES,
    SupportedLanguage.SV,
]

const HELP_CENTRE_SUPPORTED_LANGUAGES = [
    SupportedLanguage.DA,
    SupportedLanguage.DE,
    SupportedLanguage.EN,
    SupportedLanguage.ES,
    SupportedLanguage.FR,
    SupportedLanguage.SV,
    SupportedLanguage.FI,
    SupportedLanguage.IT,
    SupportedLanguage.NL,
    SupportedLanguage.PT,
    SupportedLanguage.NO,
]

const getHelpCentreLinkLanguage = (locale: Locale) => {
    let language = getLanguageByLocale(getLocaleForHelpCentre(locale))
    if (!HELP_CENTRE_SUPPORTED_LANGUAGES.includes(language)) {
        language = SupportedLanguage.EN
    }
    return language
}

// TODO: Get rid of this when we have updated the commercial website to use the same locale code. See slack conversation https://getpleo.slack.com/archives/CQBEP9295/p1641215877179400
// and halp ticket https://getpleo.halp.com/tickets/3358
const getCommercialLinkLanguage = (locale: Locale) => {
    const language = getLanguageByLocale(locale)
    if (language === SupportedLanguage.SV) {
        return 'se'
    }

    if (!COMMERCIAL_SUPPORTED_LANGUAGES.includes(language)) {
        return SupportedLanguage.EN
    }

    return language
}

const HelpWidgetButton = styled(NakedButton)`
    ${helpCentreLinkButtonStyles}
`

const HelpWidgetLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    ${helpCentreLinkButtonStyles}
`

interface HelpWidgetProps {
    portalRef: MutableRefObject<HTMLDivElement | null>
}

export const HelpWidget = ({portalRef}: HelpWidgetProps) => {
    const {isHelpWidgetOpen, setIsHelpWidgetOpen} = useHelpCentre()
    const supportChat = useSupportChat()
    const isLoggedIn = useIsLoggedIn()

    const user = useUser()
    const locale = user ? getLocale(user) : Locale.en_GB
    const commercialLinkLanguage = getCommercialLinkLanguage(locale)
    const helpCentreLanguage = getHelpCentreLinkLanguage(locale)
    const {isEarnRewardsLive} = useFlags()

    useOnClickOutside(portalRef, () => {
        if (!isHelpWidgetOpen) {
            return
        }

        setIsHelpWidgetOpen(false)
    })

    const toggle = useCallback(() => {
        setIsHelpWidgetOpen(!isHelpWidgetOpen)
        if (!isHelpWidgetOpen) {
            tracking.helpWidgetViewed({
                action: 'viewed',
            })
        }
    }, [isHelpWidgetOpen, setIsHelpWidgetOpen])

    const openChat = async () => {
        setIsHelpWidgetOpen(false)
        await supportChat.show()
        onTrackedLinkClick('contact_us')
    }

    useEffect(() => {
        supportChat.onHide(() => {
            setIsHelpWidgetOpen(false)
        })
        supportChat.onShow(() => {
            setIsHelpWidgetOpen(true)
        })
    }, [])

    const onTrackedLinkClick = (
        action: 'contact_us' | 'help_centre' | 'referral' | 'status' | 'terms' | 'updates',
    ) => {
        tracking.helpWidgetActioned({
            action,
            source: 'help_widget',
        })
    }

    if (!isHelpWidgetOpen) {
        return (
            <Tooltip content={<Trans>Help, Feedback, Updates...</Trans>}>
                <MoreToggle data-testid="help-widget" onClick={toggle}>
                    ?
                </MoreToggle>
            </Tooltip>
        )
    }

    return (
        <Content>
            <HelpWidgetLink
                href={getHelpCentreArticleLinkIntercom(undefined, helpCentreLanguage)}
                onClick={() => onTrackedLinkClick('help_centre')}
            >
                <span role="img" aria-hidden="true">
                    💡
                </span>
                <Trans>Help centre</Trans>
            </HelpWidgetLink>
            {isLoggedIn ? (
                <HelpWidgetButton id="moreChatWithUs" onClick={openChat}>
                    <span role="img" aria-hidden="true">
                        💬
                    </span>
                    <Trans>Chat with us</Trans>
                </HelpWidgetButton>
            ) : (
                <HelpWidgetLink
                    data-testid="login-issues-button"
                    href={getHelpCentreFolderLink(103000620239)}
                >
                    <span role="img" aria-hidden="true">
                        💬
                    </span>
                    <Trans>Login issues</Trans>
                </HelpWidgetLink>
            )}
            <Divider />
            <HelpWidgetLink
                href={`https://blog.pleo.io/${commercialLinkLanguage}/category/pleo-updates`}
                onClick={() => onTrackedLinkClick('updates')}
            >
                <span role="img" aria-hidden="true">
                    🎁
                </span>
                <Trans>What's new</Trans>
            </HelpWidgetLink>
            {isEarnRewardsLive && (
                <HelpWidgetButton
                    as={RouterLink}
                    to="/account/earn-rewards"
                    onClick={() => {
                        onTrackedLinkClick('referral')
                        toggle()
                    }}
                    data-testid="referral-button"
                >
                    <span role="img" aria-hidden="true">
                        🤝
                    </span>{' '}
                    <Trans>Refer a friend</Trans>
                </HelpWidgetButton>
            )}
            <Divider />
            <HelpWidgetLink
                href={`https://www.pleo.io/${commercialLinkLanguage}/legal`}
                onClick={() => onTrackedLinkClick('terms')}
                css={{fontSize: tokens.fontSmall}}
            >
                <Trans>Terms</Trans>
            </HelpWidgetLink>
            <HelpWidgetLink
                href="https://status.pleo.io"
                onClick={() => onTrackedLinkClick('status')}
                css={{fontSize: tokens.fontSmall}}
            >
                <Trans>Service status</Trans>
            </HelpWidgetLink>
        </Content>
    )
}

const MoreToggle = styled(NakedButton)`
    ${focusRing('inset')};
    width: 60px;
    height: 60px;
    padding: 0;
    font-weight: ${tokens.fontWeightMedium};
    font-size: ${tokens.font3XLarge};
    line-height: 60px; /* stylelint-disable-line */
    color: ${tokens.colorContentInteractive};
    text-align: center;
    background-color: ${tokens.colorBackgroundInteractive};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
    border-radius: ${tokens.circle};
    box-shadow: none;
    transition: ${tokens.motionWithinSmallShort};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
        box-shadow: ${tokens.shadowElevateQuiet};
    }
`

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing12};
    padding: ${tokens.spacing20} ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowElevateQuiet};
    animation: ${fadeInUp} ${motionEnterSmallShort};
`

const Divider = styled.div`
    height: ${tokens.sizeBorderDefault};
    margin-block: ${tokens.spacing4};
    background: ${tokens.colorBorderStatic};
`
