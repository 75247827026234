import {useCallback} from 'react'

import type {AutoTopUpStatus, AutoTopUpType} from '@product-web/api-types/wallet'
import {useLoggedInUser} from '@product-web/user'
import {createPersistedState} from '@product-web/web-platform/persisted-state'
import {localStorage} from '@product-web/web-platform/storage/local'

const [usePersistedState] = createPersistedState('autoTopUp', localStorage)

// we need to use the local storage here (instead of React context, for example),
// because we need to store provided settings while user redirects to and from their bank website,
// where they receive the consent token to approve the Open Banking transfer
export function useVrpAutoTopUpStorage() {
    const user = useLoggedInUser()
    const [savedVrpAutoTopUpSettings, setSavedVrpAutoTopUpSettings] = usePersistedState<{
        autoTopupStatus?: AutoTopUpStatus
        autoTopupType: AutoTopUpType
        autoTopUpAmount: number
        autoTopUpThreshold: number
        pleoReserveEnabled?: boolean
        isSwitchingToFastOptionFlow?: boolean
    } | null>(`vrpAutoTopUpSettings_${user.companyId}`, null)

    const cleanSavedVrpAutoTopUpSettings = useCallback(() => {
        setSavedVrpAutoTopUpSettings(null)
    }, [setSavedVrpAutoTopUpSettings])

    return {
        savedVrpAutoTopUpSettings,
        setSavedVrpAutoTopUpSettings,
        cleanSavedVrpAutoTopUpSettings,
    }
}
