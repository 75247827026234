import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

import {WalletRouteErrorBoundary} from './components/wallet-error-boundary'

const EditOverdraftPage = React.lazy(async () => import('./screens/overdraft/edit-overdraft'))

const AutoTopUpActivationPage = React.lazy(
    async () => import('./screens/auto-top-up-activation/auto-top-up-activation'),
)
const ChargebackPage = React.lazy(async () => import('./screens/chargeback/chargeback'))
const DirectDebitPage = React.lazy(async () => import('./screens/direct-debit/direct-debit'))
const ManageWalletPage = React.lazy(async () => import('./screens/manage-wallet/manage-wallet'))
const OverdraftStatementsPage = React.lazy(
    async () => import('./screens/overdraft/overdraft-statements'),
)
const ChangeOverdraftLimitPage = React.lazy(
    async () => import('./screens/overdraft/change-limit/change-overdraft-limit'),
)
const HigherLimitApplication = React.lazy(
    async () => import('./screens/overdraft/higher-limit-application/higher-limit-application'),
)
const HigherLimitActionRequired = React.lazy(
    async () => import('./screens/overdraft/higher-limit-action-required'),
)
const HigherLimitActionRequiredSuccess = React.lazy(
    async () => import('./screens/overdraft/higher-limit-action-required-success'),
)
const ApplicationSuccessPage = React.lazy(
    async () =>
        import(
            './screens/overdraft/higher-limit-application/higher-limit-application-success-page'
        ),
)
const UnloadPage = React.lazy(async () => import('./screens/unload/unload'))
const WalletHistoryPage = React.lazy(async () => import('./screens/wallet-history/wallet-history'))
const WalletPage = React.lazy(async () => import('./screens/wallet/wallet'))
const SubWalletCreationPage = React.lazy(
    async () => import('./screens/sub-wallet/sub-wallet-creation'),
)
const WalletWithdrawalPage = React.lazy(
    async () => import('./screens/wallet-withdrawal/wallet-withdrawal'),
)

const HigherLimitApplicationRejectionInfo = React.lazy(
    async () => import('./screens/overdraft/higher-limit-rejection-info'),
)

const HigherLimitApplicationApprovalInfo = React.lazy(
    async () => import('./screens/overdraft/higher-limit-approval-info'),
)

export const routes = (
    <Route element={<WalletRouteErrorBoundary />}>
        <Route
            path="chargeback"
            element={<ChargebackPage />}
            handle={{
                auth: true,
                allowedRoles: roleSets.company,
            }}
        />
        <Route
            handle={{
                auth: true,
                minCompanyStatus: 'sdd',
                allowedRoles: roleSets.ownerAndBookkeeper,
            }}
        >
            <Route index element={<WalletPage />} />
            <Route path="auto-top-up-activation" element={<AutoTopUpActivationPage />} />

            <Route path="manage/overdraft/statements" element={<OverdraftStatementsPage />} />
            <Route path="manage/overdraft/change-limit" element={<ChangeOverdraftLimitPage />} />
            <Route path="manage/:product?" element={<ManageWalletPage />} />

            <Route path="direct-debit" element={<DirectDebitPage />} />
            <Route path="direct-debit/:product" element={<DirectDebitPage />} />

            <Route path="create-sub-wallet" element={<SubWalletCreationPage />} />

            <Route path="withdrawal" element={<WalletWithdrawalPage />} />
            <Route path="history" element={<WalletHistoryPage />} />

            <Route path="unload" element={<UnloadPage />} />
            <Route path="unload/:account" element={<UnloadPage />} />

            <Route path="overdraft/activate" element={<EditOverdraftPage operation="activate" />} />
            <Route path="overdraft/renew" element={<EditOverdraftPage operation="renew" />} />

            <Route path="overdraft/higher-limit-application" element={<HigherLimitApplication />} />
            <Route
                path="overdraft/higher-limit-application/action-required"
                element={<HigherLimitActionRequired />}
            />
            <Route
                path="overdraft/higher-limit-action-required/success"
                element={<HigherLimitActionRequiredSuccess />}
            />
            <Route
                path="overdraft/higher-limit-application/success"
                element={<ApplicationSuccessPage />}
            />
            <Route
                path="higher-limit-rejection-info"
                element={<HigherLimitApplicationRejectionInfo />}
            />
            <Route
                path="higher-limit-approval-info"
                element={<HigherLimitApplicationApprovalInfo />}
            />
        </Route>
    </Route>
)
