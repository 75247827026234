import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import type React from 'react'
import styled from 'styled-components'

import {
    Button,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalTitle,
    Stack,
    tokens,
} from '@pleo-io/telescope'

import {SupportedLanguage} from '@product-web/i18n'
import {EmployeeStatus} from '@product-web/shell/employee-status'
import {useToaster} from '@product-web/toaster'
import {useUserMutations} from '@product-web/user'

import ATIcon from './images/at.svg'
import BEIcon from './images/be.svg'
import DAIcon from './images/da.svg'
import DEIcon from './images/de.svg'
import ESIcon from './images/es.svg'
import FIIcon from './images/fi.svg'
import FRIcon from './images/fr.svg'
import NLIcon from './images/nl.svg'
import PTIcon from './images/pt.svg'
import SVIcon from './images/sv.svg'

interface Props {
    language: SupportedLanguage
    closeModal: () => void
}

interface ModalLanguageIconProps {
    language: SupportedLanguage
}
/* eslint-disable string-to-lingui/missing-lingui-transformation */
const HeaderText: Record<SupportedLanguage, string> = {
    [SupportedLanguage.ES]: '¿Hablas español?',
    [SupportedLanguage.DE]: 'Sprechen Sie Deutsch?',
    [SupportedLanguage.DE_AT]: 'Sprechen Sie Deutsch?',
    [SupportedLanguage.SV]: 'Pratar du svenska?',
    [SupportedLanguage.EN]: 'Do you speak English?',
    [SupportedLanguage.DA]: 'Taler du dansk?',
    [SupportedLanguage.FR]: 'Parlez-vous français?',
    [SupportedLanguage.FR_BE]: 'Parlez-vous français?',
    [SupportedLanguage.FI]: 'Puhutko suomea?',
    [SupportedLanguage.NL]: 'Spreek je Nederlands?',
    [SupportedLanguage.NL_BE]: 'Spreek je Nederlands?',
    [SupportedLanguage.PT]: 'Você fala português?',
    [SupportedLanguage.IT]: 'Parli italiano?',
    [SupportedLanguage.NO]: 'Kan du norsk?',
}
/* eslint-enable string-to-lingui/missing-lingui-transformation */

const getLanguageText = (language: SupportedLanguage) => {
    const languageText: Record<SupportedLanguage, string> = {
        [SupportedLanguage.ES]: t`Spanish`,
        [SupportedLanguage.DE]: t`German`,
        [SupportedLanguage.DE_AT]: t`German`,
        [SupportedLanguage.SV]: t`Swedish`,
        [SupportedLanguage.EN]: t`English`,
        [SupportedLanguage.DA]: t`Danish`,
        [SupportedLanguage.FR]: t`French`,
        [SupportedLanguage.FR_BE]: t`French`,
        [SupportedLanguage.FI]: t`Finnish`,
        [SupportedLanguage.NL]: t`Dutch`,
        [SupportedLanguage.NL_BE]: t`Dutch`,
        [SupportedLanguage.PT]: t`Portuguese`,
        [SupportedLanguage.IT]: t`Italian`,
        [SupportedLanguage.NO]: t`Norwegian`,
    }
    return languageText[language]
}

const ModalIcon = styled.img.attrs({
    width: '218px',
    height: '145px',
})``

const ModalLanguageIcon: FC<ModalLanguageIconProps> = ({language}) => {
    switch (language) {
        case SupportedLanguage.ES:
            return (
                <ModalIllustration>
                    <ModalIcon src={ESIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.DE:
            return (
                <ModalIllustration>
                    <ModalIcon src={DEIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.DE_AT:
            return (
                <ModalIllustration>
                    <ModalIcon src={ATIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.DA:
            return (
                <ModalIllustration>
                    <ModalIcon src={DAIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.FR:
            return (
                <ModalIllustration>
                    <ModalIcon src={FRIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.SV:
            return (
                <ModalIllustration>
                    <ModalIcon src={SVIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.FI:
            return (
                <ModalIllustration>
                    <ModalIcon src={FIIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.NL:
            return (
                <ModalIllustration>
                    <ModalIcon src={NLIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.NL_BE:
        case SupportedLanguage.FR_BE:
            return (
                <ModalIllustration>
                    <ModalIcon src={BEIcon} />
                </ModalIllustration>
            )
        case SupportedLanguage.PT:
            return (
                <ModalIllustration>
                    <ModalIcon src={PTIcon} />
                </ModalIllustration>
            )
        default:
            return null
    }
}

export const ModalLanguageDetection: React.FC<Props> = ({closeModal, language}) => {
    const languageText = getLanguageText(language)
    const {showToast} = useToaster()
    const {updateLanguage, setEmployeeStateKey} = useUserMutations()

    return (
        <Modal
            onDismiss={closeModal}
            aria-label={HeaderText[language]}
            dangerouslySetZIndexValue={tokens.zIndexModal}
        >
            <ModalClose onClick={closeModal} />
            <div data-testid="modal-language-detection">
                <ModalLanguageIcon language={language} />
                <ModalTitle>{HeaderText[language]}</ModalTitle>
                <ModalContent>
                    <Trans>
                        Money talks – and so does Pleo! Our service is now available in new
                        languages. Do you want to switch your account to {languageText}?
                    </Trans>
                </ModalContent>
                <ModalActions>
                    <Stack space={16} align="center">
                        <Button
                            variant="primary"
                            onClick={async () => {
                                try {
                                    await updateLanguage(language)
                                    await setEmployeeStateKey(
                                        EmployeeStatus.askedToSwitchLanguage,
                                        true,
                                    )
                                    closeModal()
                                } catch (e) {
                                    showToast(t`Failed to update language.`, {level: 'error'})
                                }
                            }}
                            data-testid="modal-language-detection-button-yes"
                        >
                            <Trans>Yes, switch to {languageText}</Trans>
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={async () => {
                                try {
                                    await updateLanguage(SupportedLanguage.EN)
                                    await setEmployeeStateKey(
                                        EmployeeStatus.askedToSwitchLanguage,
                                        true,
                                    )
                                    closeModal()
                                } catch (e) {
                                    showToast(t`Failed to update language.`, {level: 'error'})
                                }
                            }}
                            data-testid="modal-language-detection-button-no"
                        >
                            {/* eslint-disable string-to-lingui/missing-lingui-transformation */}
                            No, stick with English
                            {/* eslint-enable string-to-lingui/missing-lingui-transformation */}
                        </Button>
                    </Stack>
                </ModalActions>
                <ModalFooter>
                    <Trans>
                        You can adjust your language preferences at any time under "Edit Profile".
                    </Trans>
                </ModalFooter>
            </div>
        </Modal>
    )
}
