import * as Accordion from '@radix-ui/react-accordion'
import styled, {css} from 'styled-components'
import {space} from 'styled-system'

import {focusRing, tokens} from '@pleo-io/telescope'
import {ChevronDown} from '@pleo-io/telescope-icons'

import type {AccordionContentProps, AccordionItemProps} from './accordion'

const PADDING_X = tokens.spacing24

export const Item = styled(Accordion.Item)<AccordionItemProps>`
    width: 100%;
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
    border-radius: ${tokens.arc8};
    ${space}
`

const buttonReset = css`
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    line-height: inherit;
    background-color: transparent;
    border-width: 0;
`

export const Header = styled(Accordion.Header)`
    width: 100%;
`

export const Trigger = styled(Accordion.Trigger)`
    ${focusRing('inset')}
    ${buttonReset}
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    font-weight: ${tokens.fontWeightRegular};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${PADDING_X};
    cursor: pointer;
    border-radius: ${tokens.arc8};

    ${Header}[data-state='open'] & {
        border-radius: ${tokens.arc8} ${tokens.arc8} 0 0;
    }

    ${Header}[data-state='closed'] &:hover {
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
    }

    [data-disabled] & {
        color: ${tokens.colorContentInteractiveDisabled};
        background-color: transparent !important;
        cursor: auto;
    }
`

export const Content = styled(Accordion.Content)<AccordionContentProps>`
    color: ${tokens.colorContentStatic};
    padding: ${PADDING_X};
    padding-top: 0;
    ${space}
`

export const Chevron = styled(ChevronDown).attrs({size: 16})`
    ${Trigger}[data-state='open'] & {
        transform: rotate(180deg);
    }
`

export const Banner = styled.div`
    padding: ${tokens.spacing12} ${PADDING_X};
    color: ${tokens.colorContentStaticQuiet};
    font-size: ${tokens.fontSmall};
    background: ${tokens.colorBackgroundStaticLoud};
    border-top: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
`

export const Divider = styled.div`
    border-bottom: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
    margin-left: -${PADDING_X};
    margin-right: -${PADDING_X};
    margin-bottom: ${tokens.spacing24};
`
