import type {AxiosError} from 'axios'
import {useMemo} from 'react'
import useSWRInfinite from 'swr/infinite'

import {getDeimos} from '@product-web/api-deimos/helpers'
import {usePartnerUser} from '@product-web/user'

export interface PartnerProgramReferral {
    companyId: string
    companyName: string
    paidUserCount: number
    freeUserCount: number
    firstReferredDate: string
}

export interface PartnerProgramReferralsResponse {
    total: number
    results: PartnerProgramReferral[]
}

export function usePartnerCurrentReferrals(limit = 5) {
    const {partnerId} = usePartnerUser()

    const result = useSWRInfinite<PartnerProgramReferralsResponse, AxiosError>(
        (index, latestPageData) => {
            const previousIndex = index ? index - 1 : index
            const latestPageLength = latestPageData?.results.length || 0
            const requestedClients = previousIndex * limit + latestPageLength
            const hasMoreToLoad = !latestPageData || latestPageData.total > requestedClients
            const offset = index * limit
            return hasMoreToLoad
                ? `partner-portal/rpc/partners/${partnerId}/current-referrals?offset=${offset}&limit=${limit}`
                : null
        },
        getDeimos,
        {revalidateFirstPage: false},
    )

    const flatList = useMemo(
        () =>
            result.data?.reduce(
                (allReferrals, referralsBatch) => [...allReferrals, ...referralsBatch.results],
                [] as PartnerProgramReferral[],
            ),
        [result.data],
    )

    const total = result?.data?.[0].total

    return {...result, flatList, total}
}

export function usePartnerPastReferrals(limit = 5) {
    const {partnerId} = usePartnerUser()

    const result = useSWRInfinite<PartnerProgramReferralsResponse, AxiosError>(
        (index, latestPageData) => {
            const previousIndex = index ? index - 1 : index
            const latestPageLength = latestPageData?.results.length || 0
            const requestedClients = previousIndex * limit + latestPageLength
            const hasMoreToLoad = !latestPageData || latestPageData.total > requestedClients
            const offset = index * limit
            return hasMoreToLoad
                ? `partner-portal/rpc/partners/${partnerId}/past-referrals?offset=${offset}&limit=${limit}`
                : null
        },
        getDeimos,
        {revalidateFirstPage: false},
    )

    const flatList = useMemo(
        () =>
            result.data?.reduce(
                (allReferrals, referralsBatch) => [...allReferrals, ...referralsBatch.results],
                [] as PartnerProgramReferral[],
            ),
        [result.data],
    )

    const total = result?.data?.[0].total

    return {...result, flatList, total}
}
