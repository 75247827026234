import {t} from '@lingui/macro'
import React from 'react'
import useSWR from 'swr'

import type {
    Attendable,
    Attendee,
    Expense,
    GetExpenseEmployeeBankTransferResponse,
    Receipt,
    TagGroupAssociation,
} from '@pleo-io/deimos'
import {ExpenseStatus, PurchaseType} from '@pleo-io/deimos'
import type {MileageReportResponse} from '@pleo-io/miranda-ts-models'
import type {PerDiemReportResponse} from '@pleo-io/pandia-ts-models'

import {request} from '@product-web/api'
import config from '@product-web/config'
import {useToaster} from '@product-web/toaster'
import {getQueryParams} from '@product-web/web-platform/query-string'

import {updateEmployeeContact} from './attendables'
import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

type ExpenseDetailQueryParams = {
    includeExtension?: boolean
    includeReviewerActivity?: boolean
}

export type CreateExpenseLineBody = {
    lineValue?: number
    taxType?: string
    taxCodeId?: string
    accountId?: string
    accountNumber?: string
}

export type AddExpenseAttendeeBody = {
    attendableType: string
    attendableId: string
}

export type UpdateAttendeePayload = {
    firstName: string
    lastName: string
    companyName: string
}

export type LostReceiptBody = {
    purchase: string
    reason: string
}

type AddReceiptPayload = {
    file: File
    onProgress: (number: number) => void
}

export type ExpenseUpdatePayload = Partial<
    Omit<Expense, 'tagGroup' | 'performed'> & {
        tagGroup: TagGroupAssociation
        tagGroups: TagGroupAssociation[]
        notes: string
        performed: string | Date
    }
>

export type Mutations = ReturnType<typeof useExpense>['mutations']
export type ExpenseWithReceiptUploadMutations = ReturnType<
    typeof useExpenseWithReceiptUpload
>['mutations']

export async function push(id: string) {
    return request(`${baseUrl}/rest/v1/expenses/${id}/push`, {
        auth: 'user',
        method: 'POST',
    })
}

export async function addExpenseAttendee(
    expenseId: string,
    payload: AddExpenseAttendeeBody,
): Promise<{id: string}> {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/attendees`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

export async function removeExpenseAttendee(expenseId: string, id: string) {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/attendees/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export async function addExpenseLine(expenseId: string, payload?: Partial<CreateExpenseLineBody>) {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/line`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

export async function removeExpenseLine(expenseId: string, lineId: string) {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/line/${lineId}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export async function removeExpenseLines(expenseId: string) {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/line`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export async function updateExpense(
    id: string,
    payload: ExpenseUpdatePayload,
): Promise<{success: boolean}> {
    return request(`${baseUrl}/rest/v1/expenses/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}

// TODO: find out actual type of new receipt from deimos
export async function uploadExpenseReceipt(
    id: string,
    {file, onProgress}: AddReceiptPayload,
): Promise<Expense & {newReceipt: any}> {
    return request(`${baseUrl}/rest/v1/expenses/${id}/receipts`, {
        auth: 'user',
        method: 'POST',
        file,
        onProgress,
    })
}

// TODO: find out actual type of new receipt from deimos
export const reportLostExpenseReceipt = async (
    id: string,
    payload: LostReceiptBody,
): Promise<Expense & {newReceipt: any}> => {
    return request(`${baseUrl}/rest/v1/expenses/${id}/lost-receipt`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

export async function removeExpenseReceipt(id: string) {
    return request(`${baseUrl}/rest/v1/receipts/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export interface BatchExpenseRequestPayload {
    expenseIds: string[]
    status?: Exclude<ExpenseStatus, 'CREATED' | 'EXPORTED'>
    accountId?: string | null
    tagGroups?: Array<{
        groupId: string
        rowId: string | null
    }>
}

interface BatchExpenseResponsePayload {
    expenseIds: string[]
}

export async function updateExpenses(
    companyId: string,
    payload: BatchExpenseRequestPayload,
): Promise<BatchExpenseResponsePayload> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/expenses`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}

async function markAsPersonalExpense(expenseId: string): Promise<{success: boolean}> {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/mark-as-personal`, {
        auth: 'user',
        method: 'POST',
    })
}

async function undoMarkAsPersonalExpense(expenseId: string): Promise<{success: boolean}> {
    return request(`${baseUrl}/rest/v1/expenses/${expenseId}/undo-mark-as-personal`, {
        auth: 'user',
        method: 'POST',
    })
}

export function useEmployeeBankTransfers(id?: string) {
    return useSWR<GetExpenseEmployeeBankTransferResponse, Error>(
        id ? `/rest/v1/expenses/${id}/employee-bank-transfers?include[]=expense` : null,
        getDeimos,
    )
}

export type ExpenseNotes = PerDiemReportResponse | MileageReportResponse

export const getExpenseNotesUrl = (id?: string | null, purchaseType?: string | null) => {
    if (!id) {
        return null
    }
    switch (purchaseType) {
        case PurchaseType.MILEAGE:
            return `/rest/v2/mileage/expenses/${id}/report`
        case PurchaseType.PER_DIEM:
            return `/rest/v3/per-diem/expenses/${id}/report`
        default:
            return null
    }
}
export function useExpense(
    id?: string | null,
    queryParams: ExpenseDetailQueryParams = {includeReviewerActivity: true},
) {
    const query = getQueryParams(queryParams)
    const result = useSWR<Expense, Error>(id ? `/rest/v1/expenses/${id}${query}` : null, getDeimos)
    const notesUrl = getExpenseNotesUrl(id, result.data?.purchaseType)
    /**
     * This is a good candidate for bff.
     * Keeping the fetch here would make it easier during bff migration.
     */
    const expenseNotes = useSWR<ExpenseNotes, Error>(notesUrl, getDeimos)
    const expense = result.data

    async function markAsPersonal() {
        if (!id) {
            return
        }
        await markAsPersonalExpense(id)
        await result.mutate()
    }

    async function undoMarkAsPersonal() {
        if (!id) {
            return
        }
        await undoMarkAsPersonalExpense(id)
        await result.mutate()
    }

    async function removeLines() {
        if (!id) {
            return
        }
        await removeExpenseLines(id)
        result.mutate()
    }

    async function addLine(payload?: Partial<CreateExpenseLineBody>) {
        if (!id) {
            return
        }
        await addExpenseLine(id, payload)
        result.mutate()
    }

    async function removeLine(lineId: string) {
        if (!id) {
            return
        }
        await removeExpenseLine(id, lineId)
        result.mutate()
    }

    async function updateExpenseLine(lineId: string, payload: ExpenseUpdatePayload) {
        await updateExpense(lineId, payload)
        result.mutate()
    }

    async function addAttendee(attendable: Attendable) {
        if (!expense) {
            return null
        }
        const response = await addExpenseAttendee(expense.id, {
            attendableId: attendable.id,
            attendableType: attendable.type,
        })
        if (!response) {
            return null
        }
        const attendee: Attendee = {
            ...attendable,
            expenseId: expense.id,
            id: response.id,
            attendableId: attendable.id,
            attendableType: attendable.type,
            avatar: attendable.avatar ?? null,
        }
        result.mutate(
            {
                ...expense,
                attendees: [...(expense.attendees ?? []), attendee],
            },
            false,
        )
        return response
    }

    async function removeAttendee(attendeeId: string) {
        if (!id) {
            return
        }
        await removeExpenseAttendee(id, attendeeId)
        result.mutate()
    }

    async function updateAttendee(attendeeId: string, payload: UpdateAttendeePayload) {
        if (!expense) {
            return
        }

        await updateEmployeeContact(attendeeId, payload)
        result.mutate()
    }

    async function reportLostReceipt(payload: LostReceiptBody) {
        if (!id) {
            return
        }

        await reportLostExpenseReceipt(id, payload)
        result.mutate()
    }

    async function removeReceipt(receiptId: string) {
        if (!expense) {
            return
        }

        await removeExpenseReceipt(receiptId)
        await result.mutate(
            {...expense, receipts: expense.receipts.filter((receipt) => receipt.id !== receiptId)},
            false,
        )
    }

    return {
        ...result,
        isValidating: result.isValidating,
        notes: {
            ...expenseNotes,
            isResolved: !notesUrl || !!expenseNotes.data || !!expenseNotes.error, // notes are only available for mileage and per-diem. This check here helps in the loading of the details page
        },
        mutations: {
            markAsPersonal,
            undoMarkAsPersonal,
            addLine,
            removeLine,
            removeLines,
            removeReceipt,
            reportLostReceipt,
            updateExpenseLine,
            addAttendee,
            removeAttendee,
            updateAttendee,
        },
    }
}

/**
 * This hook is for the receipt upload component. It allows for uploading the receipt with a progress state
 * @param id the expense expense id
 * @param onSuccess a callback to be called after the upload is successful. This is useful for the v2 expense
 * @returns object that is an extended swr return interface
 * @example expense = useExpenseWithReceiptUpload(expenseId) // used in place of `useExpense`
 */
export function useExpenseWithReceiptUpload(
    id: string | null,
    onSuccess?: (receipts: Receipt[]) => void,
) {
    const {showToast} = useToaster()
    const {
        data: expense,
        mutate,
        mutations,
        ...otherProps
    } = useExpense(id, {
        includeReviewerActivity: true,
    })
    const [receipt, setReceipt] = React.useState<Receipt | null>(null)
    const [uploadProgress, setUploadProgress] = React.useState<number | null>(null)

    async function uploadReceipt(file: File) {
        if (!id) {
            return
        }

        try {
            setReceipt(null)
            setUploadProgress(0) // Set loading to true immediately
            const response = await uploadExpenseReceipt(id, {file, onProgress: setUploadProgress})
            const {newReceipt, ...updatedExpense} = response
            setReceipt(newReceipt)
            await mutate({...expense, ...updatedExpense}, false)
            onSuccess?.(updatedExpense.receipts)
        } catch (e) {
            setUploadProgress(null)
            showToast(t`Upload failed, please try again`, {level: 'error'})
        }
    }

    return {
        ...otherProps,
        data: {
            receipt,
            expense,
            progress: uploadProgress,
            progressPercent: uploadProgress || 0,
            isUploading: uploadProgress !== null && uploadProgress !== 100,
            uploadCompleted: uploadProgress === 100,
        },
        mutations: {...mutations, uploadReceipt},
    }
}

export const getIsExpenseLocked = (
    status?: ExpenseStatus,
    hasSettledTransfer?: boolean,
): boolean => {
    return (
        (!!status && [ExpenseStatus.QUEUED, ExpenseStatus.EXPORTED].includes(status)) ||
        !!hasSettledTransfer
    )
}
