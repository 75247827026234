import {t, Trans} from '@lingui/macro'
import qs from 'qs'
import type React from 'react'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {useExperiment} from 'statsig-react'
import styled, {css} from 'styled-components'

import {Button, LoadingPage as TelescopeLoadingPage, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, Lock} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import config from '@product-web/config'
import {getHelpCentreArticleLink} from '@product-web/locale/helpers'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {breakpoints, pxBreakpoints} from '@product-web/styles/theme'
import {
    containerQuery,
    useContainerQuery,
} from '@product-web/telescope-lab/container-queries/container'
import {getFirstName, useCompanyUser} from '@product-web/user'
import {exhaustiveCheck} from '@product-web/utils'
import {createPersistedState} from '@product-web/web-platform/persisted-state'
import {useOpenBankingRedirectParams} from '@product-web-features/funds-management/use-open-banking-redirect-params'
import {TrialBannerFlagContainer} from '@product-web-features/onboarding/trial/banner.container'
import {Container, MainSectionWrapper, Page} from '@product-web-features/ui-page/page'

import {Error} from './error'
import {Loading} from './loading'

import {bff} from '../../bff-hooks'
import {BookkeepingMilestoneAccordionItem} from '../../components/bookkeeping-milestone-accordion-item'
import {DownloadAppModal} from '../../components/download-app-modal/download-app-modal'
import {FooterCard} from '../../components/footer-card'
import {InviteTeamMilestoneAccordionItem} from '../../components/invite-team-milestone-accordion-item'
import {MilestoneAccordion} from '../../components/milestone-accordion'
import {MilestoneCard} from '../../components/milestone-card'
import {ReimbursementsMilestoneAccordionItem} from '../../components/reimbursements-milestone-accordion-item'
import {SchedulePaymentsMilestoneAccordionItem} from '../../components/schedule-payments-milestone-accordion-item'
import {SpendingLimitsMilestoneAccordionItem} from '../../components/spending-limits-milestone-accordion-item'
import {TrialStartedModal} from '../../components/trial-started-modal/trial-started-modal'
import {TryCardsMilestoneAccordionItem} from '../../components/try-cards-milestone-accordion-item'
import illustrationExploreMilestoneGroup from '../../images/checklist.svg'
import illustrationTrialMilestoneGroup from '../../images/welcome.svg'
import {useDownloadMobileAppModal} from '../../lib/use-download-mobile-app-modal'

const MOBILE_BREAKPOINT = pxBreakpoints.tabletMedUp
const CONTAINER_QUERY_NAME = 'main'
export const GET_STARTED_STORAGE_KEY = 'get-started-page'

//#region Components

export const GetStartedScreen = () => {
    const {isLoading: isOpenBankingRedirectHandlerLoading} = useOpenBankingRedirectParams()
    const {data: footerData, isLoading: isFooterDataLoading} =
        bff.companyOnboarding.getStarted.getFooterData.useQuery()
    const user = useCompanyUser()
    const experiment = useExperiment('get_started_screen_variants_h1_2024')

    if (experiment.isLoading || isOpenBankingRedirectHandlerLoading) {
        return <TelescopeLoadingPage />
    }

    const experimentVariant: string = experiment.config.get('variant', 'control')
    if (experimentVariant !== 'accordions') {
        return <PageNotFound />
    }

    const openLinkInNewTab = (url: string) => {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        window.open(url, '_blank', 'noopener, noreferrer')
    }

    // Fall back to the DEMO_PLAYGROUND variant when there is no data available so the user sees a demo card
    const demoCardVariant = footerData?.demoVariant ?? 'DEMO_PLAYGROUND'

    const demoCardDestinationUrl = getFooterCardDestinationUrl({
        cardVariant: demoCardVariant,
        email: user.email,
        firstName: user.employee?.firstName,
        lastName: user.employee?.lastName,
    })
    const helpCentreCardDestinationUrl = getFooterCardDestinationUrl({
        cardVariant: 'HELP_CENTRE',
    })

    return (
        <GridContainer>
            <Helmet>
                <title>{t`Get Started`}</title>
            </Helmet>

            <div>
                <TrialBannerFlagContainer hidePreTrialBanners />

                <Page layout="wide" showNav>
                    <MaxWidthWrapper>
                        <Page.Header>
                            <Page.Title>
                                <Trans>Get Started</Trans>
                            </Page.Title>
                        </Page.Header>

                        <GetStartedScreenPageContent />
                    </MaxWidthWrapper>
                </Page>
            </div>

            <FooterSection>
                <MaxWidthWrapper>
                    <InnerFooter>
                        <FooterCard
                            variant="HELP_CENTRE"
                            onClick={() => openLinkInNewTab(helpCentreCardDestinationUrl)}
                            headingLevel="h3"
                            isLoading={false}
                        />

                        <FooterCard
                            variant={demoCardVariant}
                            onClick={() => openLinkInNewTab(demoCardDestinationUrl)}
                            headingLevel="h3"
                            isLoading={isFooterDataLoading}
                        />
                    </InnerFooter>
                </MaxWidthWrapper>
            </FooterSection>
        </GridContainer>
    )
}

const [usePersistedState] = createPersistedState(GET_STARTED_STORAGE_KEY, localStorage)

const GetStartedScreenPageContent = () => {
    const {isDownloadAppModalOpen, onCloseDownloadAppModal} = useDownloadMobileAppModal()
    const [trialStartedModalOpen, setTrialStartedModalOpen] = useState(false)
    const user = useCompanyUser()
    const firstName = getFirstName(user)
    const {
        data,
        error: dataError,
        refetch: refreshData,
    } = bff.companyOnboarding.getStarted.getData.useQuery(undefined, {
        // We need to always refetch on mount to get the latest data. It isn't always refreshed automatically
        // because we aren't using BFF mutations everywhere yet
        refetchOnMount: 'always',
    })
    const isBigScreen = useContainerQuery({
        name: CONTAINER_QUERY_NAME,
        minWidth: MOBILE_BREAKPOINT,
    })

    const [buttonClicked, setButtonClicked] = usePersistedState('continue-button-clicked', false)

    if (dataError) {
        return <Error />
    }

    if (!data) {
        return <Loading />
    }

    const {
        milestoneGroups,
        bookkeeperEmails,
        reserveData,
        companyVerificationSubmittedDate,
        trialDaysRemaining,
        hasDynamicMilestones,
        isFromMultiEntitySignupFlow,
        hasMultiEntityCompanyBilling,
    } = data

    const firstIncompleteGroup = milestoneGroups.find((group) => group.status !== 'COMPLETE')
    const lastGroup = milestoneGroups.slice(-1)[0]
    const activeGroup = buttonClicked ? firstIncompleteGroup ?? lastGroup : milestoneGroups[0]

    const {illustration, heading, description} = getActiveGroupInfo({
        groupName: activeGroup.name,
        firstName,
        companyName: user.company.name,
        isFromMultiEntitySignupFlow: !!isFromMultiEntitySignupFlow,
    })

    const sortedMilestones = activeGroup.milestones
        // Sort completed milestones at the bottom
        .sort((a, b) => {
            if (a.status === 'COMPLETE' && b.status !== 'COMPLETE') {
                return 1
            }

            if (a.status !== 'COMPLETE' && b.status === 'COMPLETE') {
                return -1
            }

            return 0
        })

    const firstNotCompleteMilestone = sortedMilestones.find(
        (milestone) => milestone.status !== 'COMPLETE',
    )

    return (
        <PageContent>
            {hasDynamicMilestones ? null : (
                <DownloadAppModal
                    isOpen={isDownloadAppModalOpen}
                    closeModal={onCloseDownloadAppModal}
                />
            )}

            {!!trialDaysRemaining && trialDaysRemaining > 0 && (
                <TrialStartedModal
                    isOpen={trialStartedModalOpen}
                    closeModal={() => setTrialStartedModalOpen(false)}
                    trialDaysRemaining={trialDaysRemaining}
                />
            )}

            <IllustrationSection>
                {isBigScreen && <Illustration src={illustration} alt="" />}

                <Stack space={4}>
                    <Text as="h2" variant="2xlarge-accent" weight="medium">
                        {heading}
                    </Text>

                    <Text as="p">{description}</Text>
                </Stack>
            </IllustrationSection>

            {activeGroup.name === 'TRIAL' && (
                <Stack space={24}>
                    <Stack space={16} as="section" stretch>
                        {activeGroup.milestones.map((milestone) => {
                            const companyVerificationMilestone = activeGroup.milestones.find(
                                (m) => m.name === 'GET_VERIFIED',
                            )
                            const companyVerificationMilestoneStatus =
                                companyVerificationMilestone?.status
                            const isCompanyVerificationSubmitted =
                                companyVerificationMilestoneStatus === 'AWAITING_REVIEW' ||
                                companyVerificationMilestoneStatus === 'COMPLETE'

                            return (
                                <MilestoneCard
                                    key={milestone.name}
                                    headingLevel="h4"
                                    milestone={milestone.name}
                                    status={milestone.status}
                                    shouldShowReserve={
                                        reserveData.isInReserveOnboardingTest &&
                                        isCompanyVerificationSubmitted
                                    }
                                    reserveLimit={reserveData.reserveLimit}
                                    companyVerificationSubmittedDate={
                                        companyVerificationSubmittedDate
                                    }
                                    isFromMultiEntitySignupFlow={!!isFromMultiEntitySignupFlow}
                                    hasMultiEntityCompanyBilling={!!hasMultiEntityCompanyBilling}
                                />
                            )
                        })}
                    </Stack>

                    <TrialContinueButton
                        variant="primary"
                        disabled={activeGroup.status !== 'COMPLETE'}
                        onClick={() => {
                            tracking.companyOnboardingNextStepsButtonActioned()
                            if (trialDaysRemaining && trialDaysRemaining > 0) {
                                setTrialStartedModalOpen(true)
                            } else {
                                // This means the end trial date is either:
                                // null -> not set (no subscription)
                                // 0 -> end date is in the past
                                reportError(
                                    `Trial end date not set or in the past. trialDaysRemaining value: ${trialDaysRemaining}`,
                                )
                            }
                            setButtonClicked(true)
                        }}
                    >
                        <Trans>Next steps</Trans>
                    </TrialContinueButton>
                </Stack>
            )}

            {activeGroup.name === 'EXPLORE' && (
                <MilestoneAccordion type="single" defaultValue={firstNotCompleteMilestone?.name}>
                    {sortedMilestones.map((milestone, i) => {
                        const key = milestone.name + i

                        switch (milestone.name) {
                            case 'CONNECT_ACCOUNTING':
                                return (
                                    <BookkeepingMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        bookkeeperEmails={bookkeeperEmails}
                                        refreshData={async () => {
                                            await refreshData()
                                        }}
                                        isOnTrial={data.isOnTrial}
                                    />
                                )
                            case 'INVITE_TEAM':
                                return (
                                    <InviteTeamMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            case 'SET_CONTROLS':
                                return (
                                    <SpendingLimitsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        isOnTrial={data.isOnTrial}
                                        requiresUpgrade={milestone.requiresUpgrade}
                                    />
                                )
                            case 'REIMBURSE_EXPENSES':
                                return (
                                    <ReimbursementsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        isOnTrial={data.isOnTrial}
                                        requiresUpgrade={milestone.requiresUpgrade}
                                    />
                                )
                            case 'TRY_CARDS':
                                return (
                                    <TryCardsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            case 'SCHEDULE_PAYMENTS':
                                return (
                                    <SchedulePaymentsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            default:
                                return null
                        }
                    })}
                </MilestoneAccordion>
            )}
        </PageContent>
    )
}

//#endregion Components

//#region Helpers

type MilestoneGroupName = 'TRIAL' | 'EXPLORE'

type GetActiveGroupInfoArgs = {
    groupName: MilestoneGroupName
    firstName: string
    companyName: string
    isFromMultiEntitySignupFlow: boolean
}

const getActiveGroupInfo = ({
    groupName,
    firstName,
    companyName,
    isFromMultiEntitySignupFlow,
}: GetActiveGroupInfoArgs) => {
    let heading = ''
    let description = ''
    switch (groupName) {
        case 'TRIAL':
            if (isFromMultiEntitySignupFlow) {
                heading = firstName ? t`Hello again, ${firstName}` : t`Hello again`
                description = t`Let's set up a Pleo account for ${companyName}.`
            } else {
                heading = firstName ? t`Welcome to Pleo, ${firstName}` : t`Welcome to Pleo`
                description = t`Activate your account to try Pleo for free.`
            }
            return {
                illustration: illustrationTrialMilestoneGroup,
                heading,
                description,
            }
        case 'EXPLORE':
            return {
                illustration: illustrationExploreMilestoneGroup,
                heading: t`You're in! What's next?`,
                description: t`Here's what we recommend based on your interests.`,
            }
        default:
            return exhaustiveCheck(groupName)
    }
}

type GetFooterCardDestinationUrlArgs = {
    cardVariant: React.ComponentProps<typeof FooterCard>['variant']
    email?: string
    firstName?: string
    lastName?: string
}

export const getFooterCardDestinationUrl = ({
    cardVariant,
    email = '',
    firstName = '',
    lastName = '',
}: GetFooterCardDestinationUrlArgs) => {
    switch (cardVariant) {
        case 'HELP_CENTRE':
            return getHelpCentreArticleLink()
        case 'DEMO_PLAYGROUND':
            return `${config.endpoints.commercial}/explore-product`
        case 'DEMO_SALES': {
            // Hubspot booking tool accepts email, firstName and lastName, and prop names must be exactly these
            const params = qs.stringify({email, firstName, lastName}, {addQueryPrefix: true})

            return config.endpoints.onboardingAppSalesDemoBookingUrl + params
        }
        default:
            return exhaustiveCheck(cardVariant)
    }
}

//#endregion Helpers

//#region Styles

const GridContainer = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: 100vh;
    overflow: auto;

    * {
        box-sizing: border-box;
    }

    ${Container}, ${MainSectionWrapper} {
        height: auto;
    }

    /* Using media queries instead of container queries to match padding and behavior of the telescope-lab page */
    @media (width >= ${breakpoints.desktopUp}) {
        /* Not sure why, but on bigger sizes, the generic-ui page doesn't have a bottom spacing */
        row-gap: ${tokens.spacing56};
    }
`

const MaxWidthWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
`

const PageContent = styled.div`
    display: grid;
    row-gap: ${tokens.spacing24};
    column-gap: ${tokens.spacing56};
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: minmax(10px, 1fr) 2fr;
            grid-template-rows: 1fr;
            gap: ${tokens.spacing56};
        `,
    )}
`

const IllustrationSection = styled.section`
    max-width: 360px;
`

const Illustration = styled.img`
    display: block;
    width: 100%;
    max-width: 320px;
`

// Using media queries instead of container queries to match padding and behavior of the telescope-lab page
const FooterSection = styled.section`
    display: flex;
    justify-content: center;
    padding: ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStaticLouder};

    @media (width >= ${breakpoints.smallTabletUp}) {
        padding: ${tokens.spacing40};
    }

    @media (width >= ${breakpoints.desktopUp}) {
        padding: ${tokens.spacing56};
    }
`

// Using media queries instead of container queries to match padding and behavior of the telescope-lab page
const InnerFooter = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: ${tokens.spacing24};
    row-gap: ${tokens.spacing24};
    width: 100%;

    @media (width >= ${breakpoints.smallTabletUp}) {
        column-gap: ${tokens.spacing40};
    }

    @media (width >= ${breakpoints.desktopUp}) {
        column-gap: ${tokens.spacing56};
    }

    > * {
        flex: 1;
    }
`

const TrialContinueButton = styled(Button).attrs(({disabled}) => ({
    IconRight: disabled ? Lock : ArrowRight,
}))``

//#endregion Styles
