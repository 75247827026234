import {Trans} from '@lingui/macro'
import React from 'react'

import {Button, Text, theme} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import type {DispatchActions, Step} from '@product-web-features/ui-guides/guide'

import {RequestEntitiesForm, RequestFailedMessage, SendButton} from './request-entities-form'

import AddEntityIllustration from '../../../images/add-entity-monopoly.svg'

export const useFormStep = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step => {
    const [requestFailed, setRequestFailed] = React.useState(false)
    const [missingSelection, setMissingSelection] = React.useState(false)

    return {
        key: 'request-to-add-entities-form',
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Request to add entities</Trans>
            </Text>
        ),
        color: theme.colors.pink300,
        sections: [
            {
                key: 'request-entities-form',
                content: () => (
                    <RequestEntitiesForm
                        setRequestFailed={setRequestFailed}
                        setMissingSelection={setMissingSelection}
                    />
                ),
            },
            {
                key: 'request-entities-form-validation',
                content: (
                    <RequestFailedMessage
                        requestFailed={requestFailed}
                        missingSelection={missingSelection}
                    />
                ),
            },
        ],
        illustration: <img src={AddEntityIllustration} alt="" />,
        actions: [
            {
                key: 'cancel',
                action: isOrganizationAdmin
                    ? ({onCompleted}: DispatchActions) => (
                          <Button variant="secondary" onClick={onCompleted}>
                              <Trans>Cancel</Trans>
                          </Button>
                      )
                    : ({onPreviousStep}: DispatchActions) => (
                          <Button variant="tertiary" IconLeft={ArrowLeft} onClick={onPreviousStep}>
                              <Trans>Back</Trans>
                          </Button>
                      ),
            },
            {
                key: 'send',
                action: ({onNextStep}: DispatchActions) => (
                    <SendButton
                        isOrganizationAdmin={isOrganizationAdmin}
                        onNextStep={onNextStep}
                        setRequestFailed={setRequestFailed}
                        setMissingSelection={setMissingSelection}
                    />
                ),
            },
        ],
    }
}
