import styled from 'styled-components'

import {Loading as TelescopeLoading, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'

export const Loading = () => (
    <LoadingContainer>
        <TelescopeLoading />
    </LoadingContainer>
)

const LoadingContainer = styled.div`
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStaticLoud};
    border-radius: ${tokens.arc8};
    display: grid;
    align-items: center;
    justify-items: center;
    height: 300px;
    padding: ${tokens.spacing60} ${tokens.spacing24};
    box-shadow: ${tokens.shadowElevateQuiet};

    @media (width >= ${breakpoints.mediumTabletUp}) {
        height: 400px;
        padding: ${tokens.spacing60};
    }

    @media (width >= ${breakpoints.desktopUp}) {
        height: 500px;
    }
`
