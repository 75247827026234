/* eslint-disable string-to-lingui/text-restrictions */
import {plural, t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Button, Modal, Text} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/analytics'
import {reportError} from '@product-web/error/report'

import HighFive from './high-five.svg'

interface TrialStartedModalProps {
    closeModal: () => void
    isOpen: boolean
    trialDaysRemaining: number
}

export const TrialStartedModal = ({
    isOpen,
    closeModal,
    trialDaysRemaining,
}: TrialStartedModalProps) => {
    const onClose = () => {
        tracking.companyOnboardingNextStepsModalActioned({cta: 'close-button'})
        closeModal()
    }

    useEffect(() => {
        if (isOpen) {
            if (trialDaysRemaining > 0) {
                tracking.companyOnboardingNextStepsModalViewed()
            } else {
                reportError(`Invalid trialDaysRemaining value: ${trialDaysRemaining}`)
            }
        }
    }, [isOpen, trialDaysRemaining])

    if (trialDaysRemaining <= 0) {
        return null
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onClose} aria-label={t`Your Pleo account is live!`}>
            <Modal.Close onClick={onClose} />
            <Modal.Illustration>
                <Illustration src={HighFive} alt="" />
            </Modal.Illustration>
            <Modal.Title>
                <Trans>Your Pleo account is live!</Trans>
            </Modal.Title>
            <Modal.Content>
                <Text>
                    <Trans>
                        Get a head start on expenses with free access to Pleo Essential for the next{' '}
                        {trialDaysRemaining && trialDaysRemaining > 0
                            ? plural(trialDaysRemaining, {
                                  one: '# day',
                                  other: '# days',
                              })
                            : undefined}
                        .
                    </Trans>
                </Text>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    variant="primary"
                    IconRight={ArrowRight}
                    onClick={() => {
                        tracking.companyOnboardingNextStepsModalActioned({
                            cta: 'explore-pleo-button',
                        })
                        closeModal()
                    }}
                >
                    <Trans>Explore Pleo</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const Illustration = styled.img`
    width: 100%;
    max-width: 320px;
`
