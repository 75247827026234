import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'

import {Box, Inline, Stack, Text} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {Page} from '@product-web-features/ui-page/page'

import {IntroductionToPleo} from './introduction-to-pleo'
import {OtherResources} from './other-resources'
import {ThePleoProduct} from './the-pleo-product'

export const AcademyIndex = () => {
    const {partnerAcademy} = useFlags()

    if (!partnerAcademy) {
        return <PageNotFound />
    }

    return (
        <>
            <Helmet>
                <title>{t`Partner Academy`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Partner Academy</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Stack mt={24}>
                    <Text variant="2xlarge-accent" space={4}>
                        <Trans>Available courses</Trans>
                    </Text>
                    <Text color="colorContentStaticQuiet">
                        <Trans>Get up to speed quickly with our Partner Academy courses.</Trans>
                    </Text>
                </Stack>
            </Page.Header>
            <Inline mt={24} space={24} stretchChildren>
                <IntroductionToPleo />
                <ThePleoProduct />
                <div />
            </Inline>
            <OtherResources />
            <Box marginY={32} />
        </>
    )
}
