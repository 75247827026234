import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const PocketPage = React.lazy(async () => import('./screens/pocket'))

export const routes = (
    <Route
        index
        element={<PocketPage />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
    />
)
