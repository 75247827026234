import {t} from '@lingui/macro'

const LARGE_THRESHOLD_DAYS = 5
const SMALL_THRESHOLD_DAYS = 1

const getIsLastDay = ({trialEndDate}: {trialEndDate?: Date}) => {
    const futureDateSuperClose = new Date()
    futureDateSuperClose.setDate(futureDateSuperClose.getDate() + SMALL_THRESHOLD_DAYS)

    const isLastDay =
        trialEndDate && trialEndDate < futureDateSuperClose && new Date() < trialEndDate

    return Boolean(isLastDay)
}

const getIsAlmostOver = ({trialEndDate}: {trialEndDate?: Date}) => {
    const futureDateClose = new Date()
    futureDateClose.setDate(futureDateClose.getDate() + LARGE_THRESHOLD_DAYS)

    const futureDateSuperClose = new Date()
    futureDateSuperClose.setDate(futureDateSuperClose.getDate() + SMALL_THRESHOLD_DAYS)
    // is within large threshold BUT IS NOT the last day
    const isAlmostOver =
        trialEndDate && trialEndDate < futureDateClose && trialEndDate > futureDateSuperClose

    return Boolean(isAlmostOver)
}

export const getPlanModalTitle = ({
    trialEndDate,
    isNewPlanSelectionModal,
}: {
    trialEndDate?: Date
    isNewPlanSelectionModal?: boolean
}) => {
    const isLastDay = getIsLastDay({trialEndDate})
    const isAlmostOver = getIsAlmostOver({trialEndDate})

    if (trialEndDate) {
        if (isLastDay) {
            return t`Your free trial has ended`
        } else if (isAlmostOver) {
            return t`Your free trial is almost over...`
        }
    }

    return isNewPlanSelectionModal
        ? t`You're seconds away from smarter spending`
        : t`Choose your plan`
}
