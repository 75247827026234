import {Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Link, Text, tokens} from '@pleo-io/telescope'
import {Warning} from '@pleo-io/telescope-icons'

const Wrapper = styled(Inline)`
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};
    border-radius: ${tokens.arc8};
    padding: 12px 24px;
    gap: 10px;
    color: ${tokens.colorContentStaticQuiet};
    max-width: 450px;
    margin: 0 auto;
`

const MemberLimitWarning = ({onClickSeeBilling}: {onClickSeeBilling: () => void}) => {
    return (
        <Wrapper justifyContent={'flex-start'} alignItems={'center'}>
            <Warning size={20} />
            <Text color="shade600" as="p">
                <Trans>
                    You're exceeding the 5 members limit on Free plan. We'll start the billing after
                    your trial ends.{' '}
                    <Link
                        as={RouterLink}
                        onClick={onClickSeeBilling}
                        to="/settings/general/billing"
                    >
                        See billing
                    </Link>
                </Trans>
            </Text>
        </Wrapper>
    )
}

export default MemberLimitWarning
