import {t, Trans} from '@lingui/macro'
import {useParams} from 'react-router'
import styled from 'styled-components'

import {Avatar, Box, Text, tokens} from '@pleo-io/telescope'
import {Bell, CategoryMealsAndDrinks, Info} from '@pleo-io/telescope-icons'

export const GuidelineEditor = () => {
    const {account} = useParams()
    // TODO: get account by ID from BFF hook specific for this screen

    return (
        <GuidelinesEditorContainer>
            <InnerContainer>
                <Avatar
                    size={72}
                    icon={<CategoryMealsAndDrinks aria-label={t`Category Meals And Drinks`} />}
                />
                <Text variant="4xlarge-accent">
                    <Trans>Client Dinners</Trans>
                </Text>
                <Text variant="large-accent" color="colorContentStaticQuiet">
                    <Trans>Draft</Trans>
                </Text>
                <ActionBar my={40}>
                    <Text>
                        <Trans>Guidelines {account}</Trans>
                    </Text>
                    <Box>
                        <Bell size={24} />
                        <Info size={24} ml={16} />
                    </Box>
                </ActionBar>
            </InnerContainer>
        </GuidelinesEditorContainer>
    )
}

const GuidelinesEditorContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
`

const InnerContainer = styled(Box)`
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${tokens.spacing8};
`

const ActionBar = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
