import {Modal} from '@pleo-io/telescope'

import ModalPurchaseTermsContent from '../manage-plan/modal-purchase-terms-content'

interface PurchaseTermsProps {
    isOpen: boolean
    onDismiss: () => void
}

export const PurchaseTermsModal = ({isOpen, onDismiss}: PurchaseTermsProps) => (
    <Modal isOpen={isOpen} aria-labelledby="purchase-terms-modal-title">
        <Modal.Close onClick={onDismiss} />
        <ModalPurchaseTermsContent onCloseHandle={onDismiss} />
    </Modal>
)
