import type {FC} from 'react'
import {useEffect, useState} from 'react'

import {PlanType} from '@product-web/api-types/plan'
import {PlanUpgradeSource} from '@product-web/api-types/plan'
import {EmployeeStatus} from '@product-web/shell/employee-status'
import {getEmployeeStatus, useUser, useUserMutations} from '@product-web/user'

import {bff} from './bff-hooks'
import {useCompanySubscription} from './lib/company-subscription'
import {PlanSelectionModal} from './plan-selections/plan-selection-modal'

const DAYS_BEFORE_END_OF_TRIAL = 5

/*
    Prevents calling subscription API unnecessarily if conditions are not met
*/
function useEndTrialModalRequirements() {
    const user = useUser()
    const hasSeenEndTrial = Boolean(getEmployeeStatus(user)[EmployeeStatus.hasSeenEndTrialModal])
    const userExists = Boolean(user)

    const {data: preSelectedPlatformPlan, isLoading} =
        bff.paywall.endTrial.getPreSelectedPricingPlanName.useQuery(undefined, {
            enabled: !hasSeenEndTrial && userExists && Boolean(user?.companyId),
        })

    const isFreemiumUser =
        preSelectedPlatformPlan === null ||
        preSelectedPlatformPlan === 'free' ||
        preSelectedPlatformPlan === 'starter'

    const shouldShowEndTrialModal = !isLoading && isFreemiumUser && userExists && !hasSeenEndTrial

    return {
        shouldShowEndTrialModal,
        isLoading,
    }
}

function useEndTrial() {
    const user = useUser()

    const {data: subscription} = useCompanySubscription()

    const [isEndTrialModalOpen, setIsEndTrialModalOpen] = useState(false)

    const {setEmployeeStateKey} = useUserMutations()

    const dismissEndTrialModal = () => {
        setEmployeeStateKey(EmployeeStatus.hasSeenEndTrialModal, true)
        setIsEndTrialModalOpen(false)
    }

    const currentPlanType = subscription?.platformRatePlan?.type
    const hasSubscription = subscription !== undefined
    const userExists = Boolean(user)
    const isTeamWithCommitment = Boolean(
        currentPlanType === PlanType.TEAM &&
            subscription?.addonRatePlans
                ?.find((addonRatePlan) => addonRatePlan.type === 'ADDITIONAL_USERS')
                ?.charges.some((charge) => charge.commitment && charge.commitment > 0),
    )
    const isFreeProOrTeam = Boolean(
        currentPlanType &&
            [PlanType.FREE, PlanType.PRO, PlanType.TEAM].some((p) => p === currentPlanType),
    )

    useEffect(() => {
        const getIsEligibleForDowngrade = () => (user?.company?.numberOfUsers || 0) <= 3

        const getIsTrialClose = () => {
            if (subscription?.isInTrialPeriod && subscription.trialPeriod?.exclusiveEndDate) {
                // In this case future date is DAYS_BEFORE_END_OF_TRIAL days in the future
                const futureDate =
                    Number(new Date()) + 1000 * 60 * 60 * 24 * DAYS_BEFORE_END_OF_TRIAL
                // Is the trial end date closer in the future than DAYS_BEFORE_END_OF_TRIAL days?
                const enoughTimePassedOnTrial =
                    Number(new Date(subscription.trialPeriod?.exclusiveEndDate)) < futureDate
                return enoughTimePassedOnTrial
            }
            // no sub, not on trial, or null trial object
            return false
        }

        if (
            getIsTrialClose() &&
            userExists &&
            hasSubscription &&
            isFreeProOrTeam &&
            !isTeamWithCommitment &&
            getIsEligibleForDowngrade()
        ) {
            setIsEndTrialModalOpen(true)
        }
    }, [
        hasSubscription,
        userExists,
        isFreeProOrTeam,
        isTeamWithCommitment,
        subscription?.isInTrialPeriod,
        subscription?.trialPeriod?.exclusiveEndDate,
    ])

    return {
        isEndTrialModalOpen,
        dismissEndTrialModal,
    }
}

const ChoosePlan = () => {
    const {isEndTrialModalOpen, dismissEndTrialModal} = useEndTrial()

    return (
        <PlanSelectionModal
            isOpen={isEndTrialModalOpen}
            onDismiss={dismissEndTrialModal}
            pageOrigin="end-trial"
            pageSection="end-trial-modal"
            planUpgradeSource={PlanUpgradeSource.END_TRIAL_MODAL}
        />
    )
}

const EndTrialModalRequirements: FC = () => {
    // Prevents subscription from being called unnecessarily
    const {shouldShowEndTrialModal} = useEndTrialModalRequirements()

    if (!shouldShowEndTrialModal) {
        return null
    }

    return <ChoosePlan />
}

export {EndTrialModalRequirements as EndTrialModal, useEndTrialModalRequirements}
