import {t} from '@lingui/macro'
import {useFormik} from 'formik'
import type {FormikContextType} from 'formik/dist/types'

import {useFlags} from '@product-web/flags'
import yup from '@product-web/validation/yup'

import type {FormPropsV1} from './lib/request-entities-modal-types'

export const useRequestEntitiesModalFormik = () => {
    const flags = useFlags()

    const formikV1 = useRequestEntitiesModalFormikV1()

    return flags.multiEntitySelfExpansion ? null : formikV1
}

const useRequestEntitiesModalFormikV1 = (): FormikContextType<FormPropsV1> => {
    return useFormik<FormPropsV1>({
        initialValues: {
            addNewEntities: false,
            countries: [],
            groupExistingEntities: false,
        },
        onSubmit: () => {},
        validationSchema: yup.object().shape({
            countries: yup.array().when('addNewEntities', {
                is: true,
                then: yup.array().min(1, t`Select countries where the companies are registered`),
            }),
            addNewEntities: yup
                .boolean()
                .test('oneOfRequired', t`Select at least one option above`, function (value) {
                    const {groupExistingEntities} = this.parent
                    return groupExistingEntities || value
                }),
            groupExistingEntities: yup
                .boolean()
                .test('oneOfRequired', t`Select at least one option above`, function (value) {
                    const {addNewEntities} = this.parent
                    return addNewEntities || value
                }),
        }),
        validateOnChange: false,
        validateOnBlur: false,
    })
}
