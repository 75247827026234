import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import styled from 'styled-components'

import {Link, Loading, Modal, tokens} from '@pleo-io/telescope'
import {HighFive} from '@pleo-io/telescope-icons'

import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import config from '@product-web/config'
import {reportError} from '@product-web/error/report'
import {breakpoints} from '@product-web/styles/theme'
import {useToaster} from '@product-web/toaster'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {PlanSelectionColumn} from './plan-selection-column'

import {bff} from '../bff-hooks'
import {getPlanModalTitle} from '../manage-plan/choose-plan-container.helper'
import {
    type AllowedPlanType,
    trackChoosePlanModalActioned,
} from '../plan-updates/plan-update.helper'
import type {PageOrigin, PageSection} from '../types'

interface PlanSelectionStepOneProps {
    pageOrigin: PageOrigin
    pageSection?: PageSection
    planUpgradeSource: PlanUpgradeSource
    onGetStarted: ({
        changeToPlan,
        existingPlan,
    }: {
        changeToPlan: AllowedPlanType
        existingPlan: AllowedPlanType
    }) => void
    onDismiss: () => void
}

export const PlanSelectionStepOne = ({
    pageOrigin,
    pageSection,
    planUpgradeSource,
    onGetStarted,
    onDismiss,
}: PlanSelectionStepOneProps) => {
    const {showToast} = useToaster()

    const {
        data: plansInfo,
        isLoading: isPlansInfoLoading,
        isError: isPlansInfoError,
    } = bff.paywall.planSelectionModal.getPlansInfo.useQuery(undefined, {
        onError(error) {
            reportError(error)
            showToast(t`An error occurred. Please try again later or contact support.`, {
                level: 'error',
            })
        },
    })

    const {
        mutate: updateCompanySubscriptionMutation,
        isLoading: isUpdateCompanySubscriptionLoading,
    } = bff.paywall.planSelectionModal.updateCompanySubscription.useMutation({
        onSuccess() {
            trackChoosePlanModalActioned({
                action: 'completed',
                step: 'pricing_plan',
                origin: pageOrigin,
                section: pageSection,
                plan: 'STARTER',
                previousPlan: plansInfo?.currentPlan as AllowedPlanType,
            })
            showToast(t`Plan changed successfully`, {level: 'success'})
            onDismiss()
        },
        onError(error) {
            reportError(error)
            showToast(t`An error occurred. Please try again later or contact support.`, {
                level: 'error',
            })
        },
    })

    const currentPlan = plansInfo?.currentPlan as AllowedPlanType

    const trialEndDate = plansInfo?.trialEndDate ? new Date(plansInfo?.trialEndDate) : undefined
    const title = getPlanModalTitle({trialEndDate, isNewPlanSelectionModal: true})

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp}`)

    const isCompanyVerified = plansInfo?.isCompanyVerified
    const isButtonDisabled = isPlansInfoError || !isCompanyVerified

    useEffect(() => {
        trackChoosePlanModalActioned({
            action: 'viewed',
            step: 'pricing_plan',
            origin: pageOrigin,
            section: pageSection,
        })
    }, [])

    return (
        <>
            <StyledModalTitle id="plan-selection-modal-title">
                {!isMobile && <HighFive size={32} />}
                {title}
            </StyledModalTitle>
            <StyledModalContent>
                {isPlansInfoLoading ? (
                    <Loading />
                ) : (
                    <StyledColumnWrapper>
                        <PlanSelectionColumn
                            planType="STARTER"
                            currentPlan={currentPlan}
                            planChangeBlocked={!!plansInfo?.planChangeBlocked}
                            planPrices={plansInfo?.plansPrices.STARTER!}
                            onGetStarted={() => {
                                trackChoosePlanModalActioned({
                                    action: 'get_started',
                                    step: 'pricing_plan',
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: 'STARTER',
                                })
                                updateCompanySubscriptionMutation({
                                    billingPeriodType: 'MONTH',
                                    ratePlanType: 'STARTER',
                                    subscriptionUpdateSource: planUpgradeSource,
                                })
                            }}
                            isButtonLoading={isUpdateCompanySubscriptionLoading}
                            isButtonDisabled={isButtonDisabled}
                            isCompanyVerified={isCompanyVerified}
                        />
                        <PlanSelectionColumn
                            planType="ESSENTIAL"
                            currentPlan={currentPlan}
                            planChangeBlocked={!!plansInfo?.planChangeBlocked}
                            planPrices={plansInfo?.plansPrices.ESSENTIAL!}
                            onGetStarted={() =>
                                onGetStarted({
                                    changeToPlan: 'ESSENTIAL',
                                    existingPlan: currentPlan,
                                })
                            }
                            isButtonDisabled={isButtonDisabled}
                            isCompanyVerified={isCompanyVerified}
                        />
                        <PlanSelectionColumn
                            planType="ADVANCED"
                            currentPlan={currentPlan}
                            planChangeBlocked={!!plansInfo?.planChangeBlocked}
                            planPrices={plansInfo?.plansPrices.ADVANCED!}
                            onGetStarted={() =>
                                onGetStarted({
                                    changeToPlan: 'ADVANCED',
                                    existingPlan: currentPlan,
                                })
                            }
                            isButtonDisabled={isButtonDisabled}
                            isCompanyVerified={isCompanyVerified}
                        />
                    </StyledColumnWrapper>
                )}
            </StyledModalContent>
            <StyledModalFooter>
                <Link
                    href={`${config.endpoints.commercial}/pricing`}
                    target="_blank"
                    onClick={() =>
                        trackChoosePlanModalActioned({
                            action: 'view_all_link',
                            step: 'pricing_plan',
                            origin: pageOrigin,
                            section: pageSection,
                        })
                    }
                >
                    <Trans>See full plan comparison</Trans>
                </Link>
            </StyledModalFooter>
        </>
    )
}

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: ${tokens.spacing10};
`

const StyledModalFooter = styled(Modal.Footer)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${tokens.colorBackgroundStaticLouder};
    margin-top: ${tokens.spacing28};
`

const StyledModalContent = styled(Modal.Content)`
    min-height: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: ${tokens.spacing16};
    row-gap: ${tokens.spacing24};
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: ${breakpoints.tabletUp}) {
        flex-direction: row;
        align-items: stretch;
    }
`
