import {useCallback} from 'react'

import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {isValuePresentInCommaSeparatedString} from '@product-web/utils'

type TagBasedReviewFlag = ReturnType<typeof useFlags>['tagBasedReview']

export type ReviewManagementMigrationFlag = ReturnType<typeof useFlags>['reviewManagementMigration']

export function useIsTagReviewFlagEnabled(flag: TagBasedReviewFlag & string) {
    const hasLoadedFeatureFlags = useFlagsLoaded()

    const {tagBasedReview: enabledMigrationFlags} = useFlags()

    const isFlagEnabled = useCallback(
        (targetFlag: TagBasedReviewFlag) =>
            isValuePresentInCommaSeparatedString<TagBasedReviewFlag>({
                value: targetFlag,
                commaSeparatedString: enabledMigrationFlags,
            }),
        [enabledMigrationFlags],
    )

    return {
        isLoading: !hasLoadedFeatureFlags,
        isEnabled: isFlagEnabled(flag),
    }
}

/**
 * This hook is used to check if a specific review management migration flag is enabled.
 *
 * @example
   ```typescript
   import {useReviewManagementMigrationFlag} from './review-feature-flags/review-feature-flags';

   // Use the hook & read `isEnabled` to determine whether or not it is enabled.
   const {isEnabled: isTeamMigrationFlagEnabled} = useReviewManagementMigrationFlag('TEAM_REVIEW');
 * ```
 */
export function useReviewManagementMigrationFlag(flag: ReviewManagementMigrationFlag & string) {
    const isLoadingFeatureFlags = useFlagsLoaded() === false

    const {reviewManagementMigration: enabledMigrationFlags} = useFlags()

    const flagEnabled = isValuePresentInCommaSeparatedString<ReviewManagementMigrationFlag>({
        value: flag,
        commaSeparatedString: enabledMigrationFlags,
    })

    if (isLoadingFeatureFlags) {
        return {
            isLoading: true,
            isEnabled: false,
        } as const
    }

    return {
        isLoading: false,
        isEnabled: flagEnabled,
    } as const
}
