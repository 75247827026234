import {t} from '@lingui/macro'
import type {FormikContextType} from 'formik'
import {Form, FormikProvider} from 'formik'
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react'
import styled from 'styled-components'

import {Inline, Modal as ModalBase, ModalClose, ModalContent, tokens} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'
import {useLoggedInAccounts} from '@product-web/auth--accounts'
import {useFlags} from '@product-web/flags'
import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {useGuide, variants} from '@product-web-features/ui-guides/guide'
import {Indicator} from '@product-web-features/ui-guides/indicator'

import {useRequestEntitiesModalFormik} from './request-entities-modal-formik'
import {useSteps} from './request-entities-modal-steps'

import {bff} from '../bff'

export const RequestEntitiesModal = ({
    isOrganizationAdmin,
    isOpen,
    setIsOpen,
}: {
    isOrganizationAdmin: boolean
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}) => {
    const flags = useFlags()
    const isNewFlow = !!flags.multiEntitySelfExpansion

    const steps = useSteps({isOrganizationAdmin})

    const formik = useRequestEntitiesModalFormik()
    const {switchAccount} = useLoggedInAccounts()
    const {data} = bff.featureMultiEntity.requestEntitiesModal.getModalInfo.useQuery(undefined, {
        enabled: isOpen && isNewFlow,
    })

    const onCloseGuide = () => {
        tracking.selfExpansionModalClosed({
            step: key,
            hasOrgAccess: isOrganizationAdmin,
        })
        onSetActiveStep(0)
        setIsOpen(false)
        formik?.resetForm()
    }

    const {active, onSetActiveStep, onNextStep, onPreviousStep, onCompleted, onStart} = useGuide({
        steps,
        onClose: onCloseGuide,
    })

    // Need to improve how to select starting step
    const activeStep = steps[active]

    const {key, title, sections, illustration, actions, illustrationBgColor} = activeStep
    const singleActionButton = actions && actions.length === 1

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp}`)

    let justifyActionsContent = 'space-between'
    if (singleActionButton) {
        if (isNewFlow) {
            justifyActionsContent = active === steps.length - 1 ? 'end' : 'start'
        } else {
            justifyActionsContent = 'space-around'
        }
    }

    useEffect(() => {
        if (!isOpen || !data) {
            return
        }

        if (data.companyId !== data.ownerCompanyId) {
            switchAccount({email: data.email, companyId: data.ownerCompanyId, skipRedirect: true})
        }
    }, [isOpen, data])

    return (
        <Modal
            onDismiss={onCloseGuide}
            isOpen={isOpen}
            aria-label={t`Request To Add Entities`}
            dangerouslySetZIndexValue={isNewFlow ? tokens.zIndexPopover : undefined}
        >
            <ModalClose onClick={onCloseGuide} />
            <ModalContent>
                <AnimatePresence initial={false} exitBeforeEnter>
                    <motion.div
                        key={key}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                    >
                        <FormikProviderWrapper value={formik}>
                            <SplitContent>
                                <LeftContent>
                                    {!isNewFlow && !isOrganizationAdmin && key !== 'success' && (
                                        <Indicator
                                            steps={steps.slice(0, 2)}
                                            active={active}
                                            onClickStepIndicator={onSetActiveStep}
                                        />
                                    )}
                                    {title}
                                    <ScreenSectionsWrapper>
                                        {sections?.length &&
                                            sections.map(({content, key: stepSectionKey}) => (
                                                <div key={`${key}-${stepSectionKey}`}>
                                                    {typeof content === 'function'
                                                        ? content({
                                                              onNextStep,
                                                              onPreviousStep,
                                                              onStart,
                                                              onCompleted,
                                                              onSetActiveStep,
                                                          })
                                                        : content}
                                                </div>
                                            ))}
                                    </ScreenSectionsWrapper>
                                </LeftContent>
                                <ActionButtonsContent>
                                    <Inline justifyContent={justifyActionsContent} alignY="center">
                                        {actions?.map(
                                            ({action, key: actionKey}: any, idx: number) => (
                                                <React.Fragment
                                                    key={`guide-step-action-${actionKey}-${idx}`}
                                                >
                                                    {typeof action === 'function' &&
                                                        action({
                                                            onNextStep,
                                                            onPreviousStep,
                                                            onStart,
                                                            onCompleted,
                                                            onSetActiveStep,
                                                        })}
                                                </React.Fragment>
                                            ),
                                        )}
                                    </Inline>
                                </ActionButtonsContent>
                                {!isMobile && (
                                    <RightContent>
                                        <IllustrationWrapper $bgColor={illustrationBgColor}>
                                            {illustration}
                                        </IllustrationWrapper>
                                    </RightContent>
                                )}
                            </SplitContent>
                        </FormikProviderWrapper>
                    </motion.div>
                </AnimatePresence>
            </ModalContent>
        </Modal>
    )
}

function FormikProviderWrapper<T>({
    value,
    children,
}: {
    value: FormikContextType<T> | null
    children: React.ReactNode
}) {
    if (!value) {
        return <>{children}</>
    }

    return (
        <FormikProvider value={value}>
            <Form>{children}</Form>
        </FormikProvider>
    )
}

const Modal = styled(ModalBase)`
    padding-top: 0;
    width: 900px;

    & button {
        z-index: ${tokens.zIndexSurface};
        cursor: pointer;
    }

    ${ModalContent} {
        margin-bottom: 0;
        padding: 0;
        border-radius: ${tokens.arc8};
    }

    @media (max-width: ${breakpoints.desktopUp}) {
        position: relative;
        width: 100%;
    }

    @media (max-width: ${breakpoints.tabletMedUp}) {
        height: fit-content;
        max-height: 1000px;
    }
`
const SplitContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 80px;
    height: 100%;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 400px 100px;
        height: fit-content;
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 60px;
    }
`
const LeftContent = styled.div`
    padding: ${tokens.spacing40} ${tokens.spacing32} 0 ${tokens.spacing32};
    grid-area: 1 / 1 / 1 / 2;
    text-align: left;

    h3 {
        padding-top: ${tokens.spacing20};
    }

    @media (max-width: ${breakpoints.tabletMedUp}) {
        grid-area: 1 / 1 / 2 / 2;
        padding: ${tokens.spacing40} ${tokens.spacing32};
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        grid-area: 1 / 1 / 2 / 2;
    }
`
const RightContent = styled.div`
    height: 100%;
    grid-area: 1 / 2 / 3 / 3;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        grid-area: 2 / 1 / 3 / 2;
        max-height: 400px;
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        display: none;
    }
`

const ActionButtonsContent = styled.div`
    grid-area: 2 / 1 / 3;
    margin: ${tokens.spacing20} ${tokens.spacing32};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        grid-area: 3 / 1 / 4 / 2;
        margin: auto ${tokens.spacing32};
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        grid-area: 2 / 1 / 3 / 2;
    }
`

const IllustrationWrapper = styled.div<{$bgColor?: string}>`
    background-color: ${({$bgColor}) => $bgColor ?? tokens.pink300};
    border-top-right-radius: ${tokens.arc8};
    border-bottom-right-radius: ${tokens.arc8};
    height: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        border-radius: 0;
        max-height: 400px;
        min-height: 400px;

        img {
            margin: 20px auto;
            max-height: 350px;
        }
    }
`
const ScreenSectionsWrapper = styled.div`
    padding-top: ${tokens.spacing24};
`
