import type {AccountCategory, Expense} from '@pleo-io/deimos'

export enum CategoryAccessType {
    FULL_ACCESS = 'FULL_ACCESS',
    LIMITED_ACCESS = 'LIMITED_ACCESS',
    NO_ACCESS = 'NO_ACCESS',
}

export const isAccountInCategory = (
    category: AccountCategory,
    selectedAccountId?: string | null,
) => {
    if (!selectedAccountId || !category?.accounts) {
        return false
    }

    return category.accounts.some((account) => account?.id === selectedAccountId)
}

export const getCategoryAccessLevel = (
    accountCategory: AccountCategory,
    isPrivilegedUser: boolean,
    expense?: Expense,
) => {
    if (isPrivilegedUser || accountCategory.selectable) {
        return CategoryAccessType.FULL_ACCESS
    }

    const isSelectedAccountInCategory = isAccountInCategory(accountCategory, expense?.accountId)
    if (isSelectedAccountInCategory) {
        return CategoryAccessType.LIMITED_ACCESS
    }

    return CategoryAccessType.NO_ACCESS
}
