import {t} from '@lingui/macro'

import {PlanTypeName} from '@product-web/api-types/billing'
import {PlanType} from '@product-web/api-types/plan'
import {useFlags} from '@product-web/flags'

export interface ConditionedPlan {
    type: PlanType
    titleText: string
}

export interface ConditionedPlans {
    starter: ConditionedPlan
    essential: ConditionedPlan
    advanced: ConditionedPlan
}

const planTitleText = (planType: PlanType) => {
    const planTypeName = PlanTypeName[planType]
    return t`${planTypeName} Plan`
}

export const getConditionedPlans = (isPricingPlan2 = false) =>
    isPricingPlan2
        ? {
              starter: {type: PlanType.STARTER, titleText: planTitleText(PlanType.STARTER)},
              essential: {type: PlanType.ESSENTIAL, titleText: planTitleText(PlanType.ESSENTIAL)},
              advanced: {type: PlanType.ADVANCED, titleText: planTitleText(PlanType.ADVANCED)},
          }
        : {
              starter: {type: PlanType.FREE, titleText: planTitleText(PlanType.FREE)},
              essential: {type: PlanType.TEAM, titleText: planTitleText(PlanType.TEAM)},
              advanced: {type: PlanType.BUSINESS, titleText: planTitleText(PlanType.BUSINESS)},
          }

export default function useConditionedPlans(): ConditionedPlans {
    const featureFlags = useFlags()

    return getConditionedPlans(featureFlags?.pricingPlan2)
}
