import type {StatsigEnvironment, StatsigUser} from 'statsig-react'
import {StatsigProvider} from 'statsig-react'

import {useIsLoggedIn, useIsLoggedOut} from '@product-web/auth--session/context'
import config from '@product-web/config'

import {bff} from './bff-hooks'

export const StatsigProviderWrapper: React.FC<{children: React.ReactNode}> = ({children}) => {
    const statsigUser = useStatsigUser()

    return (
        <StatsigProvider
            sdkKey={config.statsig.key}
            waitForInitialization={false}
            options={{
                environment: {
                    tier: getStatsigEnvironment(),
                },
                disableErrorLogging: true,
            }}
            user={statsigUser}
        >
            {children}
        </StatsigProvider>
    )
}

const useStatsigUser = (): StatsigUser => {
    const isLoggedIn = useIsLoggedIn()
    const isLoggedOut = useIsLoggedOut()

    // This will need to be updated iff we move forward with Statsig
    // Relying on LaunchDarkly to set this flag

    const isFlaggedForWebPasscodeReset =
        localStorage.getItem('webPasscodeReset') === 'true' ? true : false

    const isFlaggedForWebLoginRedesign =
        localStorage.getItem('webLoginRedesign') === 'true' ? true : false

    const {data, error} = bff.statsigUserInfo.useQuery(undefined, {
        enabled: isLoggedIn,
        refetchOnWindowFocus: false,
    })

    if (isLoggedIn && data) {
        return data
    }

    if (isLoggedOut || error || data === null) {
        return {
            userID: 'anonymous',
            custom: {
                webPasscodeReset: isFlaggedForWebPasscodeReset,
                webLoginRedesign: isFlaggedForWebLoginRedesign,
            },
        }
    }

    return {
        userID: 'anonymous',
    }
}

const getStatsigEnvironment = (): StatsigEnvironment['tier'] => {
    switch (config.environment) {
        case 'staging':
            return 'staging'
        case 'production':
            return 'production'
        default:
            return 'development'
    }
}
