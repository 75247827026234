import {useEmployeeSummary} from '@product-web/api-deimos/employee'
import {useCompanyEntitlements} from '@product-web/api-deimos/entitlement'
import type {SubscriptionRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {reportError} from '@product-web/error/report'
import {useFlags, useFlagsLoaded} from '@product-web/flags'
import {isOnNewPricingPlan} from '@product-web/paywall/helpers'
import {useCompanyUser} from '@product-web/user'

const STARTER_PLAN_TYPE: SubscriptionRatePlanType = 'STARTER'

export const useNewPricingPlan = (planType: string | undefined) => {
    const flagsLoaded = useFlagsLoaded()
    const flags = useFlags()
    return Boolean(flagsLoaded && flags.pricingPlan2 && isOnNewPricingPlan(planType))
}

export const useGetNewPricingPlan = (planType: string | undefined) => {
    if (isOnNewPricingPlan(planType)) {
        return planType
    }
    return null
}

export const isOnFreePlan = (planType: string | undefined) => {
    if (!planType) {
        return false
    }
    return planType === STARTER_PLAN_TYPE
}

export const useInviteMembersLimit = () => {
    const user = useCompanyUser()
    const {
        data: employeesCount,
        isValidating: isEmployeeSummaryValidating,
        error: employeeSummaryError,
    } = useEmployeeSummary()
    const {
        data: companyEntitlements,
        isValidating: isCompanyEntitlementsValidating,
        error: companyEntitlementsError,
    } = useCompanyEntitlements(user.companyId)

    const numberOfUsers = employeesCount ? employeesCount.member + employeesCount.owner : 0
    const maxAllowedEmployees = companyEntitlements?.limits?.maxAllowedEmployees
    const isLoading = isEmployeeSummaryValidating || isCompanyEntitlementsValidating
    let freeSeatsLeft

    if (employeeSummaryError) {
        reportError(employeeSummaryError)
        freeSeatsLeft = 0
    } else if (companyEntitlementsError) {
        reportError(companyEntitlementsError)
        freeSeatsLeft = 0
    } else if (isLoading) {
        freeSeatsLeft = 0
    } else if (maxAllowedEmployees) {
        freeSeatsLeft = Math.max(maxAllowedEmployees - numberOfUsers, 0)
    } else {
        freeSeatsLeft = Infinity
    }

    return {freeSeatsLeft, numberOfUsers, maxAllowedEmployees, isLoading}
}
