import qs from 'qs'
import useSWR from 'swr'

import type {BookkeeperPermission} from '@pleo-io/deimos'

import {request} from '@product-web/api'
import config from '@product-web/config'
import {useLoggedInUser} from '@product-web/user'

import {getDeimos, postDeimos} from './helpers'

const baseUrl = config.endpoints.api

export const useBookkeeperPermissions = (
    bookkeeperUserId?: string | null,
    companyId?: string | null | undefined,
) => {
    const {companyId: currentCompanyId} = useLoggedInUser()
    const queryParams = qs.stringify({companyId: companyId ?? currentCompanyId})
    const query = queryParams ? `?${queryParams}` : ''

    const result = useSWR<Array<BookkeeperPermission>, Error>(
        companyId && bookkeeperUserId
            ? `/rest/v1/bookkeeper/${bookkeeperUserId}/permissions${query}`
            : null,
        getDeimos,
    )

    const update = async (permission: BookkeeperPermission) => {
        if (!bookkeeperUserId || !companyId) {
            return
        }

        result.mutate((data = []) => [...data, permission], false)
        await updateBookkeeperPermission(bookkeeperUserId, permission, {companyId})
        result.mutate()
    }
    const destroy = async (permission: BookkeeperPermission) => {
        if (!bookkeeperUserId || !companyId) {
            return
        }

        result.mutate((data) => data?.filter((p) => p !== permission), false)
        await deleteBookkeeperPermission(bookkeeperUserId, permission, {companyId})
        result.mutate()
    }

    return {...result, mutations: {update, destroy}}
}

interface BookkeeperPermissionsRequestBody {
    companyId: string
}

async function updateBookkeeperPermission(
    bookkeeperUserId: string,
    permission: BookkeeperPermission,
    payload: BookkeeperPermissionsRequestBody,
) {
    return request(`${baseUrl}/rest/v1/bookkeeper/${bookkeeperUserId}/permission/${permission}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}

async function deleteBookkeeperPermission(
    bookkeeperUserId: string,
    permission: BookkeeperPermission,
    payload: BookkeeperPermissionsRequestBody,
) {
    return request(`${baseUrl}/rest/v1/bookkeeper/${bookkeeperUserId}/permission/${permission}`, {
        auth: 'user',
        method: 'DELETE',
        body: payload,
    })
}

export interface CreateBookkeeperPayload {
    email: string
    firstName?: string
    resourceName?: string
    resourceId: string
    metadata?: object
    type: 'bookkeeper-basic' | 'bookkeeper-extended'
    parentResource?: string
    parentResourceId?: string
}

interface CreateBookkeeperResponse {
    success: boolean
    message: string
}

interface AcceptBookkeeperInvitePayload {
    firstName?: string
    lastName?: string
}

interface AcceptBookkeeperInviteResponse {
    userId: string
    verifyToken: string
}

export async function create(body: CreateBookkeeperPayload): Promise<CreateBookkeeperResponse> {
    return postDeimos('/rest/v1/bookkeepers/invites', body)
}

export async function acceptInvite(
    token: string,
    body?: AcceptBookkeeperInvitePayload,
): Promise<AcceptBookkeeperInviteResponse> {
    return postDeimos(`/rest/v1/bookkeepers/invites/${token}/accept`, body)
}
