import type {ReactNode} from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

interface ElegantBannerProps {
    illustration: string
    content: ReactNode
}

export const ElegantBanner = ({illustration, content}: ElegantBannerProps) => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp}`)

    return (
        <ElegantBannerWrapper $isMobile={isMobile} data-testid="elegant-banner">
            {!isMobile && <img src={illustration} alt="" width="60px" />}
            <div>{content}</div>
        </ElegantBannerWrapper>
    )
}

const ElegantBannerWrapper = styled.div<{$isMobile?: boolean}>`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: ${tokens.spacing20};
    width: auto;
    border-radius: ${tokens.spacing8};
    background-color: ${tokens.colorBackgroundInfoQuiet};
    padding: ${({$isMobile}) =>
        $isMobile ? tokens.spacing8 : `${tokens.spacing20} ${tokens.spacing32}`};
`
