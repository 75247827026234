import {useFlags} from '@product-web/flags'

export type VerificationStatus = 'NEEDS_VERIFICATION' | 'VERIFICATION_IN_PROGRESS' | 'VERIFIED'

export const useVerificationStatus = (
    verificationStatus: VerificationStatus | undefined,
    role: 'bookkeeper' | 'owner' | 'member' | undefined,
) => {
    const flags = useFlags()

    const refinedVerificationStatus =
        flags.multiEntitySelfExpansion && role === 'owner' ? verificationStatus : 'VERIFIED'
    const isVerified = !refinedVerificationStatus || refinedVerificationStatus === 'VERIFIED'
    const showWalletBalance = isVerified || refinedVerificationStatus !== 'NEEDS_VERIFICATION'

    return {
        status: refinedVerificationStatus,
        isVerified,
        showWalletBalance,
    }
}
