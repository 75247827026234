import {useFlags} from '@product-web/flags'
import type {Step} from '@product-web-features/ui-guides/guide'

import {useFormStep} from './screens/form-step'
import {useIntroStep} from './screens/intro-step'
import {useSuccessStep} from './screens/success-step'
import {useAddOrGroupStep} from './screens/v2/add-or-group-step'
import {useGroupStep} from './screens/v2/group-step'

export const useSteps = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step[] => {
    const {multiEntitySelfExpansion} = useFlags()
    const stepsV1 = useStepsV1({isOrganizationAdmin})
    const stepsV2 = useStepsV2({isOrganizationAdmin})
    return multiEntitySelfExpansion ? stepsV2 : stepsV1
}

export const useStepsV1 = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step[] => {
    const formStep = useFormStep({isOrganizationAdmin})
    const successStep = useSuccessStep()
    const singleEntityAdminIntroStep = useIntroStep()

    const singleEntityAdminSteps = [singleEntityAdminIntroStep, formStep, successStep]
    const organizationAdminSteps = [formStep, successStep]

    return isOrganizationAdmin ? organizationAdminSteps : singleEntityAdminSteps
}

export const useStepsV2 = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step[] => {
    const successStep = useSuccessStep()
    const singleEntityAdminIntroStep = useIntroStep()
    const addOrGroupStep = useAddOrGroupStep({isOrganizationAdmin})
    const groupStep = useGroupStep({isOrganizationAdmin})

    const singleEntityAdminSteps = [
        singleEntityAdminIntroStep,
        addOrGroupStep,
        groupStep,
        successStep,
    ]
    const organizationAdminSteps = [addOrGroupStep, groupStep, successStep]

    return isOrganizationAdmin ? organizationAdminSteps : singleEntityAdminSteps
}
