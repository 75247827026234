import * as tracking from '@product-web/analytics'
import type {PageOrigin} from '@product-web/paywall/types'

export const trackPocketAdoptionPromptActioned = ({
    action,
}: {
    action: 'viewed' | 'not_now' | 'explore_pocket'
}) => tracking.pocketAdoptionPromptActioned({action})

export const trackInvoiceAdoptionPromptActioned = ({
    action,
}: {
    action: 'viewed' | 'not_now' | 'try_invoices'
}) => tracking.invoiceAdoptionPromptActioned({action})

export const trackUpgradeYourPlanViewed = ({page}: {page: PageOrigin}) =>
    tracking.upgradeYourPlanViewed({page})

export const trackLandingPagesEligibleViewed = ({page}: {page: PageOrigin}) => {
    tracking.landingPagesEligibleViewed({page})
}
