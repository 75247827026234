import {t, Trans} from '@lingui/macro'
import {useEffect, useState} from 'react'
import styled from 'styled-components'

import {
    Box,
    Button,
    Inline,
    Link,
    Loading,
    Modal,
    NakedButton,
    RadioButton,
    RadioGroup,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {CheckSmall} from '@pleo-io/telescope-icons'

import type {PlanUpgradeSource} from '@product-web/api-types/plan'
import {reportError} from '@product-web/error/report'
import type {MinorCurrency} from '@product-web/locale/currency'
import {formatMinorCurrency} from '@product-web/locale/currency'
import {breakpoints} from '@product-web/styles/theme'
import {useToaster} from '@product-web/toaster'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {ElegantBanner} from './elegant-banner'
import HandshakeIllustration from './handshake.svg'
import type {AllowedPlanType, BillingCommitment, BillingOptionConf} from './plan-update.helper'
import {
    formatMinorCurrencyNoDecimal,
    getBannerContentConf,
    getPlanBillingConf,
    SmallText,
    trackChoosePlanModalActioned,
} from './plan-update.helper'
import {PurchaseTermsModal} from './purchase-terms-modal'

import {bff} from '../bff-hooks'
import type {SubscriptionChangeRequestTyped} from '../lib/beyond.bff'
import type {PageOrigin, PageSection} from '../types'

interface PlanUpdateStepTwoProps {
    pageOrigin: PageOrigin
    pageSection?: PageSection
    planUpgradeSource: PlanUpgradeSource
    selectedPlan: AllowedPlanType
    currentPlan: AllowedPlanType
    onBack: () => void
    onDismiss: () => void
    fromPlanSelectionModal?: boolean
}

export const PlanUpdateStepTwo = ({
    pageOrigin,
    pageSection,
    planUpgradeSource,
    selectedPlan,
    currentPlan,
    onBack,
    onDismiss,
    fromPlanSelectionModal = false,
}: PlanUpdateStepTwoProps) => {
    const [isPurchaseTermsModalOpen, setIsPurchaseTermsModalOpen] = useState(false)
    const [billingCommitment, setBillingCommitment] = useState<BillingCommitment>('YEARLY')
    const updateBilling = (billing: BillingCommitment) => setBillingCommitment(billing)
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp}`)
    const {showToast} = useToaster()

    const {
        data: subscriptionPriceEstimations,
        isLoading: isSubscriptionPriceEstimationsLoading,
        error: subscriptionPriceEstimationsError,
    } = bff.paywall.planUpdateModal.getPlanPriceEstimations.useQuery(
        {
            ratePlanType: selectedPlan,
        },
        {
            enabled: !!selectedPlan,
            onError(error) {
                reportError(error)
                showToast(t`An error occurred. Please try again later or contact support.`, {
                    level: 'error',
                })
            },
        },
    )

    const {mutate: updatePlanMutation, isLoading: isUpdatePlanLoading} =
        bff.paywall.planUpdateModal.updateCompanyPlan.useMutation({
            onSuccess() {
                trackChoosePlanModalActioned({
                    action: 'completed',
                    step: 'payment_plan',
                    origin: pageOrigin,
                    section: pageSection,
                    plan: selectedPlan,
                    previousPlan: currentPlan,
                    commitment: billingCommitment === 'FLEXIBLE' ? 1 : 12,
                    interval: billingCommitment === 'YEARLY' ? 'YEAR' : 'MONTH',
                })
                showToast(t`Plan changed successfully`, {level: 'success'})
                onDismiss()
            },
            onError(error) {
                reportError(error)
                showToast(t`An error occurred. Please try again later or contact support.`, {
                    level: 'error',
                })
            },
        })

    const planCurrency = subscriptionPriceEstimations?.YEAR.totalWithoutTax.currency ?? ''
    const priceYearly = formatMinorCurrencyNoDecimal(
        subscriptionPriceEstimations?.YEAR.totalWithoutTaxDiscounted.value,
        planCurrency,
    )
    const priceMonthly = formatMinorCurrencyNoDecimal(
        subscriptionPriceEstimations?.MONTH.totalWithoutTax.value,
        planCurrency,
    )
    const priceDiscountedMonthly = formatMinorCurrencyNoDecimal(
        subscriptionPriceEstimations?.MONTH.totalWithoutTaxDiscounted.value,
        planCurrency,
    )

    const billingOptionsConf = getPlanBillingConf({
        priceYearlySum: priceYearly,
        priceMonthly,
        priceDiscountedMonthly,
    })

    const priceYearlyWithVat = formatMinorCurrency({
        value: subscriptionPriceEstimations?.YEAR.totalIncludingTaxDiscounted
            .value as MinorCurrency,
        currency: planCurrency,
    })
    const priceMonthlyWithVat = formatMinorCurrency({
        value: subscriptionPriceEstimations?.MONTH.totalIncludingTax.value as MinorCurrency,
        currency: planCurrency,
    })
    const todayPriceTextWithVat =
        billingCommitment === 'YEARLY' ? priceYearlyWithVat : priceMonthlyWithVat
    const bannerContentConf = getBannerContentConf({priceText: todayPriceTextWithVat})

    const updateCompanySubscription = async () => {
        const billingPeriodType = billingCommitment === 'YEARLY' ? 'YEAR' : 'MONTH'

        const updateCompanySubscriptionParams: SubscriptionChangeRequestTyped = {
            billingPeriodType,
            ratePlanType: selectedPlan,
            subscriptionUpdateSource: planUpgradeSource,
        }

        await updatePlanMutation(updateCompanySubscriptionParams)
    }

    useEffect(() => {
        trackChoosePlanModalActioned({
            action: 'viewed',
            step: 'payment_plan',
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlan,
        })
    }, [])

    return (
        <>
            <PurchaseTermsModal
                isOpen={isPurchaseTermsModalOpen}
                onDismiss={() => setIsPurchaseTermsModalOpen(false)}
            />
            <Modal.Title id="plan-update-modal-title">
                <Trans>Select a payment plan</Trans>
            </Modal.Title>
            <StyledModalContent $fromPlanSelectionModal={fromPlanSelectionModal}>
                {isSubscriptionPriceEstimationsLoading ? (
                    <Loading />
                ) : (
                    <StyledBox paddingX={isMobile ? 0 : 60}>
                        <RadioGroup value={billingCommitment} label="" onValueChange={() => {}}>
                            <Stack space={10}>
                                <BillingOptionCard
                                    billingCommitment="YEARLY"
                                    billingOptionConf={billingOptionsConf.YEARLY}
                                    isActive={billingCommitment === 'YEARLY'}
                                    updateBilling={updateBilling}
                                    isMobile={isMobile}
                                />
                                <BillingOptionCard
                                    billingCommitment="FLEXIBLE"
                                    billingOptionConf={billingOptionsConf.FLEXIBLE}
                                    isActive={billingCommitment === 'FLEXIBLE'}
                                    updateBilling={updateBilling}
                                    isMobile={isMobile}
                                />
                            </Stack>
                        </RadioGroup>
                        <Box paddingTop={20}>
                            <ElegantBanner
                                illustration={HandshakeIllustration}
                                content={bannerContentConf[billingCommitment].map(
                                    (content, index) => (
                                        <Inline
                                            key={index}
                                            justifyContent="start"
                                            alignItems="center"
                                        >
                                            <StyledCheckSmall />
                                            <SmallText>
                                                <Inline
                                                    justifyContent="start"
                                                    alignItems="center"
                                                    space={content.gap ?? 4}
                                                    wrap
                                                >
                                                    {content.node}
                                                </Inline>
                                            </SmallText>
                                        </Inline>
                                    ),
                                )}
                            />
                        </Box>
                        <Box paddingTop={28}>
                            <Text>
                                {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                                <Trans>
                                    By clicking <b>Start new plan</b>, you agree to the{' '}
                                    <Button
                                        variant="tertiary"
                                        onClick={() => {
                                            trackChoosePlanModalActioned({
                                                action: 'purchase_terms_link',
                                                step: 'payment_plan',
                                                origin: pageOrigin,
                                                section: pageSection,
                                                plan: selectedPlan,
                                            })
                                            setIsPurchaseTermsModalOpen(true)
                                        }}
                                    >
                                        Purchase Terms.
                                    </Button>
                                </Trans>
                            </Text>
                        </Box>
                        <Box paddingTop={28} paddingBottom={6}>
                            <Button
                                variant="primary"
                                onClick={updateCompanySubscription}
                                loading={isUpdatePlanLoading}
                                disabled={!!subscriptionPriceEstimationsError}
                            >
                                <Trans>Start new plan</Trans>
                            </Button>
                        </Box>
                    </StyledBox>
                )}
            </StyledModalContent>
            <StyledModalFooter>
                <Link onClick={onBack} data-testid="plan-update-modal-back">
                    &lt;- <Trans>back</Trans>
                </Link>
            </StyledModalFooter>
        </>
    )
}

interface BillingOptionCardProps {
    billingCommitment: BillingCommitment
    billingOptionConf: BillingOptionConf
    updateBilling: (billing: BillingCommitment) => void
    isActive?: boolean
    isMobile?: boolean
}

const BillingOptionCard = ({
    billingCommitment,
    billingOptionConf,
    updateBilling,
    isActive = false,
    isMobile,
}: BillingOptionCardProps) => {
    const {label, subLabel, discountedFromPrice, fromPrice} = billingOptionConf

    return (
        <StyledNakedButton
            as="div"
            $isActive={isActive}
            onClick={() => updateBilling(billingCommitment)}
            data-testid="billing-option"
        >
            <Inline space={4} justifyContent="start" alignItems="center" wrap>
                {!isMobile && <RadioButton value={billingCommitment} label="" />}
                <div css={{textAlign: 'left'}}>
                    <Inline space={8} justifyContent="start" alignItems="center">
                        {isMobile ? <Text>{label}:</Text> : <Text>{label}</Text>}
                    </Inline>
                    {!isMobile && <Text variant="small-subtle">{subLabel}</Text>}
                </div>

                <SelfAlignedInline space={6} justifyContent="start" alignItems="center">
                    {discountedFromPrice && <StrikedText>{discountedFromPrice}</StrikedText>}
                    <Trans>
                        <Text weight="medium">{fromPrice}</Text>
                        <Text>/ month</Text>
                    </Trans>
                </SelfAlignedInline>
            </Inline>
            {isMobile && (
                <Text align="left" variant="small-subtle">
                    {subLabel}
                </Text>
            )}
        </StyledNakedButton>
    )
}

const StyledModalFooter = styled(Modal.Footer)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${tokens.colorBackgroundStaticLouder};
    margin-top: ${tokens.spacing60};
`

const StyledModalContent = styled(Modal.Content)<{$fromPlanSelectionModal: boolean}>`
    min-height: ${({$fromPlanSelectionModal}) => ($fromPlanSelectionModal ? '630px' : '530px')};
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
`

const StyledBox = styled(Box)`
    width: 100%;
`

const StyledNakedButton = styled(NakedButton)<{$isActive: boolean}>`
    width: 100%;
    box-sizing: border-box;
    padding: ${tokens.spacing20};
    border-radius: ${tokens.spacing8};
    border: ${({$isActive}) =>
        $isActive
            ? `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveSelected}`
            : `${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractive}`};
`

const StrikedText = styled(Text)`
    color: ${tokens.colorContentInteractiveDisabled};
    text-decoration: line-through;
`

const SelfAlignedInline = styled(Inline)`
    @media (min-width: ${breakpoints.tabletUp}) {
        margin-left: auto;
    }
`

const StyledCheckSmall = styled(CheckSmall)`
    align-self: start;
`
