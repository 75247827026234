import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {styled} from 'styled-components'

import {Card, Stack, Text, tokens} from '@pleo-io/telescope'
import {VisualCard} from '@pleo-io/telescope-pictograms'

import {pxBreakpoints} from '@product-web/styles/theme'
import {useContainerQuery} from '@product-web/telescope-lab/container-queries/container'
import {AppStoreBadge, PlayStoreBadge} from '@product-web-features/ui-store-badges'

import qrAndroidDownload from '../images/qr-android-download.svg'
import qrAppleDownload from '../images/qr-apple-download.svg'

export const DownloadMobileAppCard = () => {
    const isLargeScreen = useContainerQuery({minWidth: pxBreakpoints.mediumTabletUp, name: 'main'})

    if (isLargeScreen) {
        return (
            <QrCardsContainer>
                <QrCardWrapper>
                    <PlatformTitle>
                        <Trans>Android</Trans>
                    </PlatformTitle>
                    <QrImg src={qrAndroidDownload} />

                    <PlayStoreBadge width={120} />
                </QrCardWrapper>

                <QrCardWrapper>
                    <PlatformTitle>
                        <Trans>Apple iOS</Trans>
                    </PlatformTitle>
                    <QrImg src={qrAppleDownload} />
                    <AppStoreBadge width={120} />
                </QrCardWrapper>
            </QrCardsContainer>
        )
    }

    return (
        <Card>
            <Card.Body>
                <Stack space={16}>
                    <VisualCard size="40" />
                    <Text>
                        <Trans>Pay contactless and add receipts on the go.</Trans>
                    </Text>
                    <BadgeWrapper>
                        <PlayStoreBadge height={36} />
                        <AppStoreBadge height={36} />
                    </BadgeWrapper>
                </Stack>
            </Card.Body>
        </Card>
    )
}

const QrCardWrapper = ({children}: {children: ReactNode}) => {
    return (
        <Card css={{width: 'auto'}}>
            <Card.Body>
                <Stack alignItems="center">{children}</Stack>
            </Card.Body>
        </Card>
    )
}

const PlatformTitle = styled(Text).attrs({color: 'colorContentStatic', weight: 'medium'})`
    margin-bottom: ${tokens.spacing12};
`

const QrImg = styled.img.attrs({alt: '', height: '120', width: '120'})`
    margin-bottom: ${tokens.spacing16};
`

const BadgeWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.spacing8};
`

const QrCardsContainer = styled.div`
    display: flex;
    gap: ${tokens.spacing24};
    flex-wrap: wrap;
`
