import {t, Trans} from '@lingui/macro'
import {useRect} from '@reach/rect'
import type {FC, MouseEvent, ReactNode} from 'react'
import {useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {focusRing, Inline, NakedButton, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'
import type {Props as IconProps} from '@pleo-io/telescope-icons'
import {
    ArrowRight,
    CheckCircleFilled,
    Clock,
    Home,
    HomeFilled,
    ReceiptCross,
    Swap,
    Wallet,
} from '@pleo-io/telescope-icons'

import {customColorSchemeTokens} from '@product-web/styles/custom-tokens'
import {breakpoints} from '@product-web/styles/theme'
import {useHover} from '@product-web/web-platform/use-hover'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {formatWalletBalance} from './lib/format-wallet-balance'
import type {VerificationStatus} from './lib/use-verification-status'
import {useVerificationStatus} from './lib/use-verification-status'
import {MeasuredText} from './measure-text-width'

import clockFilled from '../images/clockFilled.svg'
import clockFilledDisabled from '../images/clockFilledDisabled.svg'

interface EntityCardProps {
    isActive?: boolean
    isHomeEntity?: boolean
    companyName?: string
    walletBalance?: {value?: number; currency?: string}
    missingReceiptsCount?: number
    exportQueuedCount?: number
    role: 'bookkeeper' | 'owner' | 'member'
    onClick?: () => void
    onVerifyClick?: () => void
    testId?: string
    verificationStatus?: VerificationStatus
    disabled?: boolean
}

const BREAK_ANYWHERE_THRESHOLD = 23
const SHOW_POPOVER_AUX_THRESHOLD = 16

export const EntityCard: FC<EntityCardProps> = ({
    isActive,
    isHomeEntity,
    companyName,
    walletBalance,
    missingReceiptsCount,
    exportQueuedCount,
    verificationStatus,
    role,
    onClick,
    onVerifyClick,
    testId,
    disabled = false,
}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const companyTextRef = useRef<HTMLDivElement>(null)
    const companyTextRect = useRect(companyTextRef)
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const [hovered, {onMouseEnter, onMouseLeave}] = useHover()

    const handleVerifyClick = (e: MouseEvent) => {
        e.stopPropagation()
        onVerifyClick?.()
    }

    const onWidthChange = (measuredWidth: number) => {
        setIsTooltipVisible(
            measuredWidth > 0 &&
                measuredWidth / 2 + SHOW_POPOVER_AUX_THRESHOLD > (companyTextRect?.width ?? 0),
        )
    }

    // If company name contains words that are longer than BREAK_ANYWHERE_THRESHOLD
    // we have to allow system to break the name anywhere to avoid unnecessary layout shifting
    const overflowWrap = companyName
        ?.split(' ')
        .some((word) => word.length >= BREAK_ANYWHERE_THRESHOLD)
        ? 'anywhere'
        : 'break-word'

    const tooltipSide = isTablet ? 'bottom' : 'right'

    const {status, isVerified, showWalletBalance} = useVerificationStatus(verificationStatus, role)

    const verifiedWalletBalance = showWalletBalance ? walletBalance : {...walletBalance, value: 0}
    const formattedWalletBalance = formatWalletBalance(verifiedWalletBalance)

    return (
        <Tooltip content={isTooltipVisible ? companyName : ''} side={tooltipSide}>
            <StyledButton
                $isActive={isActive}
                $isVerified={isVerified}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid={testId}
                disabled={disabled}
            >
                <Stack stretch>
                    <Inline alignY="top" space={8}>
                        <CompanyTextWrapper ref={companyTextRef}>
                            <CompanyText
                                color={
                                    disabled
                                        ? 'colorContentInteractiveDisabled'
                                        : 'colorContentInteractive'
                                }
                                variant="small-subtle"
                                weight="medium"
                                $overflowWrap={overflowWrap}
                                onWidthChange={onWidthChange}
                            >
                                {companyName}
                            </CompanyText>
                        </CompanyTextWrapper>
                        <ActiveIcon
                            isHomeEntity={isHomeEntity}
                            isActive={isActive}
                            verificationStatus={status}
                            isVerified={isVerified}
                            role={role}
                            color={
                                disabled
                                    ? tokens.colorContentInteractiveDisabled
                                    : tokens.colorContentInteractive
                            }
                            disabled={disabled}
                            size={16}
                        />
                    </Inline>
                    {role === 'owner' && (
                        <>
                            {status !== 'NEEDS_VERIFICATION' && (
                                <Inline alignY="center" space={4}>
                                    <Wallet
                                        color={
                                            disabled
                                                ? tokens.colorContentInteractiveDisabled
                                                : hovered
                                                ? tokens.colorContentInteractiveHover
                                                : tokens.colorContentInteractiveQuiet
                                        }
                                        size={16}
                                    />
                                    <WalletBalanceText
                                        variant="small-subtle"
                                        $isHovered={hovered}
                                        $isDisabled={disabled}
                                        data-testid="entity-card-wallet-balance"
                                    >
                                        {formattedWalletBalance}
                                    </WalletBalanceText>
                                </Inline>
                            )}
                            {status === 'NEEDS_VERIFICATION' && (
                                <Inline alignY="start">
                                    <VerifyButton
                                        onClick={handleVerifyClick}
                                        $isHovered={hovered}
                                        disabled={disabled}
                                    >
                                        <ArrowRight size={16} />
                                        <Trans>Verify</Trans>
                                    </VerifyButton>
                                </Inline>
                            )}
                        </>
                    )}
                    {role === 'bookkeeper' && (
                        <Inline alignY="center" space={16}>
                            <Tooltip content={t`Missing receipts`} side={'top'}>
                                <Inline alignY="center" space={4}>
                                    <ReceiptCross
                                        color={
                                            disabled
                                                ? tokens.colorContentInteractiveDisabled
                                                : hovered
                                                ? tokens.colorContentInteractiveHover
                                                : tokens.colorContentInteractiveQuiet
                                        }
                                        size={16}
                                    />
                                    <WalletBalanceText
                                        $isDisabled={disabled}
                                        variant="small-subtle"
                                        $isHovered={hovered}
                                        data-testid="entity-card-missing-receipts-count"
                                    >
                                        {missingReceiptsCount}
                                    </WalletBalanceText>
                                </Inline>
                            </Tooltip>
                            <Tooltip content={t`Expenses in queue`} side={'top'}>
                                <Inline alignY="center" space={4}>
                                    <Swap
                                        color={
                                            disabled
                                                ? tokens.colorContentInteractiveDisabled
                                                : hovered
                                                ? tokens.colorContentInteractiveHover
                                                : tokens.colorContentInteractiveQuiet
                                        }
                                        size={16}
                                    />
                                    <WalletBalanceText
                                        variant="small-subtle"
                                        $isDisabled={disabled}
                                        $isHovered={hovered}
                                        data-testid="entity-card-export-queued-count"
                                    >
                                        {exportQueuedCount}
                                    </WalletBalanceText>
                                </Inline>
                            </Tooltip>
                        </Inline>
                    )}
                    {role === 'member' && <MemberStub />}
                </Stack>
            </StyledButton>
        </Tooltip>
    )
}

interface HomeIconProps extends IconProps {
    isActive?: boolean
    isHomeEntity?: boolean
    isVerified?: boolean
    verificationStatus?: 'NEEDS_VERIFICATION' | 'VERIFICATION_IN_PROGRESS' | 'VERIFIED'
    disabled: boolean
}

const ActiveIcon: FC<HomeIconProps> = ({
    isActive,
    isHomeEntity,
    verificationStatus,
    isVerified,
    disabled,
    ...rest
}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const tooltipSide = isTablet ? 'bottom' : 'right'

    let icon: ReactNode
    if (isHomeEntity && isVerified) {
        icon = (
            <Tooltip
                content={isHomeEntity ? t`Your default entity where you can spend` : ''}
                side={tooltipSide}
            >
                <div>{isActive ? <HomeFilled {...rest} /> : <Home {...rest} />}</div>
            </Tooltip>
        )
    } else if (!isVerified) {
        const tooltipContent =
            verificationStatus === 'VERIFICATION_IN_PROGRESS'
                ? t`Verification in progress`
                : t`Needs verification`
        icon = (
            <Tooltip content={tooltipContent} side={tooltipSide}>
                <div>
                    {isActive ? (
                        <img src={disabled ? clockFilledDisabled : clockFilled} alt="" />
                    ) : (
                        <Clock {...rest} />
                    )}
                </div>
            </Tooltip>
        )
    } else {
        icon = isActive ? <CheckCircleFilled {...rest} /> : <Placeholder />
    }

    return <ActiveIconWrapper data-testid="entity-card-active">{icon}</ActiveIconWrapper>
}

const ActiveIconWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: end;
`

const StyledButton = styled(NakedButton)<{$isActive?: boolean; $isVerified?: boolean}>`
    height: 108px;
    padding: ${tokens.spacing20} ${tokens.spacing20} ${tokens.spacing16} ${tokens.spacing20};
    border: ${tokens.sizeBorderDefault} solid transparent;
    ${({$isActive, $isVerified, disabled}) =>
        $isVerified
            ? css`
                  border-color: ${$isActive && !disabled
                      ? tokens.colorBorderInteractiveSelected
                      : 'transparent'};
              `
            : css`
                  border-color: ${$isActive
                      ? disabled
                          ? tokens.colorBorderInteractiveDisabled
                          : tokens.colorBorderInteractiveSelected
                      : 'transparent'};
                  /* stylelint-disable-next-line declaration-property-value-allowed-list */
                  border-style: dashed;
              `}
    border-radius: ${tokens.arc8};
    ${focusRing('regular')};
    background-color: ${({$isVerified}) =>
        $isVerified ? tokens.colorBackgroundInteractive : tokens.colorBackgroundInteractiveLoud};
    transition: border ${tokens.fast};

    &:hover {
        ${({$isActive, disabled}) =>
            !$isActive &&
            !disabled &&
            css`
                border-color: ${customColorSchemeTokens.colorBorderEntityCardHover};
            `};
    }
`

const CompanyTextWrapper = styled.div`
    display: flex;
    justify-content: start;
    flex-grow: 1;
`

const CompanyText = styled(MeasuredText)<{$overflowWrap: 'break-word' | 'anywhere'}>`
    overflow: hidden;
    overflow-wrap: ${({$overflowWrap}) => $overflowWrap};
    text-overflow: ellipsis;

    /*
        we have to set height to be 2 times bigger than line-height
        so text will always take two lines even if it fits just 1 line.
        We are using unit-less line-height
        which means it's a multiplier of the font-size
        so height formula is: fontSize * line-height * 2
     */
    font-size: ${tokens.fontSmall};
    line-height: ${tokens.lineHeight3};
    height: calc(${tokens.fontSmall} * ${tokens.lineHeight3} * 2);
    text-align: left;

    /* The styles below allows to achieve ellipsis for the multiline text */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: ${tokens.spacing12};
`

const WalletBalanceText = styled(Text)<{$isHovered: boolean; $isDisabled: boolean}>`
    font-family: Spezia, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({$isDisabled}) =>
        $isDisabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractiveQuiet};

    ${({$isHovered}) =>
        $isHovered &&
        css`
            color: ${tokens.colorContentInteractiveHover};
        `}
`

const MemberStub = styled.div`
    height: 20.4px;
`

const VerifyButton = styled(NakedButton)<{$isHovered?: boolean}>`
    display: flex;
    align-items: center;
    gap: ${tokens.spacing8};
    font-size: ${tokens.fontMedium};
    color: ${({$isHovered, disabled}) =>
        $isHovered && !disabled
            ? tokens.colorContentInteractiveHover
            : tokens.colorContentInteractiveQuiet};

    & > div {
        color: ${({$isHovered, disabled}) =>
            $isHovered && !disabled
                ? tokens.colorContentInteractiveHover
                : tokens.colorContentInteractiveQuiet};
    }
`

const Placeholder = styled.div`
    width: 16px;
    height: 16px;
`
