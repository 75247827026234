import {Plural, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'

import {Banner, type BannerProps, Link} from '@pleo-io/telescope'

import tracking from '@product-web/analytics'
import {PlanTypeName} from '@product-web/api-types/billing'
import {dayjs} from '@product-web/dates/dayjs'
import useConditionedPlans from '@product-web-features/ui-plan-pill/use-conditioned-plans'

import type {TrialActivationBannerActionedExtended} from './types'

const lowTierPlans = ['free', 'starter']
const midTierPlans = ['pro', 'essential']

interface TrialBannerProps {
    pricingPlan?: string
    trialEndDate?: string
    isBeforeTrial?: boolean
    isDuringTrial?: boolean
    handleCta: (modal: 'choose-plans' | 'how-trial-works') => void
}

export const TrialBanner = ({
    pricingPlan,
    trialEndDate,
    isBeforeTrial,
    isDuringTrial,
    handleCta,
}: TrialBannerProps) => {
    const {essential} = useConditionedPlans()

    if (!pricingPlan) {
        return null
    }

    // Quick fix to handle the case when the pricing plan is in uppercase
    const lowercasePricingPlan = pricingPlan.toLowerCase()

    if (isBeforeTrial && lowTierPlans.includes(lowercasePricingPlan)) {
        return (
            <ComposedBanner variant="discover">
                <Trans>
                    Unlock a free trial of essential Pleo features when you add funds to your
                    wallet.
                </Trans>{' '}
                <Link
                    role="button"
                    onClick={() => {
                        tracking.trialActivationBannerActioned({
                            action: 'clicked',
                            plan: 'free',
                        } as TrialActivationBannerActionedExtended)
                        handleCta('how-trial-works')
                    }}
                >
                    <Trans>Learn more</Trans>
                </Link>
            </ComposedBanner>
        )
    }

    if (isBeforeTrial && midTierPlans.includes(lowercasePricingPlan)) {
        return (
            <ComposedBanner variant="discover">
                <Trans>
                    Start your free trial of Pleo {PlanTypeName[essential.type]} features by adding
                    money to your Wallet.
                </Trans>{' '}
                <Link
                    role="button"
                    onClick={() => {
                        tracking.trialActivationBannerActioned({
                            action: 'clicked',
                            plan: 'paid',
                        } as TrialActivationBannerActionedExtended)
                        handleCta('how-trial-works')
                    }}
                >
                    <Trans>Learn more</Trans>
                </Link>
            </ComposedBanner>
        )
    }

    if (isDuringTrial && lowTierPlans.includes(lowercasePricingPlan) && trialEndDate) {
        const remainingTrialDays = Number(dayjs(trialEndDate).diff(dayjs(), 'days')) + 1

        return (
            <ComposedBanner variant="discover">
                <Trans>
                    You've unlocked a free Pleo trial. In{' '}
                    <Plural value={remainingTrialDays} one="# day" other="# days" />, you'll switch
                    back to the Starter (free) plan.
                </Trans>{' '}
                <Link
                    role="button"
                    onClick={() => {
                        tracking.trialActivationBannerActioned({
                            action: 'plans_clicked',
                        } as TrialActivationBannerActionedExtended)
                        handleCta('choose-plans')
                    }}
                >
                    <Trans>Compare plans</Trans>
                </Link>
            </ComposedBanner>
        )
    }

    if (isDuringTrial && midTierPlans.includes(lowercasePricingPlan) && trialEndDate) {
        const remainingTrialDays = Number(dayjs(trialEndDate).diff(dayjs(), 'days')) + 1
        return (
            <ComposedBanner variant="info" loud>
                {remainingTrialDays > 1 ? (
                    <Trans>
                        Your trial ends in{' '}
                        <Plural value={remainingTrialDays} one="# day" other="# days" />
                    </Trans>
                ) : (
                    <Trans>It is the last day of your trial</Trans>
                )}
            </ComposedBanner>
        )
    }

    return null
}

const ComposedBanner = ({
    children,
    ...bannerProps
}: {
    children: ReactNode
} & BannerProps) => (
    <Banner data-testid="trial-banner" {...bannerProps}>
        <Banner.Content>
            <Banner.Text>{children}</Banner.Text>
        </Banner.Content>
    </Banner>
)
