import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/user'

const ReviewPage = React.lazy(async () => import('./screens/review-list/review'))
const CompanyReviewPage = React.lazy(async () => import('./screens/company-review/company-review'))
const ReviewOverviewPage = React.lazy(
    async () => import('./screens/review-overview/review-overview'),
)
const TagReviewPage = React.lazy(async () => import('./screens/tag-review/tag-review'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.companyAndReviewer}}>
        <Route index element={<ReviewPage />} />
        <Route path="review/*" element={<ReviewPage />} />
    </Route>
)

export const reviewsSettingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<ReviewOverviewPage />} />
        <Route path="company" element={<CompanyReviewPage />} />
        <Route path="tags" element={<TagReviewPage />} />
    </Route>
)
