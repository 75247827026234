import {Trans} from '@lingui/macro'

import {Button, Link, Text, theme} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'
import type {DispatchActions, Step} from '@product-web-features/ui-guides/guide'

import PaperAirplaneIllustration from '../../../images/paper-airplane.svg'

export const useSuccessStep = (): Step => {
    const {multiEntitySelfExpansion} = useFlags()
    const color = multiEntitySelfExpansion ? theme.colors.yellow300 : theme.colors.pink300

    return {
        key: 'success',
        color,
        illustrationBgColor: color,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                {multiEntitySelfExpansion ? (
                    <Trans>Your request was sent</Trans>
                ) : (
                    <Trans>Your request to add entities was sent</Trans>
                )}
            </Text>
        ),
        sections: [
            {
                key: 'description',
                content: (
                    <Text as="p" align="left">
                        {!multiEntitySelfExpansion ? (
                            <Trans>
                                Your account manager will contact you within 3 days through email to
                                get you set up.{' '}
                                <Link
                                    href={getHelpCentreArticleLinkIntercom(
                                        '6394286-what-is-multi-entity-management#h_651d9799c9',
                                    )}
                                    target="_blank"
                                >
                                    Learn more
                                </Link>
                            </Trans>
                        ) : (
                            <Trans>
                                Your account manager will contact you by phone or email to get you
                                set up soon.
                            </Trans>
                        )}
                    </Text>
                ),
            },
        ],
        illustration: <img src={PaperAirplaneIllustration} alt="" />,
        actions: [
            {
                key: 'done',
                action: ({onCompleted}: DispatchActions) => (
                    <Button variant="primary" onClick={onCompleted}>
                        <Trans>Done</Trans>
                    </Button>
                ),
            },
        ],
    }
}
