import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, Link, Modal, Stack, Text, tokens} from '@pleo-io/telescope'

import {useTermsDocuments} from '@product-web/country-configuration/features/terms/terms'

const Back = styled(Text).attrs({color: 'colorContentInteractiveLink'})`
    cursor: pointer;
    position: relative;
    bottom: ${tokens.spacing10};
`

interface ModalPurchaseTermsProps {
    onCloseHandle: () => void
}

const ModalPurchaseTermsContent = ({onCloseHandle}: ModalPurchaseTermsProps) => {
    const {masterServiceAgreement, dataProcessingAgreement, privacyPolicy} = useTermsDocuments()

    return (
        <>
            <Box paddingLeft={20}>
                <Back variant="medium-default" onClick={onCloseHandle}>
                    <Trans>← Back</Trans>
                </Back>
            </Box>
            <Modal.Content id="purchase-terms-modal-title" align="left">
                <Text
                    css={{textAlign: 'center', marginBottom: tokens.spacing24}}
                    variant="3xlarge-accent"
                >
                    <Trans>Purchase terms</Trans>
                </Text>

                <Stack space={24}>
                    <Text>
                        <Trans>The prices stated are excluding any applicable taxes or VAT.</Trans>
                    </Text>

                    <Text>
                        <Trans>
                            Any additional users added during the term of the agreement will be
                            subject to the commercial unit price stated or following from the
                            pricing page. The billing frequency will follow from the 'Review your
                            plan'. Payment terms will also follow from the Purchase Terms and/or
                            invoice.
                        </Trans>
                    </Text>

                    <Text>
                        <Trans>
                            The agreement shall be subject to an initial term and commencing as
                            stated in the 'Review your plan'. The agreement shall automatically
                            renew for an additional term unless the Customer has, prior to the
                            renewal period, provided written notice of termination to
                            cancellation@pleo.io at least 30 days before the end of a term.
                        </Trans>
                    </Text>

                    <Text>
                        <Trans>
                            By agreeing to the Purchase Terms, you warrant that you are authorised
                            to act on behalf of your employer and can represent the Customer by
                            entering this agreement. By agreeing to the Purchase Terms you accept
                            the terms outlined in the Purchase Terms, including price, any add-on
                            services or additional users as well the conditions outlined above.
                        </Trans>
                    </Text>

                    <Text>
                        <Trans>
                            In addition, agreeing to the Purchase Terms you accept Pleo's{' '}
                            <Link
                                href={masterServiceAgreement}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Master Service Agreement
                            </Link>
                            ,{' '}
                            <Link
                                href={dataProcessingAgreement}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Data Processing Agreement
                            </Link>{' '}
                            and{' '}
                            <Link href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </Link>
                            . The Purchase Terms shall form an integral part of the Master Service
                            Agreement, however, any terms in this Purchase Terms shall prevail over
                            the terms of the Master Service Agreement in case of inconsistency.
                        </Trans>
                    </Text>

                    <Text>
                        <Trans>
                            This Purchase Terms supersedes and replaces in its entirety all previous
                            oral or written agreements, memoranda, correspondence or other
                            communications between the parties hereto relating to the subject matter
                            hereof.
                        </Trans>
                    </Text>
                </Stack>
            </Modal.Content>
        </>
    )
}

export default ModalPurchaseTermsContent
