import {t, Trans} from '@lingui/macro'
import countryCodeEmoji from 'country-code-emoji'
import {useFormikContext} from 'formik'
import React, {useState} from 'react'
import styled from 'styled-components'

import {Button, FormikCheckbox, FormikSelect, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {Email} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {useFlags} from '@product-web/flags'
import Country from '@product-web/locale/country'
import {getCountryName, getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'
import {breakpoints} from '@product-web/styles/theme'

import {bff} from '../../../bff'
import type {FormPropsV1} from '../../lib/request-entities-modal-types'

function useCountriesList() {
    const {usaMarketLaunch} = useFlags()

    return [
        {code: Country.AT, isEnabled: true},
        {code: Country.DK, isEnabled: true},
        {code: Country.FI, isEnabled: true},
        {code: Country.FR, isEnabled: true},
        {code: Country.DE, isEnabled: true},
        {code: Country.IE, isEnabled: true},
        {code: Country.ES, isEnabled: true},
        {code: Country.SE, isEnabled: true},
        {code: Country.GB, isEnabled: true},
        {code: Country.EE, isEnabled: true},
        {code: Country.NL, isEnabled: true},
        {code: Country.BE, isEnabled: true},
        {code: Country.LU, isEnabled: true},
        {code: Country.PT, isEnabled: true},
        {code: Country.IT, isEnabled: true},
        {code: Country.NO, isEnabled: true},
        {code: Country.US, isEnabled: usaMarketLaunch},
    ]
        .filter((country) => country.isEnabled)
        .map(({code}) => ({
            name: getCountryName(code),
            code,
            flag: countryCodeEmoji(code),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
}

export const RequestEntitiesForm = ({
    setRequestFailed,
    setMissingSelection,
}: {
    setRequestFailed: (value: boolean) => void
    setMissingSelection: (value: boolean) => void
}) => {
    const {data} = bff.featureMultiEntity.requestEntitiesForm.formData.useQuery()

    const {values, errors, setErrors} = useFormikContext<FormPropsV1>()

    const countries = useCountriesList()

    const supportedCountries = countries.map((country) => {
        return {
            label: `${country.flag} ${country.name}`,
            value: country.name,
        }
    })

    React.useEffect(() => {
        setErrors({})
        setRequestFailed(false)
        setMissingSelection(false)
    }, [])

    return (
        <Stack space={24}>
            <Text as="p" align="left">
                <Trans>
                    After you send us a request, your account manager will contact you within 3 days
                    to get you set up.{' '}
                    <Link
                        href={getHelpCentreArticleLinkIntercom(
                            '6394286-what-is-multi-entity-management#h_651d9799c9',
                        )}
                        target="_blank"
                        onClick={() => {
                            if (!data) {
                                return
                            }
                            tracking.selfExpansionHelpArticleClicked({
                                companyId: data?.companyId,
                                userId: data?.userId,
                                hasOrgAccess: data?.hasOrgAccess,
                            })
                        }}
                    >
                        Learn more
                    </Link>
                </Trans>
            </Text>
            <Text weight="medium">
                <Trans>What would you like to do?</Trans>
            </Text>

            <FormWrapper>
                <FormikCheckbox
                    name="addNewEntities"
                    onChange={() => {
                        setRequestFailed(false)
                        setMissingSelection(false)
                    }}
                >
                    <Trans>Add new entities to Pleo</Trans>
                </FormikCheckbox>
                <TextWrapper>
                    <Text variant="small-subtle" as="p">
                        <Trans>
                            I want to add new entities to this organisation. They are located in the
                            following countries:
                        </Trans>
                    </Text>
                    <CountrySelect
                        name="countries"
                        placeholder={t`See locations`}
                        testId="request-entities-form-countries"
                        disabled={values.addNewEntities === false}
                        isMulti
                        portalled
                        closeMenuOnSelect={false}
                        isClearable={false}
                        options={supportedCountries}
                        isInvalid={Boolean(errors.countries) && !values.countries.length}
                        renderError={() => {
                            if (values.addNewEntities === false) {
                                return null
                            }
                            if (errors.countries) {
                                return (
                                    <Text color="red700" variant="small-subtle">
                                        {errors.countries}
                                    </Text>
                                )
                            }
                            return null
                        }}
                    />
                </TextWrapper>
                <FormikCheckbox
                    name="groupExistingEntities"
                    onChange={() => {
                        setRequestFailed(false)
                        setMissingSelection(false)
                    }}
                >
                    <Trans>Group companies that are already in Pleo</Trans>
                </FormikCheckbox>
                <TextWrapper>
                    <Text variant="small-subtle" as="p">
                        <Trans>
                            I have companies that are already using Pleo and want to group them into
                            one organisation
                        </Trans>
                    </Text>
                </TextWrapper>
            </FormWrapper>
        </Stack>
    )
}

export const SendButton = ({
    isOrganizationAdmin,
    onNextStep,
    setRequestFailed,
    setMissingSelection,
}: {
    isOrganizationAdmin: boolean
    onNextStep: () => void
    setRequestFailed: (value: boolean) => void
    setMissingSelection: (value: boolean) => void
}) => {
    const {mutateAsync: requestEntities} =
        bff.featureMultiEntity.requestEntitiesForm.requestEntities.useMutation()

    const {values, validateForm} = useFormikContext<FormPropsV1>()

    const atLeastOneChecked = values.addNewEntities || values.groupExistingEntities
    const hasNotSelectedCountries = values.addNewEntities && values.countries.length === 0
    const countryValues = values.addNewEntities
        ? values.countries.map((country) => country.value)
        : []

    const selectedValues = {
        ...values,
        countries: countryValues,
    }

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async () => {
        setRequestFailed(false)
        setMissingSelection(false)

        if (!atLeastOneChecked || hasNotSelectedCountries) {
            setMissingSelection(!atLeastOneChecked)
            await validateForm()
            return
        }
        setIsSubmitting(true)
        try {
            await requestEntities(selectedValues)
            tracking.selfExpansionRequestSent({
                addNewEntities: values.addNewEntities,
                groupExistingEntities: values.groupExistingEntities,
                hasOrgAccess: isOrganizationAdmin,
            })
            onNextStep()
        } catch (e) {
            setRequestFailed(true)
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Button
                IconLeft={!isSubmitting ? Email : undefined}
                loading={isSubmitting}
                variant="primary"
                onClick={handleSubmit}
                loadingText={t`Sending...`}
            >
                <Trans>Send</Trans>
            </Button>
        </>
    )
}

export const RequestFailedMessage = ({
    requestFailed,
    missingSelection,
}: {
    requestFailed?: boolean
    missingSelection?: boolean
}) => {
    if (requestFailed) {
        return (
            <RequestFailedWrapper>
                <Text variant="small-subtle" color="red700" align="left">
                    <Trans>
                        There was a problem with sending your request. Please try again. If the
                        problem continues, contact our support.
                    </Trans>
                </Text>
            </RequestFailedWrapper>
        )
    }
    if (missingSelection) {
        return (
            <MissingSelection>
                <Text color="red700" align="left">
                    <Trans>Select at least one option above</Trans>
                </Text>
            </MissingSelection>
        )
    }
    return null
}

const FormWrapper = styled.div`
    width: 100%;
`

const CountrySelect = styled(FormikSelect)`
    margin-top: ${tokens.spacing8};
    margin-bottom: ${tokens.spacing20};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;

    & > div > div > div {
        max-height: 80px;
        overflow-y: auto;
    }

    @media (max-width: ${breakpoints.desktopUp}) {
        min-width: 100%;
    }
`
const TextWrapper = styled.div`
    padding-left: ${tokens.spacing32};
    padding-bottom: ${tokens.spacing4};

    @media (max-width: ${breakpoints.desktopUp}) {
        padding-right: ${tokens.spacing32};
    }
`

const RequestFailedWrapper = styled.div`
    margin-top: ${tokens.spacing8};
`
const MissingSelection = styled.div`
    margin-top: ${tokens.spacing20};
`
