import type {Dayjs} from 'dayjs'

import type {Amount} from '@pleo-io/deimos'

import type {PlanUpgradeSource} from './plan'
import {PlanType} from './plan'

interface ProductResponse {
    id: string
    name?: string
    description?: string
    plans?: PlanResponse[]
}

// Pricing plan names should not be translated
/* eslint-disable string-to-lingui/missing-lingui-transformation */
export const PlanTypeName: Record<PlanType, string> = {
    [PlanType.LEGACY]: 'Legacy',
    [PlanType.EXTRA]: 'Extra',
    [PlanType.YEARLY_EXTRA]: 'Extra - Yearly',
    [PlanType.PRO]: 'Pro',
    [PlanType.PREMIUM]: 'Premium',
    [PlanType.YEARLY]: 'Premium - Yearly',
    [PlanType.ESSENTIAL]: 'Essential',
    [PlanType.PARTNERSHIP]: 'Partnership',
    [PlanType.MANAGING_PARTNER]: 'Managing Partner',
    [PlanType.MANAGING_PARTNER_5]: 'Managing Partner - 5',
    [PlanType.MANAGING_PARTNER_10]: 'Managing Partner - 10',
    [PlanType.MANAGING_PARTNER_15]: 'Managing Partner - 15',
    [PlanType.MANAGING_PARTNER_20]: 'Managing Partner - 20',
    [PlanType.MANAGING_PARTNER_25]: 'Managing Partner - 25',
    [PlanType.PARTNER_CLIENT_FREE]: 'Partner Client - Free',
    [PlanType.PARTNER_CLIENT_PRO]: 'Partner Client - Pro',
    [PlanType.UNKNOWN]: 'Unknown',
    [PlanType.FREE]: 'Free',
    [PlanType.STARTER]: 'Starter',
    [PlanType.FIXED_RATE]: 'Fixed Rate',
    [PlanType.TEAM]: 'Team',
    [PlanType.BUSINESS]: 'Business',
    [PlanType.ADVANCED]: 'Advanced',
    [PlanType.BEYOND]: 'Beyond',
    [PlanType.INSURANCE]: 'Insurance',
}
/* eslint-enable string-to-lingui/missing-lingui-transformation */

export enum Interval {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum BillingInterval {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export const stripeIntervalToBillingInterval = (stripeInterval: Interval) => {
    switch (stripeInterval) {
        case Interval.MONTH:
            return BillingInterval.MONTHLY
        case Interval.YEAR:
            return BillingInterval.YEARLY
        default:
            return BillingInterval.MONTHLY
    }
}

export type PlanResponseReason = 'AVAILABLE' | 'TOO_MANY_EMPLOYEES'

export interface PlanResponse {
    id: string
    nickname: string
    product: ProductResponse
    type: PlanType
    amountDecimal?: number
    billingScheme?: string
    currency?: string
    interval?: Interval
    intervalCount?: number
    trialPeriodDays?: number
    eligibility: boolean
    reason: PlanResponseReason
    country: string
    userCommitment?: number
    nextBillingDate?: string
}

interface CurrentPeriodResponse {
    start: Date
    end: Date
}

interface Trial {
    start: string
    end: string
}

export interface BreakDownItem {
    amount: Amount
    description: string
    quantity: number
}

export interface ProrationLine {
    amount: Amount
    description: string
    quantity: number
}

export interface BillingBreakdown {
    additionalUsersCost: Amount
    baseUsersCost: Amount
    discountAmount: Amount
    prorationLines?: ProrationLine[]
    subtotal: Amount
    total: Amount
    totalProration: Amount
    totalBeforeVat: Amount
    vatAmount: Amount
    pendingCards?: BreakDownItem[]
    pendingReimbursements?: BreakDownItem[]
    pendingUsers?: number
}

export interface SubscriptionResponse {
    id: string
    companyId: string
    status: SubscriptionStatus
    customerId: string
    planSubscriptionItem: SubscriptionItemResponse
    extraUsersSubscriptionItem?: SubscriptionItemResponse
    latestInvoiceId?: string
    currentPeriod?: CurrentPeriodResponse
    started: Date
    couponId?: string
    freeAdministrators: number
    isInTrial?: boolean
    trial?: Trial
    billingEmail: string
    billingBreakdownUpcoming: BillingBreakdown
    taxExemptStatus: string
    insuranceSubscriptionItem?: SubscriptionItemResponse
    contract?: boolean
    includeCashback?: boolean
    userCommitment?: number
}

export enum SubscriptionStatus {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    INCOMPLETE = 'INCOMPLETE',
    INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
    TRIALING = 'TRIALING',
    PAST_DUE = 'PAST_DUE',
    UNPAID = 'UNPAID',
    STRIPE_SUBSCRIPTION = 'STRIPE_SUBSCRIPTION',
}

export interface SubscriptionItemResponse {
    id: string
    quantity?: number
    freeAdministrators?: number
    plan: PlanResponse
    totalAmount?: Amount
}

export interface UpdateStripeSubscriptionRequest {
    activeUserPrice?: number
    companyId?: string
    planType?: PlanType | string
    couponId?: string
    numberOfUsers?: number
    freeAdministrators?: number
    startDate: Dayjs
    userCommitment?: number
    intervalCommitment?: number
    interval?: Interval
    intervalType?: BillingInterval
    includeCashback?: boolean
    planUpgradeSource?: PlanUpgradeSource
    clickwrapAgreement?: {
        contractPrice: {
            currency: string
            value: number
        }
        discountedContractPrice: {
            currency: string
            value: number
        }
        purchaseAgreementUrl: string
        contractIntervalCommitment: number
    }
}
