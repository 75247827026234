import {t, Trans} from '@lingui/macro'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {v4 as uuid} from 'uuid'

import {Badge, Box, Button, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, CheckCircleFilled, Lock} from '@pleo-io/telescope-icons'
import {AddFunds, Bank, Coins, Verification} from '@pleo-io/telescope-pictograms'

import * as tracking from '@product-web/analytics'
import {dayjs} from '@product-web/dates/dayjs'
import {formatCurrency} from '@product-web/locale/currency'
import {
    Container,
    containerQuery,
    useContainerQuery,
} from '@product-web/telescope-lab/container-queries/container'
import {getIsFdd, useCompanyUser} from '@product-web/user'
import {useNavigateToDirectDebitCreation} from '@product-web-features/funds-management/direct-debit-navigation'
import {StaticTransferDetailsModal} from '@product-web-features/funds-management/static-transfer-details-modal/static-transfer-details'

import {bff} from '../bff-hooks'
import type {MilestoneName, MilestoneStatus} from '../universal/types'

const MOBILE_BREAKPOINT = 400
const CONTAINER_QUERY_NAME = 'MilestoneCard'

//#region Components

type Amount = {
    value: number
    currency: string
}

const headingLevels = ['h2', 'h3', 'h4', 'h5', 'h6'] as const

type MilestoneCardProps = {
    milestone: MilestoneName
    status: MilestoneStatus
    headingLevel: Exclude<(typeof headingLevels)[number], 'h6'>
    shouldShowReserve?: boolean
    reserveLimit?: Amount
    companyVerificationSubmittedDate: string | undefined
    isFromMultiEntitySignupFlow?: boolean
    hasMultiEntityCompanyBilling?: boolean
}

export const MilestoneCard = ({
    milestone,
    status,
    headingLevel,
    shouldShowReserve,
    reserveLimit,
    companyVerificationSubmittedDate,
    isFromMultiEntitySignupFlow,
    hasMultiEntityCompanyBilling,
}: MilestoneCardProps) => {
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const navigate = useNavigate()
    const user = useCompanyUser()
    const {navigateToDirectDebitCreation} = useNavigateToDirectDebitCreation()
    const [shouldShowTransferDetailsModal, setShouldShowTransferDetailsModal] =
        React.useState(false)

    const isFdd = getIsFdd(user)
    const reserveLimitString = formatCurrency(reserveLimit?.value, reserveLimit?.currency, {
        format: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    })

    switch (milestone) {
        case 'GET_VERIFIED': {
            const getDescription = () => {
                switch (status) {
                    case 'AWAITING_REVIEW':
                        if (companyVerificationSubmittedDate) {
                            const formattedDate = dayjs(companyVerificationSubmittedDate).format(
                                'D MMMM YYYY',
                            )

                            return t`Submitted on ${formattedDate}.`
                        }

                        return null

                    default:
                        return t`Get ready to spend with Pleo.`
                }
            }

            const getCta = () => {
                switch (status) {
                    case 'IN_PROGRESS':
                    case 'INCOMPLETE':
                        return (
                            <ContinueButton
                                variant="primary"
                                onClick={() => {
                                    startTask('GET_VERIFIED')
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone,
                                        task: 'GET_VERIFIED',
                                    })
                                    navigate('/company-verification')
                                }}
                            >
                                <Trans>Continue</Trans>
                            </ContinueButton>
                        )

                    default:
                        return null
                }
            }

            const getBadgeText = () => {
                switch (status) {
                    case 'IN_PROGRESS':
                        return t`In progress`

                    case 'AWAITING_REVIEW':
                        return t`Awaiting review`

                    default:
                        return null
                }
            }

            return (
                <MilestoneCardInternal
                    status={status}
                    isActive={status === 'INCOMPLETE' || status === 'IN_PROGRESS'}
                    headingLevel={headingLevel}
                    Illustration={Verification}
                    heading={
                        isFromMultiEntitySignupFlow
                            ? t`Verify your new entity`
                            : t`Verify your company`
                    }
                    description={getDescription()}
                    cta={getCta()}
                    badgeText={getBadgeText()}
                    footerText={
                        !isFdd && isFromMultiEntitySignupFlow && !hasMultiEntityCompanyBilling
                            ? t`Your account manager will get in touch to set up a pricing plan.`
                            : null
                    }
                />
            )
        }
        case 'LOAD_WALLET': {
            const disabled = !isFdd && !shouldShowReserve && status === 'INCOMPLETE'
            const isBankTransferDisabled = !isFdd

            const getCta = () => {
                switch (status) {
                    case 'INCOMPLETE':
                        return (
                            <ContinueButton
                                variant="primary"
                                disabled={disabled}
                                onClick={() => {
                                    startTask('LOAD_WALLET')
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone,
                                        task: 'LOAD_WALLET',
                                    })
                                    navigate('/onboarding/topup-wallet')
                                }}
                            >
                                <Trans>Continue</Trans>
                            </ContinueButton>
                        )

                    default:
                        return null
                }
            }

            const getBadgeText = () => {
                switch (status) {
                    case 'PENDING_TRANSFER':
                        return t`Awaiting transfer`

                    case 'PENDING_RESERVE':
                        return t`Pending`

                    default:
                        return null
                }
            }

            const getDescription = () => {
                switch (status) {
                    case 'PENDING_TRANSFER':
                        return (
                            <Trans>
                                Usually takes 2-3 days to arrive. Haven't made the transfer yet?{' '}
                                {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                                <Button
                                    variant="tertiary"
                                    onClick={() => setShouldShowTransferDetailsModal(true)}
                                >
                                    View transfer details
                                </Button>
                            </Trans>
                        )

                    case 'PENDING_RESERVE':
                        return t`Credit will arrive after your direct debit agreement has been processed.`

                    default:
                        return null
                }
            }

            return (
                <>
                    {shouldShowTransferDetailsModal && (
                        <StaticTransferDetailsModal
                            isOpen={shouldShowTransferDetailsModal}
                            onClose={() => setShouldShowTransferDetailsModal(false)}
                        />
                    )}

                    <MilestoneCardInternal
                        status={status}
                        isActive={!disabled}
                        headingLevel={headingLevel}
                        Illustration={AddFunds}
                        heading={t`Add funds to your wallet`}
                        description={getDescription()}
                        footerText={disabled ? t`You need to be verified to do this.` : undefined}
                        cta={getCta()}
                        badgeText={getBadgeText()}
                    >
                        {shouldShowReserve && status === 'INCOMPLETE' && (
                            <Stack space={16} stretch>
                                <MilestoneCardInternal
                                    headingLevel={
                                        headingLevels[headingLevels.indexOf(headingLevel) + 1]
                                    }
                                    Illustration={Bank}
                                    heading={t`Bank transfer`}
                                    description={
                                        isBankTransferDisabled
                                            ? t`You need to be verified to do this.`
                                            : t`Transfer any amount to start spending.`
                                    }
                                    isChildCard
                                    cta={
                                        <ContinueButton
                                            variant="secondary"
                                            disabled={isBankTransferDisabled}
                                            onClick={() => {
                                                startTask('LOAD_WALLET')
                                                tracking.companyOnboardingMilestoneActioned({
                                                    action: 'started',
                                                    milestone,
                                                    task: 'LOAD_WALLET',
                                                })
                                                navigate('/onboarding/topup-wallet')
                                            }}
                                        >
                                            <Trans>Continue</Trans>
                                        </ContinueButton>
                                    }
                                />

                                <MilestoneCardInternal
                                    headingLevel={
                                        headingLevels[headingLevels.indexOf(headingLevel) + 1]
                                    }
                                    Illustration={Coins}
                                    heading={t`Credit`}
                                    description={t`Start with ${reserveLimitString} interest-free.`}
                                    cta={
                                        <ContinueButton
                                            variant="secondary"
                                            onClick={() => {
                                                startTask('APPLY_FOR_CREDIT')
                                                tracking.companyOnboardingMilestoneActioned({
                                                    action: 'started',
                                                    milestone,
                                                    task: 'APPLY_FOR_CREDIT',
                                                })
                                                navigateToDirectDebitCreation({
                                                    preFilledAutoTopUpSettings: {
                                                        autoTopupStatus: 'ENABLED',
                                                        autoTopupType: 'LOW_BALANCE',
                                                        paymentRail: 'DD',
                                                        lowBalanceTopup: {
                                                            amount: reserveLimit?.value ?? 0,
                                                            threshold: 0,
                                                        },
                                                    },
                                                    backToUrl: '/onboarding/pleo-guide',
                                                })
                                            }}
                                        >
                                            <Trans>Continue</Trans>
                                        </ContinueButton>
                                    }
                                    isChildCard
                                />
                            </Stack>
                        )}
                    </MilestoneCardInternal>
                </>
            )
        }

        default:
            return null
    }
}

type MilestoneCardInternalProps = {
    status?: MilestoneCardProps['status']
    isActive?: boolean
    headingLevel: (typeof headingLevels)[number]
    Illustration: (
        props: React.SVGProps<SVGSVGElement> & {
            size?: string | undefined
        },
    ) => JSX.Element
    heading: string
    description?: React.ReactNode
    footerText?: React.ReactNode
    children?: React.ReactNode
    isChildCard?: boolean
    cta?: React.ReactNode
    badgeText?: string | null
}

const MilestoneCardInternal = ({
    status,
    isActive,
    isChildCard,
    headingLevel,
    Illustration,
    heading,
    description,
    footerText,
    children,
    cta,
    badgeText,
}: MilestoneCardInternalProps) => {
    const headingId = uuid()
    const checkmarkId = uuid()
    const statusBadgeId = uuid()
    const footerId = uuid()

    return (
        <Container name={CONTAINER_QUERY_NAME}>
            <OuterWrapper
                role="region"
                aria-labelledby={headingId}
                aria-describedby={[footerId, statusBadgeId, checkmarkId].join(' ')}
                aria-current={isActive}
                $isActive={isActive}
                $isChildCard={isChildCard}
                $isComplete={status === 'COMPLETE'}
            >
                <Stack space={24} p={24} stretch>
                    <Main>
                        <Content>
                            {status !== 'COMPLETE' && (
                                <IllustrationWrapper $isChildCard={isChildCard}>
                                    <Illustration />
                                </IllustrationWrapper>
                            )}

                            <Inline space={16} alignY="center">
                                {status === 'COMPLETE' && (
                                    <CheckCircleFilled
                                        color={tokens.colorBorderPositive}
                                        id={checkmarkId}
                                        aria-label={t`Completed`}
                                    />
                                )}

                                <Stack space={4}>
                                    <Inline space={8}>
                                        <Text
                                            as={headingLevel}
                                            variant={isChildCard ? 'large-accent' : 'xlarge-accent'}
                                            weight="medium"
                                            color={
                                                status === 'COMPLETE'
                                                    ? 'colorContentPositive'
                                                    : 'colorContentStatic'
                                            }
                                        >
                                            <Box as="span" id={headingId} mr={badgeText ? 8 : 0}>
                                                {heading}
                                            </Box>

                                            {badgeText && (
                                                <Badge id={statusBadgeId} variant="info">
                                                    {badgeText}
                                                </Badge>
                                            )}
                                        </Text>
                                    </Inline>

                                    {description && status !== 'COMPLETE' && (
                                        <Text variant="medium-default">{description}</Text>
                                    )}
                                </Stack>
                            </Inline>
                        </Content>

                        {!children && status !== 'COMPLETE' && cta}
                    </Main>

                    {children}
                </Stack>

                {footerText && <Footer id={footerId}>{footerText}</Footer>}
            </OuterWrapper>
        </Container>
    )
}

type FooterProps = {
    id: string
    children: React.ReactNode
}

const Footer = ({id, children}: FooterProps) => {
    const isWide = useContainerQuery({
        name: CONTAINER_QUERY_NAME,
        minWidth: MOBILE_BREAKPOINT,
    })

    return (
        <StyledFooter id={id}>
            <Text
                variant={isWide ? 'medium-default' : 'small-subtle'}
                color="colorContentStaticQuiet"
            >
                {children}
            </Text>
        </StyledFooter>
    )
}

//#endregion Components

//#region Styles

const outerWrapperActiveStyles = css`
    border-color: ${tokens.colorBorderStaticLoud};
`

const outerWrapperChildCardStyles = css`
    border-radius: ${tokens.arc8};
    min-height: unset;
`

const outerWrapperCompleteStyles = css`
    background: ${tokens.colorBackgroundPositiveQuiet};
    border-color: transparent;
    min-height: unset;
`

type OuterWrapperProps = {
    $isActive?: boolean
    $isChildCard?: boolean
    $isComplete?: boolean
}

const OuterWrapper = styled(Card)<OuterWrapperProps>`
    border-radius: ${tokens.arc12};
    overflow: hidden;
    min-height: 134px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({$isActive, $isChildCard}) => $isActive && !$isChildCard && outerWrapperActiveStyles}
    ${({$isChildCard}) => $isChildCard && outerWrapperChildCardStyles}
    ${({$isComplete}) => $isComplete && outerWrapperCompleteStyles}
`

const Main = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${tokens.spacing24};
    row-gap: ${tokens.spacing24};
    align-items: center;
    justify-items: start;

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: 1fr auto;
        `,
    )}
`

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: ${tokens.spacing16};
    row-gap: ${tokens.spacing8};

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: auto 1fr;
        `,
    )}
`

const StyledFooter = styled.footer`
    background: ${tokens.colorBackgroundStaticLoud};
    padding: ${tokens.spacing16} ${tokens.spacing24};
    border-top: ${tokens.borderPrimary};
`

type IllustrationWrapperProps = {
    $isChildCard?: boolean
}

const IllustrationWrapper = styled.div<IllustrationWrapperProps>(({$isChildCard}) => {
    return css`
        width: ${$isChildCard ? '40px' : '48px'};
        height: ${$isChildCard ? '40px' : '48px'};

        ${containerQuery(
            {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
            css`
                width: ${$isChildCard ? '64px' : '48px'};
                height: ${$isChildCard ? '64px' : '48px'};
            `,
        )}
    `
})

const ContinueButton = styled(Button).attrs(({disabled}) => ({
    IconRight: disabled ? Lock : ArrowRight,
}))``

//#endregion Styles
