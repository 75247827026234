import {Trans} from '@lingui/macro'
import * as React from 'react'
import {Link as RouterLink, Outlet, Route} from 'react-router-dom'

import {Link, LoadingPage} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {useFlags, useFlagsLoaded} from '@product-web/flags'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {roleSets} from '@product-web/user'
import {Page} from '@product-web-features/ui-page/page'

import {GuidelineEditor} from './components/guideline-editor'

const GuidelinesScreen = React.lazy(async () => import('./screens'))

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.company}} element={<Wrapper />}>
        <Route element={<GuidelinesScreen />}>
            <Route index element={null} />
            <Route path=":account" element={<GuidelineEditor />} />
        </Route>
    </Route>
)

function Wrapper() {
    const flagsLoaded = useFlagsLoaded()
    const {manageSpendGuidelines} = useFlags()

    if (!flagsLoaded) {
        return <LoadingPage />
    }

    if (!manageSpendGuidelines) {
        return <PageNotFound />
    }

    return (
        <Page layout="wide" showNav>
            <Page.Header>
                <Page.Backlink>
                    <Link IconLeft={ArrowLeft} to="/settings" as={RouterLink}>
                        <Trans>Back to Settings</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Spending guidelines</Trans>
                </Page.Title>
            </Page.Header>
            <Outlet />
        </Page>
    )
}
