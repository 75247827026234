import {t} from '@lingui/macro'
import queryString from 'qs'
import React from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import type {Amount, BankAccountInfo, CurrencyType} from '@pleo-io/deimos'

import {callApi} from '@product-web/api'
import type {WalletSettingsFeature} from '@product-web/api-types/wallet'
import {useToaster} from '@product-web/toaster'
import {useLoggedInUser} from '@product-web/user'

import {getDeimos} from './helpers'

export interface CompletedWalletLoad {
    amount: Amount
    bill: Amount
    performed: string
    type: 'LOAD' | 'UNLOAD'
    status: 'COMPLETED'
}

export interface WalletFeaturesResponse {
    features: WalletSettingsFeature[]
    openBankingTransactionLimit?: number
    autoTopupMinimumAmount?: number
}

export function useWalletFeatures() {
    const user = useLoggedInUser()
    const result = useSWRImmutable<WalletFeaturesResponse, Error>(
        user?.companyId
            ? `/wallet-management/rest/v4/companies/${user.companyId}/wallet-features`
            : null,
        getDeimos,
    )

    return {
        ...result,
        isLoading: !result.error && !result.data,
    }
}

export function useHasWalletFeature(featureName: WalletSettingsFeature) {
    const {data: walletSettings, isLoading} = useWalletFeatures()
    return {
        isLoading,
        hasWalletFeature: Boolean(walletSettings?.features?.includes(featureName)),
    }
}

export interface ActiveBankAccountDetails {
    id: string
    companyId: string
    currency: CurrencyType
    bankName?: string // human-readable string
    senderBank?: string // system-readable string
    bankAccountInfo: BankAccountInfo
}

type ActiveBankAccountsResponse = ActiveBankAccountDetails[]

export function useActiveBankAccounts() {
    const user = useLoggedInUser()

    const result = useSWRImmutable<ActiveBankAccountsResponse, Error>(
        user?.companyId ? `/rest/v1/styx/companies/${user.companyId}/proof-of-funds/basic` : null,
        getDeimos,
    )
    return {
        ...result,
        isLoading: user?.companyId && !result.data && !result.error,
    }
}

const INSTANT_TOP_UP_BANK_BANK_LIMIT = 6
const INSTITUTIONS_ICON_SIZE = 80
export type OpenBankingBankDetails = {
    id: string
    institutionId: string
    name: string
    imageUrl: string
    status: 'APPROVED' | 'SUSPENDED' | 'DEGRADED_PERFORMANCE'
}

export type InstitutionsResponse = {result: OpenBankingBankDetails[]; totalItems: number}

type AutocompleteBankSelectionResult = {
    bankList: OpenBankingBankDetails[]
    totalItems: number
    isLoading: boolean
    hasError: boolean
}

const VRP_BANK_FEATURE_PAYMENT_TYPE = 'DOMESTIC_VARIABLE_RECURRING_PAYMENT'

export function useAutocompleteOpenBankingBankSelection(
    inputValue?: string,
    options?: {isAutoTopUpFlow?: boolean},
): AutocompleteBankSelectionResult {
    const {showToast} = useToaster()

    const user = useLoggedInUser()

    const [lastResult, setLastResult] = React.useState<AutocompleteBankSelectionResult>({
        bankList: [],
        totalItems: 0,
        isLoading: false,
        hasError: false,
    })
    const queryParams = queryString.stringify({
        name: inputValue || '',
        limit: INSTANT_TOP_UP_BANK_BANK_LIMIT,
        iconSize: INSTITUTIONS_ICON_SIZE,
        paymentType: options?.isAutoTopUpFlow ? VRP_BANK_FEATURE_PAYMENT_TYPE : undefined,
    })

    const isAbleToCall = Boolean(user.companyId)
    const response = useSWR<InstitutionsResponse>(
        isAbleToCall
            ? `/open-banking/rest/v1/companies/${user.companyId}/institutions?${queryParams}`
            : null,
        getDeimos,
    )

    React.useEffect(() => {
        if (!isAbleToCall || response.error) {
            showToast(t`An error occurred. Please try again later or contact support.`, {
                level: 'error',
            })
        }

        const isLoading = isAbleToCall && !response.error && !response.data
        if (isLoading) {
            setLastResult((prev) => ({
                ...prev,
                isLoading: true,
            }))
        } else if (!response.error) {
            setLastResult({
                bankList: response.data ? response.data.result : [],
                totalItems: response.data ? response.data.totalItems : 0,
                isLoading: false,
                hasError: false,
            })
        } else {
            setLastResult({
                bankList: [],
                totalItems: 0,
                isLoading: false,
                hasError: true,
            })
        }
    }, [response.error, response.data, isAbleToCall, showToast])

    return lastResult
}

// todo: remove this method when BE is ready with the new flow - https://linear.app/pleo/issue/WALLE-5059
export async function createOpenBankingPayment(companyId: string, consentToken: string) {
    return callApi(`/wallet-management/rest/v4/companies/${companyId}/payments`, {
        auth: 'user',
        method: 'POST',
        body: {consentToken},
    })
}
