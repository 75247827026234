import * as React from 'react'
import {Navigate, Outlet, Route} from 'react-router-dom'

import {Page} from '@product-web-features/ui-page/page'

const Invites = React.lazy(
    async () => import('./screens/employee-invites-list/employee-invites-list'),
)
const Team = React.lazy(async () => import('./screens/people-list/people-list'))
const InviteMember = React.lazy(async () => import('./screens/invite-employee/invite-employee'))

const Wrapper = () => {
    return (
        <Page layout="wide" showNav>
            <Outlet />
        </Page>
    )
}

export const routes = (
    <Route handle={{allowedRoles: ['partner-owner']}} element={<Wrapper />}>
        <Route index element={<Navigate to="people" replace />} />
        <Route path="people" element={<Team />} />
        <Route path="invites" element={<Invites />} />
        <Route path="invite" element={<InviteMember />} />
        <Route path=":partnerEmployeeId" element={<Team />} />
    </Route>
)
