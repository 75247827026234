/**
 * @overview List
 * @stage Proposed
 * @author  Paul and William
 * @designer
 * @spec
 */

import type {FocusEventHandler, MouseEventHandler} from 'react'
import React, {useCallback, useEffect, useState} from 'react'
import styled, {css} from 'styled-components'

import {focusRing, tokens} from '@pleo-io/telescope'

import {useInfiniteScroll} from '@product-web/web-platform/infinite-scroll'
import {Keys} from '@product-web/web-platform/keyboard'
import {useKeyPress} from '@product-web/web-platform/use-keypress'

export interface RenderItemProps<T> {
    item: T
    index: number
    listItemProps: {
        id: string
        key: string
        role: 'option'
        'aria-posinset': number
        'aria-setsize': number
        'aria-selected'?: true
        onClick?: MouseEventHandler<HTMLLIElement>
    }
}

export interface RenderGroupProps<T> {
    group: ListGroup<T>
    groupProps: {
        role: 'group'
        key: string
        'aria-labelledby': string
    }
    listItemProps: {
        id: string
        role: 'presentation'
    }
    children: any
}

export type RenderItem<T> = (itemProps: RenderItemProps<T>) => JSX.Element | null

export type RenderGroup<T> = (groupProps: RenderGroupProps<T>) => JSX.Element | null

interface ListData<T = any> {
    renderItem: RenderItem<T>
    renderGroup?: RenderGroup<T>
}

interface UniquelyIdentifiable {
    id: string
}

type DataListItem<T> = UniquelyIdentifiable & T

export interface ListGroup<T = any> {
    id: string
    children: T[]
    [key: string]: any
}

interface UseList<T> {
    dataFlat: T[]
    dataGrouped?: ListGroup<T>[]
    activeItemId: string | null
    setActiveItemId: (id: string | null) => void
    onFocus: FocusEventHandler<any>
    onBlur: FocusEventHandler<any>
    handleNext: () => void
    handlePrevious: () => void
    hasNext: boolean
    hasPrevious: boolean
}

export function useList<T>({
    dataFlat = [],
    dataGrouped = [],
    initialActiveIndex,
    onSelect,
}: {
    dataFlat?: DataListItem<T>[]
    dataGrouped?: ListGroup<T>[]
    initialActiveIndex?: string | null
    onSelect?: (item: T | null) => void
} = {}): UseList<T> {
    const [activeItemId, _setActiveItemId] = React.useState<string | null>(
        initialActiveIndex ?? null,
    )

    const [isFocused, setIsFocused] = useState<boolean>(false)

    const currentIndex = dataFlat.findIndex((i) => activeItemId === i.id)

    const setActiveItemId = useCallback(
        (id: string | null) => {
            _setActiveItemId(id)
            const index = dataFlat.findIndex((i) => id === i.id)
            onSelect && onSelect(dataFlat[index] ?? null)
        },
        [dataFlat, onSelect],
    )

    const hasNext = dataFlat && currentIndex !== dataFlat.length - 1
    const hasPrevious = !!(dataFlat && currentIndex && currentIndex !== 0 && currentIndex !== -1)

    const selectFirstItem = () => {
        if (dataFlat[0]) {
            setActiveItemId(dataFlat[0].id)
        }
    }

    const handleNext = () => {
        if (activeItemId === null) {
            selectFirstItem()
            return
        }
        if (hasNext && typeof currentIndex === 'number' && currentIndex <= dataFlat.length) {
            setActiveItemId(dataFlat[currentIndex + 1].id)
        }
    }

    const handlePrevious = () => {
        if (activeItemId === null) {
            selectFirstItem()
            return
        }
        if (hasPrevious && typeof currentIndex === 'number' && currentIndex !== 0) {
            setActiveItemId(dataFlat[currentIndex - 1].id)
        }
    }

    const onFocus: FocusEventHandler<HTMLUListElement> = useCallback(() => {
        setIsFocused(true)
    }, [])

    const onBlur: FocusEventHandler<HTMLUListElement> = useCallback(() => {
        setIsFocused(false)
    }, [])

    useKeyPress(Keys.ARROW_DOWN, (e) => {
        if (!isFocused) {
            return
        }
        e.preventDefault()
        handleNext()
    })

    useKeyPress(Keys.ARROW_UP, (e) => {
        if (!isFocused) {
            return
        }
        e.preventDefault()
        handlePrevious()
    })

    useKeyPress(Keys.ESCAPE, () => activeItemId !== null && setActiveItemId(null))

    return {
        dataFlat,
        dataGrouped,
        activeItemId,
        setActiveItemId,
        onFocus,
        onBlur,
        hasNext,
        hasPrevious,

        handleNext,
        handlePrevious,
    }
}

export interface Props<TItem>
    extends ListData<TItem>,
        React.HTMLAttributes<HTMLUListElement | HTMLDivElement> {
    dataFlat: TItem[]
    dataGrouped?: ListGroup<TItem>[]
    activeItemId: string | null
    onFocus: FocusEventHandler<any>
    onBlur: FocusEventHandler<any>

    onItemClick?: (item: TItem) => void
    fetchMore?: () => void
    canFetchMore?: boolean
    isFetchingMore?: boolean
    customListScrollTargetHeight?: number

    checkContainerForScroll?: boolean
    scrollIntoViewOptions?: ScrollIntoViewOptions
}

const GroupedList = <T extends UniquelyIdentifiable>({
    dataGrouped,
    dataFlat,
    activeItemId,
    renderItem,
    renderGroup,
    onItemClick,
}: {
    dataFlat: T[]
    dataGrouped: ListGroup<T>[]
    activeItemId: string | null
    renderItem: RenderItem<T>
    renderGroup?: RenderGroup<T>
    onItemClick?: (item: T) => void
}) => {
    if (!renderGroup) {
        throw new Error('renderGroup is required when passing dataGrouped')
    }

    return (
        <>
            {dataGrouped.map((group) => {
                const children = group.children.map((item) => {
                    const index = dataFlat.findIndex((i) => i.id === item.id)
                    const setsize = dataFlat.length
                    const selected = activeItemId === item.id ? true : undefined
                    const itemWithEmployeeId = {
                        ...item,
                        ...(group.employeeId && {employeeId: group.employeeId}),
                    }

                    return renderItem({
                        item: itemWithEmployeeId,
                        index,
                        listItemProps: {
                            id: item.id,
                            key: item.id,
                            role: 'option',
                            'aria-posinset': index + 1,
                            'aria-setsize': setsize,
                            'aria-selected': selected,
                            ...(onItemClick && {onClick: () => onItemClick(item)}),
                        },
                    })
                })

                return renderGroup({
                    group,
                    groupProps: {
                        role: 'group',
                        key: group.id,
                        'aria-labelledby': group.id,
                    },
                    listItemProps: {
                        id: group.id,
                        role: 'presentation',
                    },
                    children,
                })
            })}
        </>
    )
}

const FlatList = <T extends UniquelyIdentifiable>({
    dataFlat,
    activeItemId,
    renderItem,
    onItemClick,
}: {
    dataFlat: T[]
    activeItemId: string | null
    renderItem: RenderItem<T>
    onItemClick?: (item: T) => void
}) => (
    <>
        {dataFlat.map((item: any, index) => {
            return renderItem({
                item,
                index,
                listItemProps: {
                    id: item.id,
                    key: item.id,
                    role: 'option',
                    'aria-posinset': index + 1,
                    'aria-setsize': dataFlat.length,
                    'aria-selected': activeItemId === item.id ? true : undefined,
                    ...(onItemClick && {onClick: () => onItemClick(item)}),
                },
            })
        })}
    </>
)

export function List<T extends UniquelyIdentifiable>({
    dataFlat,
    dataGrouped = [],
    activeItemId,
    onItemClick,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    renderGroup,
    renderItem,
    customListScrollTargetHeight,
    checkContainerForScroll,
    scrollIntoViewOptions,
    children,
    ...props
}: Props<T>) {
    const ref = React.useRef<any>(null)
    const goalRef = React.useRef<HTMLDivElement>(null)

    const shouldFetchMore = Boolean(canFetchMore && !isFetchingMore)

    useInfiniteScroll(shouldFetchMore && fetchMore ? fetchMore : null, goalRef)

    useEffect(() => {
        if (activeItemId) {
            const itemEl: HTMLLIElement | undefined =
                ref?.current?.querySelector(`[aria-selected="true"]`)
            const rect = itemEl?.getBoundingClientRect()
            const height = checkContainerForScroll
                ? ref.current?.getBoundingClientRect()?.height
                : window.innerHeight
            const top =
                checkContainerForScroll && ref.current && rect
                    ? ref.current?.scrollTop + ref.current?.getBoundingClientRect().y
                    : 0
            if (itemEl && rect && height && (rect.y + rect.height > height || rect.y < top)) {
                itemEl.scrollIntoView(scrollIntoViewOptions ?? {block: 'nearest'})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItemId])

    if (dataFlat.length === 0) {
        return null
    }

    return (
        <ListWrapper
            as={dataGrouped.length > 0 ? 'div' : 'ul'}
            ref={ref}
            role="listbox"
            tabIndex={0}
            aria-busy={isFetchingMore}
            aria-activedescendant={activeItemId ?? undefined}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if ((e.key === Keys.ENTER || e.key === Keys.SPACE) && onItemClick) {
                    const item = dataFlat.find((i) => i.id === activeItemId)
                    item && onItemClick(item)
                }
            }}
            data-generic-ui="list"
            {...props}
        >
            {dataGrouped.length > 0 ? (
                <GroupedList
                    dataFlat={dataFlat}
                    dataGrouped={dataGrouped}
                    activeItemId={activeItemId}
                    renderItem={renderItem}
                    renderGroup={renderGroup}
                    onItemClick={onItemClick}
                />
            ) : (
                <FlatList
                    dataFlat={dataFlat}
                    activeItemId={activeItemId}
                    renderItem={renderItem}
                    onItemClick={onItemClick}
                />
            )}
            {children}
            <Bottom ref={goalRef} $targetHeight={customListScrollTargetHeight} />
        </ListWrapper>
    )
}

export const ListScrollTargetHeight = 200

const Bottom = styled.div<{$targetHeight?: number}>`
    ${(props) =>
        css`
            min-height: ${props.$targetHeight ?? ListScrollTargetHeight}px;
        `}
    width: 100%;
`

const ListWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin: 0 6%;
    outline: none;
    border-radius: ${tokens.arc12};
    min-width: 70%;
    ${focusRing('regular')}
`

export const ListItem = styled.li`
    width: 100%;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    list-style-type: none;
`

export const ListGroup = styled.ul`
    width: 100%;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
`
