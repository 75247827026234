import Locale from '@product-web/locale/locale'

export enum Terms {
    Documents = 'documents',
}

export const defaultLocale = Locale.en_GB
export const nonDefaultLocales = Object.values(Locale).filter((locale) => locale !== defaultLocale)

type LocalisedDocument = Partial<{[key in Locale]: string}>

export type LocalisedDocuments = {
    masterServiceAgreement: LocalisedDocument
    masterServiceAgreementB4B: LocalisedDocument
    dataProcessingAgreement: LocalisedDocument
    privacyPolicy: LocalisedDocument
    purchaseTerms: LocalisedDocument
    cardholderAgreement: LocalisedDocument
    authorisedUserTerms: LocalisedDocument
}

export type TermsConfig = {
    [Terms.Documents]: LocalisedDocuments
}
