import {useFlags, useFlagsLoaded} from '@product-web/flags'

import {bffHooks} from './bff-hooks'
import type {SamlConfigContext, SamlConfigMutationsContext} from './contexts/saml-config'

const bff = bffHooks.settingsIntegrations.samlConfig

export function useSamlConfig(): SamlConfigContext {
    const flags = useFlags()
    const isFlagsLoaded = useFlagsLoaded()
    const {
        data: samlConfig = null,
        isLoading,
        isError,
    } = bff.getSamlConfig.useQuery({state: flags.samlSsoConfigState}, {enabled: isFlagsLoaded})

    return {
        samlConfig,
        isLoading,
        isError,
    }
}

export function useSamlConfigMutations(): SamlConfigMutationsContext {
    const {mutateAsync: createConfig, isLoading: isCreating} = bff.createSamlConfig.useMutation()
    const {mutateAsync: enableConfig, isLoading: isEnabling} = bff.enableSamlConfig.useMutation()
    const {mutateAsync: disconnectConfig, isLoading: isDisconnecting} =
        bff.disconnectSamlConfig.useMutation()

    return {
        isCreating,
        isEnabling,
        isDisconnecting,
        create: async () => {
            await createConfig()
            return
        },
        enable: async () => {
            await enableConfig()
            return
        },
        disconnect: async () => {
            await disconnectConfig()
            return
        },
    }
}
