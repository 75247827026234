import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'
import type {SpacingValues} from '@pleo-io/telescope-tokens/dist/spacing.types'

import {choosePlanModalActioned} from '@product-web/analytics'
import type {CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {dayjs} from '@product-web/dates/dayjs'
import {CurrencyFormat, formatCurrency} from '@product-web/locale/currency'

import type {PlansInfo} from '../index.bff'
import type {PageOrigin, PageSection} from '../types'

const AllowedPlanTypes: CpqRatePlanType[] = ['STARTER', 'ESSENTIAL', 'ADVANCED', 'BEYOND']

export type AllowedPlanType = (typeof AllowedPlanTypes)[number]

const allowedPlanTypesSet = new Set(AllowedPlanTypes)
export const isOfAllowedPlanType = (plan: string) =>
    allowedPlanTypesSet.has(plan as AllowedPlanType)

/*
    Type PlanColumnType (below) is used as a way to handle content for the different plans based on which context they're being shown.

    The copy in the SIMPLE key will be used when the plan is shown as the current plan that needs upgrading.

    The copy in the AWESOME key will be used when the plan is shown as the plan the user need to upgrade to.

    This is specially important in the case of ESSENTIAL plan, which can be a starting point for some users, but a potencial upgrade for others, and we want to present those cases differently.
 */
export type PlanColumnType = 'SIMPLE' | 'AWESOME'
export type BillingCommitment = 'FLEXIBLE' | 'YEARLY'
export type PlanPrices = PlansInfo['plansPrices'][AllowedPlanType]

const getRealTimeExpenseTrackingConf = () => ({
    title: t`Real-time expense tracking`,
})

const getSpenManagementEssentials = () => ({
    title: t`Spend management essentials`,
})

const get3CompanyCardsConf = () => ({
    title: t`3 company cards`,
})

const getCardExpensesOnlyConf = () => ({
    title: t`Card expenses only`,
})

const getAnalyticsDashboardConf = () => ({
    title: t`Analytics dashboard`,
    tooltip: t`Discover top spenders and spend categories, receipt capture rates, and more.`,
})

const getSpendingLimitsConf = () => ({
    title: t`Spending limits`,
    tooltip: t`Set limits for card holders based on their role or seniority.`,
})

const getRecurringExpensesManagementConf = () => ({
    title: t`Recurring expenses management`,
    tooltip: t`Avoid duplicate payments by getting an overview of subscriptions, ads and cloud spend.`,
})

const GetApprovalWorkflowsConf = () => ({
    title: t`Approval workflows`,
    tooltip: t`Get managers to review expenses above a certain amount. `,
})

const getIndividualCardsConf = () => ({
    title: t`Individual cards`,
    tooltip: t`Trace every expense to a specific employee or cardholder.`,
})

const getReimbursementsConf = () => ({
    title: t`Reimbursements`,
    tooltip: t`Reimburse employees for out-of-pocket expenses and mileage.`,
})

const getOutOfPocketExpensesConf = () => ({
    title: t`Out-of-pocket expenses`,
    tooltip: t`Reimburse employees for out-of-pocket expenses and mileage.`,
})

const getInvoicePaymentConf = () => ({
    title: t`Invoice payments`,
    tooltip: t`Pay up to 20 invoices a month through Pleo with no transfer fees.`,
})

const getApprovalWorkflowsConf = () => ({
    title: t`Approval workflows`,
    tooltip: t`Get managers to review expenses above a certain amount.`,
})

const getMultiStepApprovalWorkflowsConf = () => ({
    title: t`Multi-step approval workflows`,
    tooltip: t`Get managers to review expenses above a certain amount.`,
})

const getTeamAnalyticsConf = () => ({
    title: t`Team analytics`,
    tooltip: t`Discover top spenders and spend categories, receipt capture rates, and more.`,
})

const getBudgetsConf = () => ({
    title: t`Budgets`,
    tooltip: t`Track spending in relation to department or client budgets.`,
})

const getVendorCardsConf = () => ({
    title: t`Vendor cards`,
    tooltip: t`Set up virtual cards for recurring payments.`,
})

const getPeopleIntegrationsConf = () => ({
    title: t`People management integrations`,
    tooltip: t`Keep your Pleo account up-to-date with your company's employee access system.`,
})

const getOpenAPIConf = () => ({
    title: t`Open API`,
    tooltip: t`Leverage your Pleo data and build your own custom solutions.`,
})

const getSingleSignOnConf = () => ({
    title: t`Single Sign-On`,
    tooltip: t`Make it easy for employees to log in securely with your chosen SSO provider.`,
})

const getCustomerSuccessManagerConf = () => ({
    title: t`Customer Success Manager`,
    tooltip: t`A real person at Pleo to help with questions, onboarding, and best practices.`,
})

export const getStarterBenefitList = ({
    priceAtmWithdrawal,
    priceExtBookkeeper,
}: {
    priceAtmWithdrawal: string
    priceExtBookkeeper: string
}) => [
    {
        title: t`${priceAtmWithdrawal} / ATM Withdrawal`,
    },
    {title: t`2.49% Foreign exchange fee`},
    {
        title: t`${priceExtBookkeeper} / External Bookkeeper`,
    },
]

export const getEssentialOrAdvancedBenefitListWithStarter = ({
    priceExtBookkeeper,
}: {
    priceExtBookkeeper: string
}) => [
    {title: t`Unlimited ATM withdrawals`},
    {title: t`1.99% Foreign exchange fee`},
    {title: t`${priceExtBookkeeper} / External Bookkeeper`},
]

export const getEssentialBenefitList = () => [
    {title: t`1 or 2 entities`},
    {title: t`20 invoice payments`},
]

const getAdvancedBenefitList = () => [
    {title: t`Unlimited entities`},
    {title: t`100 invoice payments`},
]

export const getPlanUpdateModalConf = ({pageOrigin}: {pageOrigin: PageOrigin}) => {
    switch (pageOrigin) {
        case 'analytics':
        case 'recurring-expenses':
        case 'add-people-integrations-promo':
        case 'company-integrations-people-management-promo':
        case 'people-management-app-details':
        case 'vendor-cards':
            return {
                title: t`You're seconds away from smarter spending`,
            }
        case 'pocket':
            return {
                title: t`A spend solution your team can count on`,
            }
        case 'budgets':
            return {
                title: t`Create your ideal spend solution`,
            }
        case 'limits':
        case 'teams':
        case 'company-review':
            return {
                title: t`Lead the way to smarter spending`,
            }
        default:
            return {
                title: '',
            }
    }
}

interface PlanUpdateOptions {
    description: string
    featuresLabel: string
    featuresList: Array<{title: ReactNode; tooltip?: string}>
    benefitsList: Array<{title: ReactNode; tooltip?: string}>
}

// Temporary var to satisfy TS, this code is not used anymore and will be removed soon
const emptyPlanConf = {
    SIMPLE: {
        description: '',
        featuresLabel: '',
        featuresList: [],
        benefitsList: [],
    },
    AWESOME: {
        description: '',
        featuresLabel: '',
        featuresList: [],
        benefitsList: [],
    },
}

export const getPlanUpdatePagesConf = ({
    pageOrigin,
    priceAtmWithdrawal,
    priceExtBookkeeperStarter,
    priceExtBookkeeperEssentialAndAdvanced,
}: {
    pageOrigin: PageOrigin
    priceAtmWithdrawal: string
    priceExtBookkeeperStarter: string
    priceExtBookkeeperEssentialAndAdvanced: string
}): {
    title: string
    pillLabel: string
    plansConf: Record<AllowedPlanType, Record<PlanColumnType, PlanUpdateOptions>>
} => {
    switch (pageOrigin) {
        case 'analytics':
            return {
                title: t`You're seconds away from smarter spending`,
                pillLabel: t`Includes Analytics`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend management`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams who want to keep a closer eye on company spending`,
                            featuresLabel: t`Greater visibility and control`,
                            featuresList: [
                                getAnalyticsDashboardConf(),
                                getSpendingLimitsConf(),
                                getRecurringExpensesManagementConf(),
                                GetApprovalWorkflowsConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
        case 'recurring-expenses':
            return {
                title: t`You're seconds away from smarter spending`,
                pillLabel: t`Includes Recurring expenses`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [
                                get3CompanyCardsConf(),
                                getRealTimeExpenseTrackingConf(),
                            ],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams who want to keep a closer eye on company spending`,
                            featuresLabel: t`Greater visibility and control`,
                            featuresList: [
                                getIndividualCardsConf(),
                                getRecurringExpensesManagementConf(),
                                GetApprovalWorkflowsConf(),
                                getAnalyticsDashboardConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
        case 'limits':
            return {
                title: t`Lead the way to smarter spending`,
                pillLabel: t`Includes Limits`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams looking to improve cost-effectiveness and prevent overspending`,
                            featuresLabel: t`Greater visibility and control`,
                            featuresList: [
                                getSpendingLimitsConf(),
                                GetApprovalWorkflowsConf(),
                                getReimbursementsConf(),
                                getAnalyticsDashboardConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
        case 'pocket':
            return {
                title: t`A spend solution your team can count on`,
                pillLabel: t`Includes Reimbursements`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getCardExpensesOnlyConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams that move fast and need spend management to follow suit`,
                            featuresLabel: t`More flexibility for the finance team`,
                            featuresList: [
                                getOutOfPocketExpensesConf(),
                                getInvoicePaymentConf(),
                                getRecurringExpensesManagementConf(),
                                getAnalyticsDashboardConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
        case 'budgets':
            return {
                title: t`Create your ideal spend solution`,
                pillLabel: t`Includes Budgets`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: t`For companies on a steady path to smarter business spending`,
                            featuresLabel: t`Centralise business spend`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getEssentialBenefitList(),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ADVANCED: {
                        SIMPLE: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getBudgetsConf(),
                                getSpendingLimitsConf(),
                                getApprovalWorkflowsConf(),
                                getAnalyticsDashboardConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                        AWESOME: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getBudgetsConf(),
                                getOpenAPIConf(),
                                getSingleSignOnConf(),
                                getCustomerSuccessManagerConf(),
                            ],
                            benefitsList: getAdvancedBenefitList(),
                        },
                    },
                    BEYOND: emptyPlanConf,
                },
            }
        case 'vendor-cards':
            return {
                title: t`Create your ideal spend solution`,
                pillLabel: t`Includes Vendor cards`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: t`For companies on a steady path to smarter business spending`,
                            featuresLabel: t`Centralise business spend`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getEssentialBenefitList(),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ADVANCED: {
                        SIMPLE: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getBudgetsConf(),
                                getSpendingLimitsConf(),
                                getApprovalWorkflowsConf(),
                                getVendorCardsConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                        AWESOME: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getVendorCardsConf(),
                                getBudgetsConf(),
                                getOpenAPIConf(),
                                getCustomerSuccessManagerConf(),
                            ],
                            benefitsList: getAdvancedBenefitList(),
                        },
                    },
                    BEYOND: emptyPlanConf,
                },
            }
        case 'add-people-integrations-promo':
        case 'company-integrations-people-management-promo':
        case 'people-management-app-details':
            return {
                title: t`Lead the way to smarter spending`,
                pillLabel: t`Includes People integrations`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend management`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: t`For companies on a steady path to smarter business spending`,
                            featuresLabel: t`Centralise business spend`,
                            featuresList: [
                                getSpenManagementEssentials(),
                                getRealTimeExpenseTrackingConf(),
                            ],
                            benefitsList: getEssentialBenefitList(),
                        },
                        // "ESSENTIAL" will not be shown as an upgrade option for this pageOrigin (it's only available on ADVANCED plans), thus the AWESOME content for ESSENTIAL is not needed
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ADVANCED: {
                        SIMPLE: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getPeopleIntegrationsConf(),
                                getSingleSignOnConf(),
                                getSpendingLimitsConf(),
                                getApprovalWorkflowsConf(),
                            ],
                            benefitsList: getAdvancedBenefitList(),
                        },
                        AWESOME: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getPeopleIntegrationsConf(),
                                getSingleSignOnConf(),
                                getSpendingLimitsConf(),
                                getApprovalWorkflowsConf(),
                            ],
                            benefitsList: getAdvancedBenefitList(),
                        },
                    },
                    BEYOND: emptyPlanConf,
                },
            }
        case 'company-review':
            return {
                title: t`Lead the way to smarter spending`,
                pillLabel: t`Includes Company Review`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams looking to improve cost-effectiveness and prevent overspending`,
                            featuresLabel: t`Added customisation and control`,
                            featuresList: [
                                getMultiStepApprovalWorkflowsConf(),
                                getTeamAnalyticsConf(),
                                getSpendingLimitsConf(),
                                getIndividualCardsConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
        case 'settings-accounting':
            return {
                title: t`Create your ideal spend solution`,
                pillLabel: t`INCLUDES AUTO VAT SPLIT`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: t`For companies on a steady path to smarter business spending`,
                            featuresLabel: t`Centralise business spend`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getEssentialBenefitList(),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ADVANCED: {
                        SIMPLE: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getBudgetsConf(),
                                getSpendingLimitsConf(),
                                getApprovalWorkflowsConf(),
                                getAnalyticsDashboardConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                        AWESOME: {
                            description: t`For larger organisations who need spend management at scale`,
                            featuresLabel: t`Complete control for the finance team`,
                            featuresList: [
                                getBudgetsConf(),
                                getOpenAPIConf(),
                                getSingleSignOnConf(),
                                getCustomerSuccessManagerConf(),
                            ],
                            benefitsList: getAdvancedBenefitList(),
                        },
                    },
                    BEYOND: emptyPlanConf,
                },
            }
        case 'teams':
        default:
            return {
                title: t`Lead the way to smarter spending`,
                pillLabel: t`Includes Teams`,
                plansConf: {
                    STARTER: {
                        SIMPLE: {
                            description: t`For startups and companies who are just beginning their Pleo journey`,
                            featuresLabel: t`Basic spend overview`,
                            featuresList: [getRealTimeExpenseTrackingConf()],
                            benefitsList: getStarterBenefitList({
                                priceAtmWithdrawal,
                                priceExtBookkeeper: priceExtBookkeeperStarter,
                            }),
                        },
                        AWESOME: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                    },
                    ESSENTIAL: {
                        SIMPLE: {
                            description: '',
                            featuresLabel: '',
                            featuresList: [],
                            benefitsList: [],
                        },
                        AWESOME: {
                            description: t`For teams looking to improve cost-effectiveness and prevent overspending`,
                            featuresLabel: t`Added customisation and control`,
                            featuresList: [
                                getApprovalWorkflowsConf(),
                                getTeamAnalyticsConf(),
                                getSpendingLimitsConf(),
                                getIndividualCardsConf(),
                            ],
                            benefitsList: getEssentialOrAdvancedBenefitListWithStarter({
                                priceExtBookkeeper: priceExtBookkeeperEssentialAndAdvanced,
                            }),
                        },
                    },
                    ADVANCED: emptyPlanConf,
                    BEYOND: emptyPlanConf,
                },
            }
    }
}

export const SmallText = styled(Text).attrs({
    variant: 'small-subtle',
    color: 'colorContentStatic',
})``

export const DashedText = styled(SmallText)`
    text-decoration: underline dotted ${tokens.colorContentStatic};
    cursor: pointer;
`

const getTodayPriceText = ({priceText}: {priceText: string}) => (
    <Trans>
        Today you'll pay <b>{priceText}</b> (incl. VAT) from your Pleo Wallet
    </Trans>
)

export const dateFormat = 'D MMMM YYYY'

export const getBannerContentConf = ({
    priceText,
}: {
    priceText: string
}): Record<BillingCommitment, Array<{node: ReactNode; gap?: 0 | SpacingValues}>> => {
    const aYearFromToday = dayjs().add(1, 'year').format(dateFormat)
    const aMonthFromToday = dayjs().add(1, 'month').format(dateFormat)

    return {
        YEARLY: [
            {
                node: getTodayPriceText({priceText}),
            },
            {
                node: (
                    <Trans>
                        Renews next year: <b>{aYearFromToday}</b>
                    </Trans>
                ),
            },
        ],
        FLEXIBLE: [
            {
                node: getTodayPriceText({priceText}),
            },
            {
                node: (
                    <Trans>
                        Renews next month: <b>{aMonthFromToday}</b>
                    </Trans>
                ),
            },
        ],
    }
}

export interface BillingOptionConf {
    label: ReactNode
    subLabel: ReactNode
    discountedFromPrice: string
    fromPrice: string
}

export const getPlanBillingConf = ({
    priceYearlySum,
    priceMonthly,
    priceDiscountedMonthly,
}: {
    priceYearlySum: string
    priceMonthly: string
    priceDiscountedMonthly: string
}): Record<BillingCommitment, BillingOptionConf> => ({
    YEARLY: {
        label: <Trans>Yearly</Trans>,
        subLabel: <Trans>One payment of {priceYearlySum}</Trans>,
        discountedFromPrice: priceMonthly,
        fromPrice: priceDiscountedMonthly,
    },
    FLEXIBLE: {
        label: <Trans>Monthly (flexible)</Trans>,
        subLabel: <Trans>Cancel anytime</Trans>,
        discountedFromPrice: '',
        fromPrice: priceMonthly,
    },
})

export const starterAddonsPricesPerCurrencyMap: Record<
    'ATM_WITHDRAWALS' | 'EXTERNAL_BOOKKEEPER',
    Record<string, string>
> = {
    ATM_WITHDRAWALS: {
        DKK: '45 DKK',
        SEK: '60 SEK',
        GBP: '5 GBP',
        NOK: '52 NOK',
        EUR: '5 EUR',
    },
    EXTERNAL_BOOKKEEPER: {
        DKK: '80 DKK',
        SEK: '110 SEK',
        GBP: '9 GBP',
        NOK: '0 NOK',
        EUR: '9 EUR',
    },
}

export const trackChoosePlanModalActioned = ({
    action,
    step,
    origin,
    section,
    plan,
    previousPlan,
    interval,
    commitment,
}: {
    action:
        | 'abandoned'
        | 'back'
        | 'completed'
        | 'get_started'
        | 'purchase_terms_link'
        | 'view_all_link'
        | 'viewed'
    step: 'payment_plan' | 'pricing_plan'
    origin: PageOrigin
    section?: PageSection
    plan?: AllowedPlanType
    previousPlan?: AllowedPlanType
    interval?: 'MONTH' | 'YEAR'
    commitment?: 1 | 12
}) => {
    choosePlanModalActioned({
        action,
        step,
        origin,
        ...(section ? {section} : null),
        ...(plan ? {plan} : null),
        ...(previousPlan ? {previousPlan} : null),
        ...(interval ? {interval} : null),
        ...(commitment ? {commitment} : null),
    })
}

export const formatMinorCurrencyNoDecimal = (amount = 0, currency: string): string =>
    formatCurrency(amount / 100, currency, {
        format: CurrencyFormat.FixedFractionDigits(0),
    })
