import {useRect} from '@reach/rect'
import type {FC} from 'react'
import {useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {focusRing, Inline, NakedButton, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {ArrowRight, Office} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {customColorSchemeTokens} from '@product-web/styles/custom-tokens'
import {breakpoints} from '@product-web/styles/theme'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {formatWalletBalance} from './lib/format-wallet-balance'
import {useVerificationStatus} from './lib/use-verification-status'
import {MeasuredText} from './measure-text-width'

import {bff} from '../bff-hooks'
import {useInternalNavigationContext} from '../navigation-internal-provider'
import {NavigationLogoLoader} from '../navigation-logo'

interface EntitySwitcherButtonProps {
    isActive: boolean
    companyName?: string
    walletBalance?: string
    onClick: () => void
}

export const EntitySwitcherButtonWrapper: FC<
    Pick<EntitySwitcherButtonProps, 'isActive' | 'onClick'>
> = ({isActive, onClick}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const {selectedEntityId, isEntitySwitcherVisible} = useInternalNavigationContext()
    const {data: switchButtonData = []} = bff.navigation.getEntitySwitcherButtonData.useQuery(
        undefined,
        {keepPreviousData: true},
    )

    let formattedWalletBalance: string | undefined

    const currentSwitchButtonData = switchButtonData.find((data) =>
        selectedEntityId ? data.companyId === selectedEntityId : data.isActive,
    )

    const {showWalletBalance} = useVerificationStatus(
        currentSwitchButtonData?.verificationStatus,
        currentSwitchButtonData?.role,
    )

    if (!currentSwitchButtonData) {
        return <NavigationLogoLoader />
    }

    if (currentSwitchButtonData?.walletBalance) {
        const {walletBalance} = currentSwitchButtonData
        const verifiedWalletBalance = showWalletBalance
            ? walletBalance
            : {...walletBalance, value: 0}
        formattedWalletBalance = formatWalletBalance(verifiedWalletBalance)
    }

    const onButtonClick = () => {
        tracking.toggleEntitySwitcher({
            isSpendingEntity: currentSwitchButtonData?.isSpendingEntity ?? false,
            variant: isTablet ? 'mobile' : 'desktop',
            state: isEntitySwitcherVisible ? 'close' : 'open',
        })
        onClick()
    }

    return (
        <EntitySwitcherButton
            isActive={isActive}
            onClick={onButtonClick}
            companyName={currentSwitchButtonData?.companyName}
            walletBalance={formattedWalletBalance}
        />
    )
}

export const EntitySwitcherButton: FC<EntitySwitcherButtonProps> = ({
    isActive,
    companyName,
    walletBalance,
    onClick,
}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const textRef = useRef<HTMLDivElement>(null)
    const textRect = useRect(textRef)
    const [isTextOverflowing, setIsTextOverflowing] = useState(false)

    const onWidthChange = (width: number) => {
        setIsTextOverflowing((textRect?.width ?? 0) - width < 0)
    }

    const tooltipSide = isTablet ? 'bottom' : 'right'
    const hasWalletBalance = typeof walletBalance !== 'undefined'

    const shouldShiftButtonText = isTablet ? !hasWalletBalance : isActive || !hasWalletBalance

    return (
        <Tooltip content={isTextOverflowing ? companyName : ''} side={tooltipSide}>
            <StyledButton
                $isActive={isActive}
                $isTablet={isTablet}
                onClick={onClick}
                data-testid="entity-switcher-button"
            >
                <Inline alignY="center" space={8}>
                    <Office color={tokens.colorContentInteractiveQuiet} />
                    <TextWrapper ref={textRef}>
                        <CompanyText
                            $shift={shouldShiftButtonText}
                            $animate={hasWalletBalance}
                            variant="medium-default"
                            weight="medium"
                            onWidthChange={onWidthChange}
                        >
                            {companyName}
                        </CompanyText>
                        <WalletBalanceText
                            variant="small-subtle"
                            color="colorContentInteractive"
                            $shift={shouldShiftButtonText}
                            $animate={hasWalletBalance}
                        >
                            {walletBalance}
                        </WalletBalanceText>
                    </TextWrapper>
                    {isTablet && (
                        <ArrowRight color={tokens.colorContentInteractiveQuiet} size={16} />
                    )}
                </Inline>
            </StyledButton>
        </Tooltip>
    )
}

const StyledButton = styled(NakedButton)<{$isActive?: boolean; $isTablet?: boolean}>`
    height: 64px;
    margin: ${({$isTablet}) =>
        $isTablet ? `${tokens.spacing16} ${tokens.spacing48}` : `${tokens.spacing16}`};
    padding: ${tokens.spacing10} ${tokens.spacing16};
    border-radius: ${tokens.arc8};
    ${focusRing('regular')};
    background-color: ${({$isTablet}) =>
        $isTablet
            ? customColorSchemeTokens.colorBackgroundEntitySwitcherActive
            : customColorSchemeTokens.colorBackgroundEntitySwitcher};

    &:hover,
    &:focus-visible {
        background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherHover};
    }

    ${(props) =>
        props.$isActive &&
        css`
            background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherActive};
        `}

    ${(props) =>
        props.$isTablet &&
        css`
            border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
        `}
`

const TextWrapper = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: start;
    flex-grow: 1;
`

const ellipsis = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const textAnimation = css<{$shift: boolean; $animate: boolean}>`
    ${({$animate}) =>
        $animate &&
        css`
            transition: all ${tokens.smoothInOut};
        `}

    transform: translate(0, ${({$shift}) => ($shift ? '50%' : '0')});
`

const CompanyText = styled(MeasuredText)<{$shift: boolean; $animate: boolean}>`
    color: ${tokens.colorContentInteractive};

    ${ellipsis}

    ${textAnimation}
`

const WalletBalanceText = styled(Text)<{$shift: boolean; $animate: boolean}>`
    font-family: Spezia, sans-serif;
    height: 20.4px;
    ${ellipsis}
    ${textAnimation}
    opacity: ${({$shift}) => ($shift ? '0' : '1')};
`
