import {t} from '@lingui/macro'

import {Button, Inline, ModalActions, ModalContent, ModalTitle} from '@pleo-io/telescope'

import type {IModal} from '@product-web/shell/legacy/legacy-modals-bridge'
import {__legacyShowModal} from '@product-web/shell/legacy/legacy-modals-bridge'

interface ModalConfirmProps extends IModal {
    title: React.ReactNode
    message: React.ReactNode
    cancelLabel: string
    confirmLabel: string
    confirmButtonTestId?: string
}

export function ModalConfirm(props: ModalConfirmProps) {
    return (
        <>
            <ModalTitle>{props.title}</ModalTitle>
            <ModalContent data-testid="modal-content">{props.message}</ModalContent>
            <ModalActions>
                <Inline space={16} alignX="center">
                    <Button variant="secondary" onClick={() => props._modal?.promise.reject()}>
                        {props.cancelLabel}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => props._modal?.promise.resolve()}
                        data-testid={props.confirmButtonTestId ?? 'modal-confirm-button'}
                    >
                        {props.confirmLabel}
                    </Button>
                </Inline>
            </ModalActions>
        </>
    )
}

type ShowConfirmModalArgs = {
    title: React.ReactNode
    message: React.ReactNode
    confirmButtonTestId?: string
    confirmLabel?: string
    cancelLabel?: string
    onClose?: () => void
}

/**
Shows a confirmation modal with the specified title and message, and returns the result.
@param args.title - The title of the confirmation modal.
@param args.message - The message to display in the confirmation modal.
@param args.confirmButtonTestId - The test ID to assign to the confirm button.
@param [args.confirmLabel] - The label to display on the confirm button. Defaults to "Confirm".
@param [args.cancelLabel] - The label to display on the cancel button. Defaults to "Cancel".
@param [args.onClose] - A function to be called when the confirmation modal is closed.
@returns - A promise that resolves to an object containing the result of the confirmation modal, either "confirmed" or "cancelled".
*/
export async function showConfirmModal(args: ShowConfirmModalArgs) {
    const result = await __legacyShowModal(
        <ModalConfirm
            title={args.title}
            message={args.message}
            confirmButtonTestId={args.confirmButtonTestId}
            confirmLabel={args.confirmLabel ?? t`Confirm`}
            cancelLabel={args.cancelLabel ?? t`Cancel`}
        />,
        {onClose: args.onClose},
    )
    return {result: result.status === 'submitted' ? ('confirmed' as const) : ('cancelled' as const)}
}
