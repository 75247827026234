import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {Fragment, useEffect} from 'react'
import styled from 'styled-components'

import {
    Box,
    Button,
    Inline,
    Link,
    List,
    ListItem as TelescopeListItem,
    Modal as ModalBase,
    ModalClose,
    Popover,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {ArrowDiagonalUp, CheckSmall, Email} from '@pleo-io/telescope-icons'

import tracking from '@product-web/analytics'
import {PlanType} from '@product-web/api-types/plan'
import {useFlags} from '@product-web/flags'
import {CurrencyFormat, formatCurrency} from '@product-web/locale/currency'
import {breakpoints} from '@product-web/styles/theme'
import {useToaster} from '@product-web/toaster'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {bff} from '../../../../bff'
import receipt1 from '../../../../images/receipt-1.svg'
import receipt2 from '../../../../images/receipt-2.svg'

export const toStringNoDecimal = (
    amount: number | undefined,
    currency: string | undefined,
): string => formatCurrency(amount, currency, {format: CurrencyFormat.FixedFractionDigits(0)})

export const PricingInfoModal = ({onDismiss}: {onDismiss: () => void}) => {
    const {showToast} = useToaster()
    const isMediumTabletUp = useMediaQuery(`(max-width: ${breakpoints.mediumTabletUp})`)
    const isMobileLrgUp = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp})`)

    const flags = useFlags()
    const {
        data: plansPricesData,
        isError,
        isLoading,
    } = bff.featureMultiEntity.pricingInfoModal.getPricingInfo.useQuery(undefined, {
        enabled: !!flags.multiEntitySelfExpansion,
    })

    const {data: userInfoData} = bff.featureMultiEntity.pricingInfoModal.getUserInfo.useQuery()

    const essential = plansPricesData?.[PlanType.ESSENTIAL]
    const advanced = plansPricesData?.[PlanType.ADVANCED]

    const essentialPrice = toStringNoDecimal(
        essential?.prices?.monthlyDiscounted,
        essential?.currency,
    )

    useEffect(() => {
        if (userInfoData) {
            tracking.selfExpansionMultiEntityPricingPlansClicked({
                companyId: userInfoData?.companyId,
                userId: userInfoData?.userId,
                hasOrgAccess: userInfoData?.hasOrgAccess,
            })
        }
    }, [userInfoData])

    const advancedPrice = toStringNoDecimal(advanced?.prices?.monthlyDiscounted, advanced?.currency)

    useEffect(() => {
        const essentialDataNotLoaded =
            !essential?.prices?.monthlyDiscounted ||
            !essential?.additionalUsers?.length ||
            !essential?.currency
        const advancedDataNotLoaded =
            !advanced?.prices?.monthlyDiscounted ||
            !advanced?.additionalUsers?.length ||
            !advanced?.currency

        const notAllPricingDataLoaded =
            !isLoading && (essentialDataNotLoaded || advancedDataNotLoaded)

        if (isError || notAllPricingDataLoaded) {
            showToast(t`Please refresh the page or try again later`, {
                level: 'error',
                title: t`Some pricing information could not be loaded`,
                isDismissable: true,
            })
        }
    }, [isError, isLoading])

    return (
        <Modal onDismiss={onDismiss} isOpen aria-label={t`Request To Add Entities`}>
            <ModalClose onClick={onDismiss} />
            <Stack stretch>
                <Title>
                    {!isMediumTabletUp && <Email size={24} mr={10} />}
                    <Text variant="large-accent">
                        <Trans>
                            Your account manager will reach out to set up your billing once you add
                            an entity
                        </Trans>
                    </Text>
                </Title>
                <PricingBoxWrapper>
                    <PricingBox>
                        <PlanName name={t`Essential`} />
                        <PlanDescription
                            description={t`For companies on a steady path to smarter business spending`}
                        />
                        <Price price={essentialPrice} />

                        <PlanList title={t`Centralise business spend`}>
                            <ListItem>
                                <ListItemText>
                                    <Trans>1 or 2 entities</Trans>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Trans>3 users included</Trans> (
                                    <Popover>
                                        <Popover.Trigger>
                                            {/* @temp-button-migrations: May look off, due to inline use and custom styling, when tertiary button styling is updated */}
                                            <TiersButton
                                                variant="tertiary"
                                                data-testid="essential-plan-more-users-pricing"
                                            >
                                                <Trans>add more for a fee</Trans>
                                            </TiersButton>
                                        </Popover.Trigger>
                                        <Popover.Content side="top">
                                            <Tiers
                                                tiers={essential?.additionalUsers}
                                                currency={essential?.currency}
                                            />
                                        </Popover.Content>
                                    </Popover>
                                    )
                                </ListItemText>
                            </ListItem>
                        </PlanList>
                    </PricingBox>
                    <PricingBox>
                        <PlanName name={t`Advanced`} />
                        <PlanDescription
                            description={t`For larger organisations who need spend management at scale`}
                        />
                        <Price price={advancedPrice} />

                        <PlanList title={t`Complete control for the finance team`}>
                            <ListItem>
                                <ListItemText>
                                    <Trans>unlimited entities</Trans>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Trans>3 users included</Trans> (
                                    <Popover>
                                        <Popover.Trigger>
                                            <TiersButton
                                                variant="tertiary"
                                                data-testid="advanced-plan-more-users-pricing"
                                            >
                                                <Trans>add more for a fee</Trans>
                                            </TiersButton>
                                        </Popover.Trigger>
                                        <Popover.Content side="top">
                                            <Tiers
                                                tiers={advanced?.additionalUsers}
                                                currency={advanced?.currency}
                                            />
                                        </Popover.Content>
                                    </Popover>
                                    )
                                </ListItemText>
                            </ListItem>
                        </PlanList>
                    </PricingBox>
                </PricingBoxWrapper>
                <Box ml={isMobileLrgUp ? 16 : 32}>
                    <Text weight="medium">
                        <Trans>Billing options</Trans>
                    </Text>
                </Box>
                <BillingOptions alignY="center" mt={16} mb={12} padding={16}>
                    <Box mr={16}>
                        <img src={receipt1} alt="" />
                    </Box>
                    <Text>
                        <Trans>One entity pays for the whole organisation</Trans>
                    </Text>
                </BillingOptions>
                <BillingOptions alignY="center" mb={28} padding={16}>
                    <Box mr={16}>
                        <img src={receipt2} alt="" />
                    </Box>
                    <Text>
                        <Trans>Each entity gets a separate invoice</Trans>
                    </Text>
                </BillingOptions>
                <Footer>
                    <Box mt={18} mb={18}>
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.pleo.io/en/pricing"
                            onClick={() => {
                                if (userInfoData) {
                                    tracking.selfExpansionViewAllPricingPlansClicked({
                                        companyId: userInfoData?.companyId,
                                        userId: userInfoData?.userId,
                                        hasOrgAccess: userInfoData?.hasOrgAccess,
                                    })
                                }
                            }}
                        >
                            <Trans>View and compare all plans</Trans>
                            <ArrowDiagonalUp size={16} ml={8} />
                        </Link>
                    </Box>
                </Footer>
            </Stack>
        </Modal>
    )
}

const Tiers = ({
    tiers,
    currency,
}: {
    tiers?: {upTo?: string; unitAmountDecimal?: number}[]
    currency?: string
}) => {
    const getPrice = (price: number) => toStringNoDecimal(price, currency)

    return (
        <Stack stretch padding={24}>
            <TiersGrid>
                <TierCell $align="start" $skipBorder>
                    <Text weight="medium">
                        <Trans>Additional users</Trans>
                    </Text>
                </TierCell>
                <TierCell $align="end" $skipBorder>
                    <Text weight="medium">
                        <Trans>Price</Trans>
                    </Text>
                </TierCell>
                {tiers?.map((tier, index) => (
                    <Fragment key={tier.upTo}>
                        <TierCell $align="start" $skipBorder={index === tiers.length - 1}>
                            <Text>{tier.upTo}</Text>
                        </TierCell>
                        <TierCell $align="end" $skipBorder={index === tiers.length - 1}>
                            <Text>{getPrice(tier.unitAmountDecimal!)}</Text>
                        </TierCell>
                    </Fragment>
                ))}
            </TiersGrid>
            <Text variant="small-subtle" align="right">
                <Trans>Per user / per month / per entity</Trans>
            </Text>
        </Stack>
    )
}

const PlanName = ({name}: {name: string}) => {
    return (
        <Box mb={10}>
            <Text variant="2xlarge-accent">{name}</Text>
        </Box>
    )
}

const PlanDescription = ({description}: {description: string}) => {
    return (
        <Box mb={24}>
            <Text variant="small-subtle" weight="medium">
                {description}
            </Text>
        </Box>
    )
}

const Price = ({price}: {price: string}) => {
    return (
        <Box mb={24}>
            <PriceHeader>
                <Text variant="small-subtle">
                    <Trans>from</Trans>
                </Text>
            </PriceHeader>
            <Inline alignY="baseline">
                <Text variant="3xlarge-accent">{price}</Text>
                <Text variant="small-subtle" weight="medium">
                    /<Trans>month per entity</Trans>
                </Text>
            </Inline>
        </Box>
    )
}

const PlanList = ({children, title}: {children: ReactNode; title: string}) => {
    return (
        <>
            <Box mb={10} mt={4}>
                <Text weight="medium">{title}</Text>
            </Box>
            <List listStyle="none">{children}</List>
        </>
    )
}

const ListItem = ({children}: {children: ReactNode}) => {
    return (
        <StyledListItem>
            <Inline>
                <ListItemIcon />
                {children}
            </Inline>
        </StyledListItem>
    )
}
const ListItemIcon = () => (
    <ListItemIconWrapper>
        <CheckSmall mr={8} />
    </ListItemIconWrapper>
)

const PriceHeader = styled.div`
    margin-bottom: calc(-1 * ${tokens.spacing10});
`

const Modal = styled(ModalBase).attrs({dangerouslySetZIndexValue: tokens.zIndexPopover})`
    width: 814px;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        width: unset;
        max-width: 814px;
    }
`

const Title = styled(Inline)`
    justify-content: center;
    text-align: center;
    margin-bottom: ${tokens.spacing28};
    margin-left: ${tokens.spacing48};
    margin-right: ${tokens.spacing48};

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        margin-bottom: ${tokens.spacing24};
    }
`

const PricingBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: ${tokens.spacing24};
    margin-right: ${tokens.spacing32};
    margin-left: ${tokens.spacing32};
    margin-bottom: ${tokens.spacing32};

    @media (max-width: ${breakpoints.mediumTabletUp}) {
        flex-direction: column;
        row-gap: ${tokens.spacing24};
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        margin-right: ${tokens.spacing16};
        margin-left: ${tokens.spacing16};
        margin-bottom: ${tokens.spacing24};
    }
`

const PricingBox = styled(Box)`
    padding: ${tokens.spacing20} ${tokens.spacing32} ${tokens.spacing12} ${tokens.spacing32};
    border-radius: ${tokens.arc8};
    border: 1px solid ${tokens.shade400};
    background: ${tokens.shade000};

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        padding: ${tokens.spacing20} ${tokens.spacing20} ${tokens.spacing12} ${tokens.spacing20};
    }
`

const Footer = styled.div`
    display: flex;
    justify-content: center;
    height: ${tokens.spacing60};
    background: ${tokens.shade200};
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
`

const ListItemText = styled(Text)`
    line-height: ${tokens.lineHeight3};
`

const ListItemIconWrapper = styled(Box)`
    color: ${tokens.green700};
`

const StyledListItem = styled(TelescopeListItem)`
    margin-bottom: ${tokens.spacing4};
`

const BillingOptions = styled(Inline)`
    background-color: ${tokens.blue100};
    border-radius: ${tokens.arc8};
    margin-left: ${tokens.spacing32};
    margin-right: ${tokens.spacing32};

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        margin-left: ${tokens.spacing16};
        margin-right: ${tokens.spacing16};
    }
`

const TiersGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: ${tokens.spacing10};
`

const TierCell = styled.div<{$align?: 'start' | 'end'; $skipBorder?: boolean}>`
    text-align: ${({$align}) => $align};
    border-bottom: ${({$skipBorder}) => ($skipBorder ? 'none' : tokens.borderPrimary)};
    padding-bottom: ${tokens.spacing10};
`

const TiersButton = styled(Button)`
    color: ${tokens.shade900};
    text-decoration: underline dotted;

    &:hover {
        color: ${tokens.shade600};
    }
`
